import React, { useState, useEffect } from 'react'
import { Config } from '../../../classes/config'
import DataTable from '../../Common/CustomASDataGrid'
import Spinner from '../../Common/ReusableComponents/Spinner'
import utility from '../../../classes/utility'
import global from '../../../classes/global'
import CustomStore from "devextreme/data/custom_store";
import { LoadOptions } from "devextreme/data";
import notify from 'devextreme/ui/notify';

interface LoadResult {
  data: any[];
  totalCount: number;
}

const Suppliers = (props: any) => {
  if (props.role !== global.worxOnline_Admin) {
    window.open('#/AccessDenied', '_self')
  }

  const [items, setItems] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [dataLoaded, setDataLoaded] = useState(false);

  const columns = [
    {
      field: 'id',
      title: 'ID',
      width: 80,
      type: 'string',
      allowHiding: false,
      visibleColumn: false,
      formItem: {
        visible: false
      }
    },
    {
      field: 'code',
      title: 'Code',
      // width: 160,
      type: 'string',
      allowHiding: true,
      isRequired: true
    },
    {
      field: 'title',
      title: 'Title',
      // width: 160,
      type: 'string',
      allowHiding: false,
      isRequired: true
    },
    {
      field: 'abn',
      title: 'ABN',
      // width: 160,
      type: 'string',
      allowHiding: true,
      isRequired: true
    },
    {
      field: 'city',
      title: 'City',
      // width: 160,
      type: 'string',
      allowHiding: true,
      isRequired: true
    },
    {
      field: 'created',
      title: 'Created',
      width: 160,
      type: 'datetime',
      visibleColumn: false,
      allowHiding: true,
      formItem: {
        visible: false,
      }
    },
    {
      field: 'created_by',
      title: 'Created By',
      width: 160,
      type: 'string',
      visibleColumn: false,
      allowHiding: true,
      formItem: {
        visible: false,
      }
    },
    {
      field: 'updated',
      title: 'Updated',
      width: 160,
      type: 'datetime',
      visibleColumn: false,
      allowHiding: true,
      formItem: {
        visible: false,
      }
    },
    {
      field: 'updated_by',
      title: 'Updated By',
      width: 160,
      type: 'string',
      visibleColumn: false,
      allowHiding: true,
      formItem: {
        visible: false,
      }
    }
  ]


  useEffect(() => {
    itemsData();
  }, [])

  const itemsData = async () => {
    setLoading(true);
    try {
      const itemResponse = await utility.genericGetAPICall(Config.Suppliers);
      const itemData = itemResponse.map((r: any) => ({
        ...r,
        is_active: r.is_active ? true : false
      }));
      setItems(itemData);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const dataSource = new CustomStore({
    load: async (loadOptions: LoadOptions): Promise<LoadResult> => {
      return {
        data: items,
        totalCount: items.length
      };
    },
    key: 'id',
    onLoaded: () => {
      console.log('Data loaded');
      setLoading(false);
      setDataLoaded(true);
    },
    insert: async (values: any) => {
      try {
        await utility.genericPostAPICall(Config.Suppliers, values);
        notify('Row added successfully', 'success', 3000);
        await itemsData();
      }catch (error:any) {
        const errorMessage = error.message || 'An unexpected error occurred.';
        notify(errorMessage, 'error', 5000); 
        console.error(errorMessage);
      }
    },
    update: async (key: any, values: any) => {
      try {
        await utility.genericUpdatePatchAPICall(Config.Suppliers, key, values);
        notify('Row updated successfully', 'success', 3000);
        await itemsData();
      } catch (error:any) {
        const errorMessage = error.message || 'An unexpected error occurred.';
        notify(errorMessage, 'error', 5000); 
        console.error(errorMessage);
      }
    },
    remove: async (key: any) => {
      try {
        await utility.genericDeleteAPICall(Config.Suppliers, key);
        notify('Row deleted successfully', 'success', 3000);
        await itemsData();
      } catch (error:any) {
        const errorMessage = error.message || 'An unexpected error occurred.';
        notify(errorMessage, 'error', 5000); 
        console.error(errorMessage);
      }
    }
  });

  return (
    <>
      <div>
        {!loading ? (
          <div className='results-container'>
            <DataTable
              dataSource={dataSource}
              style={{ width: '100%' }}
              filteralbe={true}
              groupable={true}
              columns={columns}
              fileName={'Suppliers'}
              columnChooser={'suppliersColumns'}
              selectionMode={false}
              showHideColumnMenu={true}
              loading={loading}
              allowExportSelectedData={false}
              columnHidingEnabled={true}
              showCommandBtn={false}
              hideSearchPanel={false}
              hideCommandDeleteBtn={false}
              showNewBtn={false}
              showViewDetailBtn={false}
              hideNewBtn={true}
              //defaultfiltervalue={filterValue}
              showMoreBtn={true}
              visible={dataLoaded}
            />
          </div>
        ) : (
          <Spinner size='large' className='loader' />
        )}
      </div>
    </>
  );
};


export default Suppliers
