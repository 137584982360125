import React, { useState, useEffect } from 'react'
import utility from '../../../classes/utility'
import { Config } from '../../../classes/config'
import DataTable from '../../Common/CustomASDataGrid'
import AlertDialog from '../../Common/AlertDialog'
import DeleteDialog from '../../Common/DeleteAlert'
import Spinner from '../../Common/ReusableComponents/Spinner'
import global from '../../../classes/global'
import CustomStore from 'devextreme/data/custom_store'
import { LoadOptions } from 'devextreme/data'
import notify from 'devextreme/ui/notify'

interface LoadResult {
  data: any[]
  totalCount: number
}

const RiskGroup = (props: any) => {
  if (props.role !== global.worxOnline_Admin) {
    window.open('#/AccessDenied', '_self')
  }

  const [dataLoaded, setDataLoaded] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [items, setItems] = useState<any[]>([]);
  const [riskCategories, setRiskCategories] = useState<any[]>([]);

  const setRiskCategoryValue = (rowData: any, value: number) => {
    rowData.category_id = value || null;
    // const riskCategory = riskCategories.find(r => r.category_id === value);
    // if (riskCategory) {
    //   rowData.risk_category_name = riskCategory.risk_category_name;
    // }
  }

  const columns = [
    {
      field: 'id',
      title: 'ID',
      width: 80,
      type: 'string',
      allowHiding: false,
      visibleColumn: false,
      formItem: {
        visible: false
      }
    },
    {
      field: 'title',
      title: 'Title',
      width: 250,
      type: 'string',
      allowHiding: false,
      isRequired: true
    },
    {
      field: 'category_id',
      title: 'Risk Category',
      width: 150,
      type: 'string',
      allowHiding: true,
      isRequired: true,
      setCellValue: setRiskCategoryValue,
      Lookup: {
        dataSource: riskCategories,
        valueExpr: 'category_id',
        displayExpr: 'risk_category_name'
      }
    },
    {
      field: 'is_active',
      title: 'Active',
      // width: 160,
      type: 'boolean',
      allowHiding: false,
      calculateCellValue: (data: any) => data.is_active ?? true, // Default to true
      setCellValue: (newData: any, value: any) => {
        newData.is_active = value
      }
    },
    {
      field: 'created',
      title: 'Created',
      width: 160,
      type: 'datetime',
      visibleColumn: false,
      allowHiding: true,
      formItem: {
        visible: false,
      }
    },
    {
      field: 'created_by',
      title: 'Created By',
      width: 160,
      type: 'string',
      visibleColumn: false,
      allowHiding: true,
      formItem: {
        visible: false,
      }
    },
    {
      field: 'updated',
      title: 'Updated',
      width: 160,
      type: 'datetime',
      visibleColumn: false,
      allowHiding: true,
      formItem: {
        visible: false,
      }
    },
    {
      field: 'updated_by',
      title: 'Updated By',
      width: 160,
      type: 'string',
      visibleColumn: false,
      allowHiding: true,
      formItem: {
        visible: false,
      }
    }
  ]

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const riskCategoryResponse = await utility.genericGetAPICall(`${Config.RiskCategory}`)
        const riskCategoryData = riskCategoryResponse.map((r: any) => ({
          category_id: r.id,
          risk_category_name: r.title
        }))
        setRiskCategories(riskCategoryData);
        await fetchItemsData();

      } catch (error) {
        console.error(error)
        setLoading(false)
      }
    }

    fetchData()
  }, [])

  const fetchItemsData = async () => {
    setLoading(true)
    try {
      const itemsResponse:any = await utility.genericGetAPICall(Config.RiskGroupsWithRiskCategories);
      let items: any[] = []

      for (let r of itemsResponse) {
        items.push({
          ...r,
          id: r.id,
          category_id: r.category_id,
          risk_category_title: r.risk_category? r.risk_category.title : '',
          active: r.is_active ? true : false,
        })
      }

      items = utility._sortItems(items, 'id', true)
      setItems(items)
      setLoading(false)
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  }
  const dataSource = new CustomStore({
    load: async (loadOptions: LoadOptions): Promise<LoadResult> => {
      return {
        data: items,
        totalCount: items.length
      }
    },
    key: 'id',
    onLoaded: () => {
      console.log('Data loaded')
      setLoading(false)
      setDataLoaded(true)
    },
    insert: async (values: any) => {
      try {
        await utility.genericPostAPICall(Config.RiskGroup, values)
        notify('Row added successfully', 'success', 3000)
        await fetchItemsData()
      } catch (error:any) {
        const errorMessage = error.message || 'An unexpected error occurred.';
        notify(errorMessage, 'error', 5000); 
        console.error(errorMessage);
      }
    },
    update: async (key: any, values: any) => {
      try {
        await utility.genericUpdatePatchAPICall(Config.RiskGroup, key, values)
        notify('Row updated successfully', 'success', 3000)
        await fetchItemsData()
      } catch (error:any) {
        const errorMessage = error.message || 'An unexpected error occurred.';
        notify(errorMessage, 'error', 5000); 
        console.error(errorMessage);
      }
    },
    remove: async (key: any) => {
      try {
        await utility.genericDeleteAPICall(Config.RiskGroup, key)
        notify('Row deleted successfully', 'success', 3000)
        await fetchItemsData()
      } catch (error:any) {
        const errorMessage = error.message || 'An unexpected error occurred.';
        notify(errorMessage, 'error', 5000); 
        console.error(errorMessage);
      }
    }
  })

  return (
    <>
      <div>
        {!loading ? (
          <div className='results-container'>
            <DataTable
              dataSource={dataSource}
              style={{ width: '100%' }}
              filteralbe={true}
              groupable={true}
              columns={columns}
              fileName={'Risk Group'}
              columnChooser={'riskGroupColumns'}
              selectionMode={false}
              showHideColumnMenu={true}
              loading={loading}
              allowExportSelectedData={false}
              columnHidingEnabled={true}
              showCommandBtn={false}
              hideSearchPanel={false}
              hideCommandDeleteBtn={false}
              showNewBtn={false}
              showViewDetailBtn={false}
              hideNewBtn={true}
              //defaultfiltervalue={filterValue}
              showMoreBtn={true}
              visible={dataLoaded}
            />
          </div>
        ) : (
          <Spinner size='large' className='loader' />
        )}
      </div>
    </>
  )
}

export default RiskGroup
