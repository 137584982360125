import React, { useState, useEffect } from "react";
import config from 'devextreme/core/config';

import { useAuth } from "./AuthContext";
import SignIn from './components/Login/Login';
import global from './classes/global'
import utility from './classes/utilityOld'
import { Config } from './classes/config'
import { HashRouter, NavLink } from 'react-router-dom';
import Navigation from "./components/Navigation/Navigation";
import { ActiveTabProvider } from './components/Navigation/ActiveTabContext';
import Spinner from "./components/Common/ReusableComponents//Spinner";

import { licenseKey } from './classes/devExtremeLicense';
config({ licenseKey });

const Home = () => {
  const { isAuthenticated, user, role} = useAuth();

  let currentUser = {
    DisplayName: user?.full_name,
    Email: user?.email,
    LoginName: user?.login_name,
    userId: user?.id
  };

  useEffect(() => {
    if (user && Object.keys(user).length > 0) {
        currentUser = {
            DisplayName: user?.full_name,
            Email: user?.email,
            LoginName: user?.login_name,
            userId: user?.id
          };
      onInit();
    }
  }, [user]);

   const [loading, setLoading] = useState(true);
  const [Department, setDepartment] = useState('');
  const [Role, setRole] = useState('');
  const [isContractor, setIsContractor] = useState('');
  const [Team, setteam] = useState('');
  const [pageListItems, setPageListItems] = useState([]);
  const handleSetLoading = () => {
    setLoading(false);
  };
  const handleDepartment = (item: any) => {
    setDepartment(item);
  };
  const handleteam = (item: any) => {
    setteam(item);
  };
  const handleRole = (item: any) => {
    setRole(item);
  };

  const handleIsContractor = (item: any) => {
    setIsContractor(item);
  };

  const handlePageListItems = (item: any) => {
    setPageListItems(item);
  };
  // let loading =true;
  const onInit = async () => {
    return new Promise((resolve, reject) => {

      const promises = [];
      const dataToPassToService = {};
      let isContractor = false
      let Team = '';
      //@ts-ignore
      dataToPassToService.id = [user?.id]
      const promise1 = utility.genericPostAPIcall(Config.searchCrewDeptTeam, dataToPassToService).then((data: any) => {

        if (data && data.length > 0) {
          // Department = { id: data[0].DepartmentId, text: data[0].DepartmentName }
          if (data[0].department_id) {
            handleDepartment({ id: data[0].department_id, text: data[0].department_name });
          }
         
          if (data[0]?.is_contractor === true) {
            isContractor = true;
            handleIsContractor(isContractor)
          }
          if (data[0].team_id) {
            Team = data[0].team_code + '|' + data[0].team_name;
            handleteam(Team)
          }
            const tempRoles = 'Admin';
           //let tempRoles = data[0]?.role_name ;

          if (tempRoles) {
            handleRole(tempRoles)
          }
        } else {
          window.open('#/AccessRequest', '_self');
        }

      }, (err:any) => {
        console.log(err);
        handleRole(global.worxOnline_Reader);
         handleSetLoading()

      });
      promises.push(promise1);

      const promise3 = utility.genericGetAPICallForList(Config.ListConfiguration, 'GET').then((data) => {
        const tempPageListItems: any = [];
        if (data && data.length > 0) {

          data.forEach((element:any) => {
            tempPageListItems.push({
              Id: element.id,
              Title: element.title,
              Category: element.category,
              Isactive: element.is_active ? true : false,
              //AddEditField: JSON.parse(element.add_edit_field),
              //apiInfo: JSON.parse(element.api_info),
              //Columns: JSON.parse(element.columns),
              //DataTable: JSON.parse(element.data_table),
            });
          });

          handlePageListItems(tempPageListItems)

        }
      }, (err: any) => {
        console.log(err);

      });
      promises.push(promise3);
      Promise.all(promises).then(result => {
         handleSetLoading()
        resolve(true)
      });
    });
  }

  return (
    <>
      {isAuthenticated ? (
        <>
          {!loading ?
          <div className="App">
            {/* <WorxOnline
              role={Role}
              Department={Department}
              currentUser={currentUser}
              pageListItems={pageListItems}
              isContractor={isContractor}
              team={Team}
            /> */}
              <HashRouter>
       <ActiveTabProvider>
            <Navigation
              role={Role}
              Department={Department}
              currentUser={currentUser}
              pageListItems={pageListItems}
              isContractor={isContractor}
              team={Team}
              />
      </ActiveTabProvider>
    </HashRouter>

          </div>
          : <div> <br /> <Spinner size='large' label="Please wait. Component is loading" /></div>
        }
        </>
      ) : (
        <SignIn />
        // <p>try something else</p>
      )}
    </>
  );
};

export default Home;