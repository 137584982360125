import React, {useEffect, useState, useMemo, useCallback } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { useAuth } from '../../AuthContext'
import { useActiveTab } from './ActiveTabContext'
import {AssetProvider} from '../../AssetContext'
import global from '../../classes/global'
import Worxonline from './worxOnline'
import Drawer from 'devextreme-react/drawer'
import 'devextreme/dist/css/dx.light.css'
import List from '@mui/material/List'
import Collapse from '@mui/material/Collapse'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import ListItem from '@mui/material/ListItem'

// images
import { ReactComponent as ExpandLess } from '../../images/menu/ArrowDown.svg'
import { ReactComponent as ExpandMore } from '../../images/menu/ArrowUp.svg'
import { ReactComponent as PersonIcon } from '../../images/menu/2Users.svg'
import { ReactComponent as WorkIcon } from '../../images/menu/Work.svg'
import { ReactComponent as WarningIcon } from '../../images/menu/DangerTriangle.svg'
import { ReactComponent as ListIcon } from '../../images/menu/MoreSquare.svg'
import { ReactComponent as SearchIcon } from '../../images/menu/Search.svg'
import { ReactComponent as SchedulerIcon } from '../../images/menu/Calendar.svg'
import { ReactComponent as HomeIcon } from '../../images/menu/Home.svg'
import { ReactComponent as SignOutIcon } from '../../images/menu/Logout.svg'
import { ReactComponent as WOLogo } from '../../images/menu/wo-logo.svg'
import NavLogoSmall from '../../images/menu/nav-logo-small.png'
import NavLogo from '../../images/menu/nav-logo.png'

interface NavigationProps {
  role: string
  Department?: any
  currentUser?: any
  pageListItems?: any
  isContractor?: any
  team?: any
  details?: any
}

interface StateComponent {
  openUserManagement: boolean
  openWorkManagement: boolean
  openRiskManagement: boolean
  openOtherManagement: boolean
  openSearch: boolean
  openHome: boolean
  openScheduler: boolean
  openSubmenu?: any
}

interface CustomButtonProps {
  onClick: () => void;
}

const Navigation: React.FC<NavigationProps> = ({
  role,
  Department,
  currentUser,
  pageListItems,
  isContractor,
  team,
  details
}) => {
  const location = useLocation();
  const { setTabs } = useActiveTab()
  const { logout } = useAuth()
  const [isDrawerOpen, setIsDrawerOpen] = useState(true)
  const [isSmallDevice, setIsSmallDevice] = useState(false)
  const [state, setState] = useState<StateComponent>({
    openUserManagement: false,
    openWorkManagement: false,
    openRiskManagement: false,
    openOtherManagement: false,
    openSearch: false,
    openHome: false,
    openScheduler: false
  })

  useEffect(() => {
    const path = location.pathname
    const isExactPath = (basePath:any) => path === basePath
    const newState = { ...state }
    if (path.includes('/Employees') || path.includes('/Crews') || path.includes('/Teams') || path.includes('/Departments') || path.includes('/LoginDetails')) {
      newState.openUserManagement = true
    } else if (path.includes('/Activities') || path.includes('/WorkTypeField') || path.includes('/AssetQuestion') || path.includes('/DefectCode') || isExactPath('/Equipment') || path.includes('/Events') || path.includes('/Forms') || path.includes('/Materials') || path.includes('/PrestartForms') || path.includes('/Tcp') || path.includes('/WorkRequestActivities')) {
      newState.openWorkManagement = true
    } else if (path.includes('/RiskCategory') || path.includes('/RiskGroup') || path.includes('/Risks') || path.includes('/RiskControlMeasure') || path.includes('/RiskForm') || path.includes('/Swms')) {
      newState.openRiskManagement = true
    // } else if (path.includes('/ClearStorage') || path.includes('/Counters') || path.includes('/OnHoldReasons') || path.includes('/Priority') || path.includes('/Status') || path.includes('/Suppliers') || path.includes('/Suburbs') || path.includes('/WeeklyTimesheet')) {
    //   newState.openOtherManagement = true
    } else if (path.includes('/Assets') || path.includes('/SearchTimesheet') || path.includes('/PlantUsage') || path.includes('/Searchworkorder') || path.includes('/EquipmentAndVehicles') || path.includes('/PrestartChecklist') || path.includes('/InspectionForm')) {
      newState.openSearch = true
    } else if (path.includes('/Scheduler')) {
      newState.openScheduler = true
    }
    setState(newState)
  }, [location.pathname])

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1536) {
        setIsSmallDevice(true)
        setIsDrawerOpen(false)
      } else {
        setIsSmallDevice(false)
        setIsDrawerOpen(true)
      }
    }
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const handleMenuToggle = useCallback((toggleName: keyof StateComponent, mainTab: string, subTab: string) => {
    if(!isDrawerOpen) {
      setIsDrawerOpen(true);
    }
    const newState = { ...initialState };
    newState[toggleName] = !state[toggleName]; // Toggle the selected tab
    setState(newState);
    setTabs(mainTab, subTab);
  }, [state, setTabs, isDrawerOpen]);

  const initialState: StateComponent = {
    openUserManagement: false,
    openWorkManagement: false,
    openRiskManagement: false,
    openOtherManagement: false,
    openSearch: false,
    openHome: false,
    openScheduler: false,
  };

  const toggleDrawer = useCallback(() => {
    if (isDrawerOpen) {
      setState(initialState);
    }
    setIsDrawerOpen((prevState) => !prevState);
  }, [isDrawerOpen, initialState]);

  const toggleDrawerForMainContent = useCallback(() => {
    if (isDrawerOpen) {
      setState(initialState);
    }
    setIsDrawerOpen(false);
  }, [isDrawerOpen, initialState]);

  // const CustomButton: React.FC<CustomButtonProps> = ({ onClick }) => (
  //   <button 
  //     onClick={onClick} 
  //     style={{ 
  //       display: 'flex', 
  //       alignItems: 'center', 
  //       color: '#fff', 
  //       background: 'none', 
  //       border: 'none', 
  //       cursor: 'pointer' 
  //     }}
  //   >
  //     <NavLogo style={{ marginRight: '8px', width: '24px', height: '24px' }} />
  //     WorxOnline
  //   </button>
  // );

  const renderAdminList = () => {
    return (
      <>
        {/* user management */}

        <div className={`nav-tab ${state.openUserManagement ? 'active' : ''}`}>
          <ListItemButton
            onClick={() => {
              handleMenuToggle('openUserManagement', 'User', '')
            }}
          >
            <ListItemIcon>
              <PersonIcon className={'NavIcon'} />
            </ListItemIcon>
            <ListItemText primary={'User'} className={'ml-2 parentListName'} />
            {state.openUserManagement ? <ExpandMore className='arrowIcon'/> : <ExpandLess className='arrowIcon'/>}
          </ListItemButton>
          <Collapse in={state.openUserManagement} timeout='auto' unmountOnExit>
            <NavLink className={`is-active sideMenu subNavItem`} to='/Employees'>
              <ListItem className={'nested'}>
                <ListItemIcon />
                <ListItemText primary={'Users'} className={'ml-2'} />
              </ListItem>
            </NavLink>
            <NavLink className='is-active sideMenu subNavItem' to='/Crews'>
              <ListItem className={'nested'}>
                <ListItemIcon />
                <ListItemText primary={'Crews'} className={'ml-2'} />
              </ListItem>
            </NavLink>
            <NavLink className='is-active sideMenu subNavItem' to='/Teams'>
              <ListItem className={'nested'}>
                <ListItemIcon />
                <ListItemText primary={'Teams'} className={'ml-2'} />
              </ListItem>
            </NavLink>
            {/* <NavLink className='is-active sideMenu subNavItem' to='/Teams1'>
              <ListItem className={'nested'}>
                <ListItemIcon />
                <ListItemText primary={'Teams1'} className={'ml-2'} />
              </ListItem>
            </NavLink> */}
            <NavLink className='is-active sideMenu subNavItem' to='/Departments'>
              <ListItem className={'nested'}>
                <ListItemIcon />
                <ListItemText primary={'Departments'} className={'ml-2'} />
              </ListItem>
            </NavLink>
            {/* <NavLink className='is-active sideMenu subNavItem' to='/LoginDetails'>
              <ListItem className={'nested'}>
                <ListItemIcon />
                <ListItemText primary={'Login Details'} />
              </ListItem>
            </NavLink> */}
          </Collapse>
        </div>

        {/* work management */}
        <div className={`nav-tab ${state.openWorkManagement ? 'active' : ''}`}>
          <ListItemButton
            onClick={() => {
              handleMenuToggle('openWorkManagement', 'Work', '')
            }}
          >
            <ListItemIcon>
              <WorkIcon className={'NavIcon'} />
            </ListItemIcon>
            <ListItemText primary={'Work'} className={'ml-2 parentListName'} />
            {state.openWorkManagement ? <ExpandMore className='arrowIcon'/> : <ExpandLess className='arrowIcon'/>}
          </ListItemButton>
          <Collapse in={state.openWorkManagement} timeout='auto' unmountOnExit>
            <NavLink className='is-active sideMenu subNavItem' to='/Activities'>
              <ListItem className={'nested'}>
                <ListItemIcon />
                <ListItemText primary={'Activities'} className={'ml-2'} />
              </ListItem>
            </NavLink>
            {/* <NavLink className='is-active sideMenu subNavItem' to='/Test'>
              <ListItem className={'nested'}>
                <ListItemIcon />
                <ListItemText primary={'TestTreeView'} className={'ml-2'} />
              </ListItem>
            </NavLink> */}
            <NavLink className='is-active sideMenu subNavItem' to='/WorkTypeField'>
              <ListItem className={'nested'}>
                <ListItemIcon />
                <ListItemText primary={'Activity Field'} />
              </ListItem>
            </NavLink>
            {/* <NavLink className='is-active sideMenu subNavItem' to='/AssetQuestion'>
              <ListItem className={'nested'}>
                <ListItemIcon />
                <ListItemText primary={'Asset Question'} className={'ml-2'} />
              </ListItem>
            </NavLink> */}
            <NavLink className='is-active sideMenu subNavItem' to='/DefectCode'>
              <ListItem className={'nested'}>
                <ListItemIcon />
                <ListItemText primary={'Defect Code'} className={'ml-2'} />
              </ListItem>
            </NavLink>
            <NavLink className='is-active sideMenu subNavItem' to='/Equipment'>
              <ListItem className={'nested'}>
                <ListItemIcon />
                <ListItemText primary={'Plant and Fleet'} className={'ml-2'} />
              </ListItem>
            </NavLink>
            <NavLink className='is-active sideMenu subNavItem' to='/Events'>
              <ListItem className={'nested'}>
                <ListItemIcon />
                <ListItemText primary={'Events'} className={'ml-2'} />
              </ListItem>
            </NavLink>
            <NavLink className='is-active sideMenu subNavItem' to='/Forms'>
              <ListItem className={'nested'}>
                <ListItemIcon />
                <ListItemText primary='Forms' className={'ml-2'} />
              </ListItem>
            </NavLink>
            <NavLink className='is-active sideMenu subNavItem' to='/Materials'>
              <ListItem className={'nested'}>
                <ListItemIcon />
                <ListItemText primary='Materials' className={'ml-2'} />
              </ListItem>
            </NavLink>
            <NavLink className='is-active sideMenu subNavItem' to='/PrestartForms'>
              <ListItem className={'nested'}>
                <ListItemIcon />
                <ListItemText primary='PrestartForms' className={'ml-2'} />
              </ListItem>
            </NavLink>
            <NavLink className='is-active sideMenu subNavItem' to='/Tcp'>
              <ListItem className={'nested'}>
                <ListItemIcon />
                <ListItemText primary='TCP' className={'ml-2'} />
              </ListItem>
            </NavLink>
            <NavLink className='is-active sideMenu subNavItem' to='/WorkRequestActivities'>
              <ListItem className={'nested'}>
                <ListItemIcon />
                <ListItemText primary='Work Request Activities' className={'ml-2'} />
              </ListItem>
            </NavLink>
          </Collapse>
        </div>

        {/* risk management */}
        <div className={`nav-tab ${state.openRiskManagement ? 'active' : ''}`}>
          <ListItemButton
            onClick={() => {
              handleMenuToggle('openRiskManagement', 'Risk', '')
            }}
          >
            <ListItemIcon>
              <WarningIcon className={'NavIcon'} />
            </ListItemIcon>
            <ListItemText primary={'Risk'} className={'ml-2 parentListName'} />
            {state.openRiskManagement ? <ExpandMore className='arrowIcon'/> : <ExpandLess className='arrowIcon'/>}
          </ListItemButton>
          <Collapse in={state.openRiskManagement} timeout='auto' unmountOnExit>
            <NavLink className='is-active sideMenu subNavItem' to='/RiskCategory'>
              <ListItem className={'nested'}>
                <ListItemIcon />
                <ListItemText primary={'Risk Category'} className={'ml-2'} />
              </ListItem>
            </NavLink>
            <NavLink className='is-active sideMenu subNavItem' to='/RiskGroup'>
              <ListItem className={'nested'}>
                <ListItemIcon />
                <ListItemText primary={'Risk Group'} />
              </ListItem>
            </NavLink>
            <NavLink className='is-active sideMenu subNavItem' to='/Risks'>
              <ListItem className={'nested'}>
                <ListItemIcon />
                <ListItemText primary={'Risks'} className={'ml-2'} />
              </ListItem>
            </NavLink>
            <NavLink className='is-active sideMenu subNavItem' to='/RiskControlMeasure'>
              <ListItem className={'nested'}>
                <ListItemIcon />
                <ListItemText primary={'Risk Control Measure'} className={'ml-2'} />
              </ListItem>
            </NavLink>
            <NavLink className='is-active sideMenu subNavItem' to='/RiskForm'>
              <ListItem className={'nested'}>
                <ListItemIcon />
                <ListItemText primary={'Risk Form'} className={'ml-2'} />
              </ListItem>
            </NavLink>
            <NavLink className='is-active sideMenu subNavItem' to='/Swms'>
              <ListItem className={'nested'}>
                <ListItemIcon />
                <ListItemText primary={'SWMS'} className={'ml-2'} />
              </ListItem>
            </NavLink>
          </Collapse>
        </div>

        {/* other management */}
        <div className={`nav-tab ${state.openOtherManagement ? 'active' : ''}`}>
          <ListItemButton
            onClick={() => {
              handleMenuToggle('openOtherManagement', 'Other', '')
            }}
          >
            <ListItemIcon>
              <ListIcon className={'NavIcon'} />
            </ListItemIcon>
            <ListItemText primary={'Other'} className={'ml-2 parentListName'} />
            {state.openOtherManagement ? <ExpandMore className='arrowIcon'/> : <ExpandLess className='arrowIcon'/>}
          </ListItemButton>
          <Collapse in={state.openOtherManagement} timeout='auto' unmountOnExit>
            {/* <NavLink className='is-active sideMenu subNavItem' to='/ClearStorage'>
              <ListItem className={'nested'}>
                <ListItemIcon />
                <ListItemText primary={'Clear Storage'} className={'ml-2'} />
              </ListItem>
            </NavLink> */}
            <NavLink className='is-active sideMenu subNavItem' to='/Counters'>
              <ListItem className={'nested'}>
                <ListItemIcon />
                <ListItemText primary={'Counters'} />
              </ListItem>
            </NavLink>
            <NavLink className='is-active sideMenu subNavItem' to='/OnHoldReasons'>
              <ListItem className={'nested'}>
                <ListItemIcon />
                <ListItemText primary={'On Hold Reasons'} className={'ml-2'} />
              </ListItem>
            </NavLink>
            <NavLink className='is-active sideMenu subNavItem' to='/Priority'>
              <ListItem className={'nested'}>
                <ListItemIcon />
                <ListItemText primary={'Priority'} className={'ml-2'} />
              </ListItem>
            </NavLink>
            <NavLink className='is-active sideMenu subNavItem' to='/Status'>
              <ListItem className={'nested'}>
                <ListItemIcon />
                <ListItemText primary={'Status'} className={'ml-2'} />
              </ListItem>
            </NavLink>
            <NavLink className='is-active sideMenu subNavItem' to='/Suppliers'>
              <ListItem className={'nested'}>
                <ListItemIcon />
                <ListItemText primary={'Suppliers'} className={'ml-2'} />
              </ListItem>
            </NavLink>
            <NavLink className='is-active sideMenu subNavItem' to='/Suburbs'>
              <ListItem className={'nested'}>
                <ListItemIcon />
                <ListItemText primary={'Suburbs'} className={'ml-2'} />
              </ListItem>
            </NavLink>
            <NavLink className='is-active sideMenu subNavItem' to='/WeeklyTimesheet'>
              <ListItem className={'nested'}>
                <ListItemIcon />
                <ListItemText primary={'Weekly Timesheet'} className={'ml-2'} />
              </ListItem>
            </NavLink>
          </Collapse>
        </div>
      </>
    )
  }

  const renderDrawerList = () => {
    return (
      <>
       <div className='navigation-header'>
       <button
          onClick={() => toggleDrawer()}
          className='navigation-header-button'
        >
          <div className="navigation-header-content">
              <div className="navigation-header-text-Logo">
              {isDrawerOpen ? <WOLogo /> : <WOLogo className="logoSmall" />}
              </div>

              {isDrawerOpen ? <div className="navigation-header-text">
                  <span className="worx-text">
                    Worx</span>
                  <span className="online-text">Online</span></div> : ""}
              </div>
        </button>
        </div>
        <List
          // sx={{ width: '100%', maxWidth: 360 }}
          component='nav'
          aria-labelledby='wo-list-header'
          className='navigation-list'
          // subheader={
          //   <ListSubheader component='div' id='wo-list-header'>
          //     Worx<span className='bold'>Online</span>
          //   </ListSubheader>
          // }
        >
          <div className={`nav-tab ${state.openHome ? 'active' : ''}`}>
            <NavLink
              className='is-active sideMenu'
              to='/'
              onClick={() => {
                handleMenuToggle('openHome', 'Home', '')
              }}
            >
              <ListItem>
                <ListItemIcon>
                  {' '}
                  <HomeIcon id='Home' className={'NavIcon'} />
                </ListItemIcon>
                <ListItemText primary={'Home'} className={'ml-2'} />
              </ListItem>
            </NavLink>
          </div>

          <div className={`nav-tab ${state.openSearch ? 'active' : ''}`}>
            <ListItemButton
              onClick={() => {
                handleMenuToggle('openSearch', 'Search', '')
              }}
            >
              <ListItemIcon>
                <SearchIcon id='SearchIcon' className={'NavIcon'} />
              </ListItemIcon>
              <ListItemText primary={'Search'} className={'ml-2 parentListName'} />
              {state.openSearch ? <ExpandMore className='arrowIcon'/> : <ExpandLess className='arrowIcon'/>}
            </ListItemButton>
            <Collapse in={state.openSearch} timeout='auto' unmountOnExit>
              {/* <List component="div"> */}
              <NavLink className='is-active sideMenu subNavItem' to='/Assets'>
                <ListItem className={'nested'}>
                  {/* <SearchAssets id="SearchAssets" className={"NavIcon"} /> */}
                  <ListItemIcon />
                  <ListItemText primary={'Assets'} className={'ml-2'} />
                </ListItem>
              </NavLink>
              <NavLink className='is-active sideMenu subNavItem' to='/SearchTimesheet'>
                <ListItem className={'nested'}>
                  <ListItemIcon />
                  <ListItemText primary={'Timesheet'} />
                </ListItem>
              </NavLink>
              <NavLink className='is-active sideMenu subNavItem' to='/PlantUsage'>
                <ListItem className={'nested'}>
                  <ListItemIcon />
                  <ListItemText primary={'Plant Usage'} className={'ml-2'} />
                </ListItem>
              </NavLink>
              <NavLink className='is-active sideMenu subNavItem' to='/Searchworkorder'>
                <ListItem className={'nested'}>
                  {/* <SearchTimeSheet id="SearchTimeSheet" className={"NavIcon"} /> */}
                  <ListItemIcon />
                  <ListItemText primary={'Workorders'} className={'ml-2'} />
                </ListItem>
              </NavLink>
              <NavLink className='is-active sideMenu subNavItem' to='/EquipmentAndVehicles'>
                <ListItem className={'nested'}>
                  {/* <SearchTimeSheet id="SearchTimeSheet" className={"NavIcon"} /> */}
                  <ListItemIcon />
                  <ListItemText primary={'Equipment & Vehicles'} />
                </ListItem>
              </NavLink>
              <NavLink className='is-active sideMenu subNavItem' to='/PrestartChecklist'>
                <ListItem className={'nested'}>
                  {/* <SearchAssets id="SearchAssets" className={"NavIcon"} /> */}
                  <ListItemIcon />
                  <ListItemText primary={'Prestart Checklist'} className={'ml-2'} />
                </ListItem>
              </NavLink>
              <NavLink className='is-active sideMenu subNavItem' to='/InspectionForm'>
                <ListItem className={'nested'}>
                  {/* <SearchTimeSheet id="SearchTimeSheet" className={"NavIcon"} /> */}
                  <ListItemIcon />
                  <ListItemText primary={'Inspection Form'} className={'ml-2'} />
                </ListItem>
              </NavLink>
              {/* </List> */}
            </Collapse>
          </div>

          <div className={`nav-tab ${state.openScheduler ? 'active' : ''}`}>
            <NavLink
              className='is-active sideMenu'
              to='/Scheduler'
              onClick={() => {
                handleMenuToggle('openScheduler', 'Scheduler', '')
              }}
            >
              <ListItem className={'nested'}>
                <ListItemIcon>
                  <SchedulerIcon id='SchedulerIcon' className={'NavIcon'} />
                </ListItemIcon>
                <ListItemText primary={'Scheduler'} className={'ml-2'} />
              </ListItem>
            </NavLink>
          </div>

          {(role === global.worxOnline_Admin ||
            role === global.worxOnline_Contractor ||
            role === global.worxOnline_ContractorAdmin) &&
            renderAdminList()}

          <div className='nav-tab'>
            <NavLink className='is-active sideMenu' to='' onClick={logout}>
              <ListItem>
                <ListItemIcon>
                  <SignOutIcon id='SignOutIcon' className={'NavIcon'} />
                </ListItemIcon>
                <ListItemText primary={'SIGN OUT'} className={'signOut ml-2'} />
              </ListItem>
            </NavLink>
          </div>
        </List>

        <div className="navigation-footer">
        <div className="navigation-footer-text">
            <button
              onClick={() => toggleDrawer()}
              className="navigation-header-button"
            >
 
              <div className="navigation-header-text">
                {isDrawerOpen ?<img src={NavLogo} /> : <img src={NavLogoSmall} className="logoSmall" />}
              </div>
            </button>
            <span>version 1.0</span>
          </div>
        </div>
      </>
    )
  }

  return (
    <AssetProvider>
    <div className={`navigation-container ${isDrawerOpen ? 'drawer-open' : 'drawer-closed'}`}>
      {/* <div className='navigation-menu' /> */}
      <Drawer
        opened={isDrawerOpen}
        openedStateMode={`${isSmallDevice ? 'overlap' : 'shrink'}`}
        position='left'
        revealMode={`${isSmallDevice ? 'expand' : 'expand'}`}
        minSize={114}
        closeOnOutsideClick={false}
        component={renderDrawerList}
        shading = {false}
      >
        <div className='main-content' onClick={() => toggleDrawerForMainContent()}>
          <Worxonline role={role} Department={Department} currentUser={currentUser}/>
        </div>
      </Drawer>
    </div>
    </AssetProvider>
  )
}

export default Navigation
