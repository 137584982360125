import React, { useState, useEffect } from 'react'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import utility from '../../../classes/utility'
import global from '../../../classes/global'
import { Config } from '../../../classes/config'
import AlertDialog from '../../Common/AlertDialog'
import DataTable from '../../Common/DataTable'
import DateBoxInput from '../../Common/ReusableComponents/DateBox'
import SelectBoxInput from '../../Common/ReusableComponents/SelectInputBox'
import TextBoxInput from '../../Common/ReusableComponents/TextBox'
import Spinner from '../../Common/ReusableComponents/Spinner'
import Typography from '@mui/material/Typography'
import InputLabel from '@mui/material/InputLabel'



const StatusListItem: any = [
  { id: 'FAIL', text: 'FAIL' },
  { id: 'PASS', text: 'PASS' }
]
const PrestartChecklist = (props: any) => {
  useEffect(() => {
    if (!props.Department) {
      window.open('#/AccessRequest', '_self')
    }
  }, [])

  const columns = [
    {
      field: 'itemFileName',
      title: 'File Name',
      // width: 160,
      type: 'string',
      allowHiding: false,
      // link: 'file'
    },
    {
      field: 'title',
      title: 'Title',
      // width: 160,
      type: 'string',
      allowHiding: false
    },
    {
      field: 'status',
      title: 'Status',
      // width: 280,
      type: 'string',
      allowHiding: false
    },
    {
      field: 'driver',
      title: 'Driver',
      // width: 160,
      type: 'string',
      allowHiding: true
    },
    {
      field: 'department',
      title: 'Department',
      // width: 160,
      type: 'string',
      allowHiding: true
    },
    {
      field: 'crewName',
      title: 'Crew',
      width: 160,
      type: 'string',
      // visibleColumn: false,
      allowHiding: true
    },
    {
      field: 'fieldEmployee',
      title: 'Employee',
      width: 160,
      type: 'string',
      visibleColumn: false,
      allowHiding: true
    },
    {
      field: 'equipmentNumber',
      title: 'Plant Number',
      width: 160,
      type: 'string',
      visibleColumn: false,
      allowHiding: true
    },
    {
      field: 'equipmentName',
      title: 'Plant Name',
      // width: 160,
      type: 'string',
      visibleColumn: false,
      allowHiding: true
    },
    {
      field: 'fileURL',
      title: 'File Url',
      width: 160,
      type: 'string',
      visble: false,
      allowHiding: true
    },
    // {
    //   field: 'createdOn',
    //   title: 'Created',
    //   width: 160,
    //   type: 'datetime',
    //   visibleColumn: false,
    //   allowHiding: true
    // }
  ]
  const searchResultsText = 'Please Search To Populate Results'
  const [items, setItems] = useState([])
  const [callAfterInit, setCallAfterInit] = useState(false)
  const [loading, setLoading] = useState(false)
  const [resultText, setResultText] = useState(searchResultsText)

  const [state, setState] = useState({
    dateCreatedfrom: '',
    dateCreatedTo: '',
    plant: { id: '', text: '' },
    plantNumber: '',
    department: { id: '', text: '' },
    status: { id: '', text: '' },
    user: { id: '', text: '' },
    title: ''
  })
  const [alertMessage, setAlertMessage] = useState('')
  const [showAlert, setShowAlert] = useState(false)
  const [plantItems, setPlantItems] = useState([])
  const [allPlantItems, setAllPlantItems] = useState([])
  const [userItems, setUserItems] = useState([])
  const [allUserItems, setAllUserItems] = useState([])
  const [departmentItems, setDepartmentItems] = useState([])
  const [allDepartmentItems, setAllDepartmentItems] = useState([])

  useEffect(() => {
    let tempstate = localStorage.getItem('prestartDataState')
    if (tempstate) {
      //state = JSON.parse(tempstate)
      let allState = JSON.parse(tempstate)

      setState(prevState => ({
        ...prevState,
        dateCreatedfrom: allState.dateCreatedfrom ? allState.dateCreatedfrom : '',
        dateCreatedTo: allState.dateCreatedTo ? allState.dateCreatedTo : '',
        plant: allState.plant ? allState.plant : '',
        plantNumber: allState.plantNumber ? allState.plantNumber : '',
        department: allState.department ? allState.department : '',
        status: allState.status ? allState.status : ''
      }))
    }
    _getPlantData()
    _getUserData()
    _getDepartmentData()
  }, [])

  const _handleKeyDown = (e: any) => {
    if (e.event.originalEvent.key === 'Enter') {
      getPrestartChecklistwithcriteria()
    }
  }

  const clearFilter = () => {
    setState(prevState => ({
      ...prevState,
      dateCreatedTo: '',
      dateCreatedfrom: '',
      plant: { id: '', text: '' },
      status: { id: '', text: '' },
      user: { id: '', text: '' },
      plantNumber: '',
      title: '',
      department: { id: '', text: '' }
    }))
    setItems([])
    localStorage.setItem('prestartDataState', '')
    let datagrid = localStorage.getItem('datagridFilterValue')
    if (datagrid) {
      localStorage.removeItem('datagridFilterValue')
    }
  }

  const viewFile = (file: any) => {
      let item = file
      let dataToPassToService: any = {}
      if (!utility.validURL(item?.file_url) || !item?.file_name) {
        setAlertMessage('Unable to view this document. \n File URL or File name does exist')
        setShowAlert(true)
        return
      }
      dataToPassToService.file_name = item.file_name
      dataToPassToService.file_url = item.file_url
      utility.genericPostAPIcallForDowloadDoct(Config.dowloadDocumentList, dataToPassToService).then((doc: any) => {
        //console.log("doc",doc);
        window.open(doc, '_blank')
      })
    
  }

  const _getPlantData = () => {
    utility.genericGetAPICallForList(Config.EquipmentsListName).then(
      (data: any) => {
        //console.log(data);
        let plantitemsData: any = []
        for (let r of data) {
          plantitemsData.push({ id: r.id, text: r.title })
        }
        const sortedItems: any = utility._sortItems(plantitemsData, 'text', false)
        setPlantItems(sortedItems)
        setAllPlantItems(sortedItems)
      },
      err => {
        console.log(err)
      }
    )
  }

  const _getUserData = () => {
    utility.genericGetAPICall(Config.employeelistname).then(
      (data: any) => {
        //console.log(data);
        let userItemsData: any = []
        for (let r of data) {
          userItemsData.push({ id: r.id, text: r.full_name })
        }
        const sortedItems: any = utility._sortItems(userItemsData, 'text', false)
        setUserItems(sortedItems)
        setAllUserItems(sortedItems)
      },
      err => {
        console.log(err)
      }
    )
  }

  const _getDepartmentData = () => {
    utility.genericGetAPICall(Config.DepartmentList).then(
      (data: any) => {
        let departmentItemsData: any = []
        if (data && data.length > 0) {
          departmentItemsData = data
            .filter((r: any) => r.is_active === true)
            .map((r: any) => ({
              id: r.id,
              text: r.title
            }))
        }
        departmentItemsData = utility._sortItems(departmentItemsData, 'text', false)
        setDepartmentItems(departmentItemsData)
        setAllDepartmentItems(departmentItemsData)
      },
      err => {
        console.log(err)
      }
    )
  }

  const getPrestartChecklistwithcriteria = () => {
    let criteriaSelected = false

    let dataToPassToService: any = {}

    if (state.title) {
      dataToPassToService.title = state.title
      criteriaSelected = true
    }

    if (state.plant && state.plant.text !== '') {
      dataToPassToService.equipmentId = [state.plant.id]
      criteriaSelected = true
    }
    if (state.status && state.status.text !== '') {
      dataToPassToService.pass_status = [state.status.id]
      criteriaSelected = true
    }
    if (state.user && state.user.text !== '') {
      dataToPassToService.userId = [state.user.id]
      criteriaSelected = true
    }
    if (state.plantNumber) {
      dataToPassToService.equipment_number = [state.plantNumber]
      criteriaSelected = true
    }

    if (state.department && state.department.text !== '') {
      dataToPassToService.department_id = [state.department.id]
      criteriaSelected = true
    }

    if (state.dateCreatedTo && state.dateCreatedfrom) {
      let dcreatedfrom = new Date(state.dateCreatedfrom)
      let dcreatedTo = new Date(state.dateCreatedTo)
      if (dcreatedTo.getTime() < dcreatedfrom.getTime()) {
        setAlertMessage('Date To should be greater than Date From')
        setShowAlert(true)
        return
      }
    }

    if (state.dateCreatedfrom || state.dateCreatedTo) {
      let dfrom
      let dTo
      if (state.dateCreatedfrom) {
        dfrom = new Date(state.dateCreatedfrom).toISOString()
        dataToPassToService.dateFrom = dfrom
      }
      if (state.dateCreatedTo) {
        var extraday = new Date(state.dateCreatedTo)
        extraday.setDate(extraday.getDate() + 1)
        dTo = extraday.toISOString().substring(0, 10) + 'T13:59:59.000Z'
        // dTo =state.dateCreatedTo.toISOString();
        dataToPassToService.dateTo = dTo
      }

      criteriaSelected = true
    }

    if (!criteriaSelected) {
      setAlertMessage('Please select search criteria')
      setShowAlert(true)
      return
    }

    //console.log("dataToPassToService", dataToPassToService);
    setLoading(true)

    utility.genericPostAPICall(Config.SearchPrestartChecklist, dataToPassToService).then(
      (data: any) => {
        let _items:any  = [];
        // console.log(data);
        if (data === null || data.length <= 0) {
          setAlertMessage('No results found for this criteria')
          setShowAlert(true)
          setItems(_items)
          setLoading(false)
          var stateData = { ...state }
          localStorage.setItem('prestartDataState', JSON.stringify(stateData))
          return
        } else {
          data.forEach((item: any) => {
            _items.push({
              id: item.id,
              itemFileName: item.file_name,
              title: item.title,
              fileURL: item.file_url,
              driver: item.driver,
              //department: item.department,
              status: item.pass_status,
              equipmentName: item.equipment_name,
              equipmentNumber: item.equipment_number,
              prestartType: item.prestart_type,
              fieldEmployee: item.field_employee_id,
              crewName: item.crew_name,
              // createdOn: item.created ? item.created : null
            })
          })  
          setItems(_items)
          setLoading(false)
          //console.log(_items);
        }
      },
      (err: any) => {
        console.log(err)
        setLoading(false)
      }
    )
  }

  //   const stopCommsAfterInit = (): void => {
  //    setState({ callAfterInit: false });
  //   }

  const handleValueChange = (event: any, fieldName: string) => {
    setState(prevState => ({
      ...prevState,
      [fieldName]: event.value
    }))
  }

  const handleDropdownChange = (e: any, fieldName: string) => {
    setState(prevState => ({
      ...prevState,
      [fieldName]: e.selectedItem
    }))
  }

  const alert = () => {
    return (
      <AlertDialog
        showDialog={showAlert}
        message={alertMessage}
        title={Config.productTitle}
        onClose={() => (setShowAlert(false), setAlertMessage(''))}
      />
    )
  }

  return (
    <>
      {showAlert && alert()}
      <div className='ui-container'>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={6} lg={4}>
            <InputLabel shrink className='label bold'>
              Date From
            </InputLabel>
            <DateBoxInput
              stylingMode='outlined'
              type='date'
              displayFormat={'dd/MM/yy'}
              showClearButton={true}
              value={state.dateCreatedfrom ? new Date(state.dateCreatedfrom) : null}
              onValueChanged={(e: any) => handleValueChange(e, 'dateCreatedfrom')}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={4}>
            <InputLabel shrink className='label bold'>
              Date To
            </InputLabel>
            <DateBoxInput
              stylingMode='outlined'
              type='date'
              displayFormat={'dd/MM/yy'}
              showClearButton={true}
              value={state.dateCreatedTo ? new Date(state.dateCreatedTo) : null}
              onValueChanged={(e: any) => handleValueChange(e, 'dateCreatedTo')}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={4}>
            <InputLabel shrink className='label bold'>
              User
            </InputLabel>
            <SelectBoxInput
              stylingMode='outlined'
              displayExpr='text'
              valueExpr='id'
              showClearButton={true}
              items={userItems}
              selectedItem={state.user}
              onSelectionChanged={(e: any) => handleDropdownChange(e, 'user')}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={4}>
            <InputLabel shrink className='label bold'>
              Plant and Fleet
            </InputLabel>
            <SelectBoxInput
              stylingMode='outlined'
              displayExpr='text'
              valueExpr='id'
              showClearButton={true}
              items={plantItems}
              selectedItem={state.plant}
              onSelectionChanged={(e: any) => handleDropdownChange(e, 'plant')}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={4}>
            <InputLabel shrink className='label bold'>
              Plant and Fleet Number
            </InputLabel>
            <TextBoxInput
              stylingMode='outlined'
              // pattern={"[A-Za-z]+"}
              // minLength={2}
              value={state.plantNumber}
              onValueChanged={(e: any) => handleValueChange(e, 'plantNumber')}
              onKeyDown={_handleKeyDown}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={4}>
            <InputLabel shrink className='label bold'>
              Status
            </InputLabel>
            <SelectBoxInput
              stylingMode='outlined'
              displayExpr='text'
              valueExpr='id'
              showClearButton={true}
              items={StatusListItem}
              selectedItem={state.status}
              onSelectionChanged={(e: any) => handleDropdownChange(e, 'status')}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={4}>
            <InputLabel shrink className='label bold'>
              Department
            </InputLabel>
            <SelectBoxInput
              stylingMode='outlined'
              displayExpr='text'
              showClearButton={true}
              items={departmentItems}
              selectedItem={state.department}
              onSelectionChanged={(e: any) => handleDropdownChange(e, 'department')}
              disabled={
                props.role === global.worxOnline_Contractor || props.role === global.worxOnline_ContractorAdmin
                  ? true
                  : false
              }
            />
          </Grid>
        </Grid>
        <div className='actions-container'>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}></Grid>
            <Grid item xs={12} sm={6}>
              <div className='action-buttons'>
                <Button variant='outlined' color='secondary' onClick={clearFilter}>
                  Clear
                </Button>
                <Button
                  variant='outlined'
                  color='primary'
                  className='primary-button'
                  onClick={getPrestartChecklistwithcriteria}
                >
                  Search
                </Button>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className=''>
        <h2 className='pl-10'>Results</h2>
        <Typography>{resultText}</Typography>
        {!loading ? (
          <div>
            {items && items.length > 0 && (
              <div className='results-container'>
                <DataTable
                  style={{ width: '100%' }}
                  filteralbe={true}
                  groupable={true}
                  dataSource={items}
                  columns={columns}
                  fileName={'Prestart Checklist'}
                  columnChooser={'prestartchecklistColumns'}
                  selectionMode={false}
                  showHideColumnMenu={true}
                  loading={loading}
                  allowExportSelectedData={false}
                  showNewBtn={false}
                  viewFile={(e: any) => viewFile(e)}
                  showCommandBtn={false}
                  showViewDetailBtn={false}
                />
              </div>
            )}
          </div>
        ) : (
          <Spinner size='large' className='loader' />
        )}
      </div>
    </>
  )
}

export default PrestartChecklist
