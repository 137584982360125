import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import {
    NavLink
  } from "react-router-dom";
  import { useActiveTab } from './ActiveTabContext';
  import { useAssetContext } from '../../AssetContext';
  import clsx from 'clsx';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MoreIcon from '@mui/icons-material/MoreVert';
import GavelIcon from '@mui/icons-material/Gavel';
import DashboardIcon from '@mui/icons-material/Dashboard';
import EventNoteIcon from '@mui/icons-material/EventNote';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ArrowBackIcon from "../../images/ArrowBack.svg";
import Typography from '@mui/material/Typography';

interface WeatherData {
  temperature: string;
  weatherState: string;
  weatherIcon: string;
  date: string;
}

const AppBarPage = () => {
  const location = useLocation();
  const history = useHistory();
  const { activeTab } = useActiveTab();
  const { previousPage, assetDetailItems } = useAssetContext();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState<null | HTMLElement>(null);
    const [isScrolled, setIsScrolled] = useState(false);
    const [pageTitle, setPageTitle] = React.useState<string>("");
    const [pageSubtitle, setPageSubtitle] = React.useState<string | null>(null);
    const [showBackIcon, setShowBackIcon] = React.useState<boolean | undefined>(
      false
    );
  const [backIconLink, setBackIconLink] = React.useState<any>(undefined);
  const [weather, setWeather] = useState<WeatherData | null>(null);
    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  

    useEffect(() => {
        const handleScroll = () => {
          if (window.scrollY > 0) {
            setIsScrolled(true);
          } else {
            setIsScrolled(false);
          }
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);

      // useEffect(() => {
      //   const fetchWeatherData = async () => {
      //     const locationResponse = await fetch(
      //       `https://www.metaweather.com/api/location/search/?query=london`
      //     );
      //     const locationData = await locationResponse.json();
      //     const woeid = locationData[0].woeid;
      //     const weatherResponse = await fetch(
      //       `https://www.metaweather.com/api/location/${woeid}/`
      //     );
      //     const weatherData = await weatherResponse.json();
        
      //     const todayWeather = weatherData.consolidated_weather[0];
      //     return {
      //       temperature: `${Math.round(todayWeather.the_temp)}°C`,
      //       weatherState: todayWeather.weather_state_name, // e.g., Clear, Cloudy
      //       weatherIcon: `https://www.metaweather.com/static/img/weather/png/${todayWeather.weather_state_abbr}.png`, // Dynamic weather icon
      //       date: new Date(weatherData.time).toLocaleDateString("en-US", {
      //         weekday: "short",
      //         day: "numeric",
      //         month: "short",
      //       }),
      //     };
      //   };
      //   fetchWeatherData().then(setWeather);
      // }, []);

      useEffect(() => {
        // Function to extract the last part of the pathname (after the last '/')
        const extractPageNameAndQueryParams = (
          pathname: string,
          search: string
        ) => {
          const parts = pathname.split("/");
          const pageName = parts[parts.length - 1];
    
          // Extract query parameters
          const params = new URLSearchParams(search);
          const communicationId = params.get("communicationId");
    
          return { pageName, communicationId };
        };
    
        const { pageName, communicationId } = extractPageNameAndQueryParams(
          location.pathname,
          location.search
        );
    
        // Map page names to corresponding titles and subtitles
        const pageTitleMap: {
          [key: string]: {
            title: string;
            subtitle?: string;
            showBackIcon?: boolean;
            backIconLink?: string;
          };
        } = {
          "": {
            title: "Home",
            subtitle: undefined,
            showBackIcon: false,
            backIconLink: undefined,
          },
          Assets: {
            title: "Search",
            subtitle: "Assets",
            showBackIcon: true,
            backIconLink: '/',
          },
          SearchTimesheet: {
            title: "Search",
            subtitle: "Timesheet",
            showBackIcon: true,
            backIconLink: '/',
          },
          PlantUsage: {
            title: "Search",
            subtitle: "Plant Usage",
            showBackIcon: true,
            backIconLink: '/',
          },
          Searchworkorder: {
            title: "Search",
            subtitle: "Work orders",
            showBackIcon: true,
            backIconLink: '/',
          },
          EquipmentAndVehicles: {
            title: "Search",
            subtitle: "Equipment & Vehicles",
            showBackIcon: true,
            backIconLink: '/',
          },
          PrestartChecklist: {
            title: "Search",
            subtitle: "Prestart Checklist",
            showBackIcon: true,
            backIconLink: '/',
          },
          InspectionForm: {
            title: "Search",
            subtitle: "Inspection Form",
            showBackIcon: true,
            backIconLink: '/',
          },
          Scheduler: {
            title: "Scheduler",
            subtitle: undefined,
            showBackIcon: false,
            backIconLink: undefined,
          },
          Employees: {
            title: "User Management",
            subtitle: "Users",
            showBackIcon: true,
            backIconLink: '/',
          },
          Crews: {
            title: "User Management",
            subtitle: "Crews",
            showBackIcon: true,
            backIconLink: '/',
          },
          Teams: {
            title: "User Management",
            subtitle: "Teams",
            showBackIcon: true,
            backIconLink: '/',
          },
          Departments: {
            title: "User Management",
            subtitle: "Departments",
            showBackIcon: true,
            backIconLink: '/',
          },
          Activities: {
            title: "Work Management",
            subtitle: "Activities",
            showBackIcon: true,
            backIconLink: "/",
          },
          WorkTypeField: {
            title: "Work Management",
            subtitle: "Activity Field",
            showBackIcon: true,
            backIconLink: "/",
          },
          DefectCode: {
            title: "Work Management",
            subtitle: "Defect Code",
            showBackIcon: true,
            backIconLink: "/",
          },
          Equipment: {
            title: "Work Management",
            subtitle: "Plant & Fleet",
            showBackIcon: true,
            backIconLink: "/",
          },
          Events: {
            title: "Work Management",
            subtitle: "Events",
            showBackIcon: true,
            backIconLink: "/",
          },
          Forms: {
            title: "Work Management",
            subtitle: "Forms",
            showBackIcon: true,
            backIconLink: "/",
          },
          Materials: {
            title: "Work Management",
            subtitle: "Materials",
            showBackIcon: true,
            backIconLink: "/",
          },
          PrestartForms: {
            title: "Work Management",
            subtitle: "Prestart Forms",
            showBackIcon: true,
            backIconLink: "/",
          },
          Tcp: {
            title: "Work Management",
            subtitle: "TCP",
            showBackIcon: true,
            backIconLink: "/",
          },
          WorkRequestActivities: {
            title: "Work Management",
            subtitle: "Work Request Activities",
            showBackIcon: true,
            backIconLink: '/',
          },
          RiskCategory: {
            title: "Risk Management",
            subtitle: "Risk Category",
            showBackIcon: true,
            backIconLink: '/',
          },
          RiskGroup: {
            title: "Risk Management",
            subtitle: "Risk Group",
            showBackIcon: true,
            backIconLink: '/',
          },
          Risks: {
            title: "Risk Management",
            subtitle: "Risks",
            showBackIcon: true,
            backIconLink: '/',
          },
          RiskControlMeasure: {
            title: "Risk Management",
            subtitle: "Risk Control Measure",
            showBackIcon: true,
            backIconLink: '/',
          },
          RiskForm: {
            title: "Risk Management",
            subtitle: "Risk Form",
            showBackIcon: true,
            backIconLink: '/',
          },
          Swms: {
            title: "Risk Management",
            subtitle: "SWMS",
            showBackIcon: true,
            backIconLink: '/',
          },
          Counters: {
            title: "Other Management",
            subtitle: "Counters",
            showBackIcon: true,
            backIconLink: "/",
          },
          OnHoldReasons: {
            title: "Other Management",
            subtitle: "On Hold Reasons",
            showBackIcon: true,
            backIconLink: "/",
          },
          Priority: {
            title: "Other Management",
            subtitle: "Priority",
            showBackIcon: true,
            backIconLink: "/",
          },
          Status: {
            title: "Other Management",
            subtitle: "Status",
            showBackIcon: true,
            backIconLink: "/",
          },
          Suppliers: {
            title: "Other Management",
            subtitle: "Suppliers",
            showBackIcon: true,
            backIconLink: "/",
          },
          Suburbs: {
            title: "Other Management",
            subtitle: "Suburbs",
            showBackIcon: true,
            backIconLink: "/",
          },
          WeeklyTimesheet: {
            title: "Other Management",
            subtitle: "Weekly Timesheet",
            showBackIcon: true,
            backIconLink: "/",
          },
          Profile: {
            title: "Profile",
            subtitle: undefined,
            showBackIcon: true,
            backIconLink: "/",
          },
          AccessDenied: {
            title: "Access Denied",
            showBackIcon: true,
            backIconLink: "/",
          },
        };
    
        // Set the page title and subtitle based on the mapping or use default values
        const { title, subtitle, showBackIcon, backIconLink } = pageTitleMap[
          pageName
        ] || {
          title: "",
          subtitle: undefined,
          showBackIcon: false,
          backIconLink: undefined,
        };
        setPageTitle(title);
        setShowBackIcon(showBackIcon);
        setPageSubtitle(subtitle ? subtitle : null);
        setBackIconLink(backIconLink);
      }, [location.pathname, location.search]);

      const handleBackButtonClick = () => {
        if (backIconLink !== undefined) {
          history.push(backIconLink); // Navigate to the provided link
        } else {
          history.push(-1); // Go back one step in history
        }
      };

      const handleCreateWorkorder = () => {
        const assetItems = assetDetailItems || JSON.parse(localStorage.getItem('assetDetailItems') || 'null');
        if (previousPage === '/AssetDetail') {
          history.push('/CreateWorkorder', { assetItems });
        } else {
          history.push('/CreateWorkorder');
        }
      };
  
    const handleMobileMenuClose = () => {
      setMobileMoreAnchorEl(null);
    };
  
    const handleMenuClose = () => {
      setAnchorEl(null);
      handleMobileMenuClose();
    };
  
    const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
      setMobileMoreAnchorEl(event.currentTarget);
    };
    const logOut = () => {
      //instance.logoutRedirect({ postLogoutRedirectUri: "/" })
      //window.open('/#/SignIn', '_self');
    }

    const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <NavLink className="is-active" to="/Profile">
        <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
    </NavLink>
      <MenuItem onClick={logOut}>Logout</MenuItem>
      <div className='button-container'>
        {/* <NavLink to="/CreateWorkOrder" className="CreateWOButton">Create Workorder</NavLink> */}
        <button onClick={handleCreateWorkorder}>Create Workorder</button>
      </div>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >

      <MenuItem>
        {/* <Notification isMobile={true} /> */}
        <p>Notifications</p>
      </MenuItem>
      <NavLink className="is-active" to="/Profile">
        <MenuItem>
          <IconButton
            aria-label="account of current user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
          <p>Profile</p>
        </MenuItem>
      </NavLink>
      <MenuItem onClick={logOut}>
        <IconButton
          aria-label="logout"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <ExitToAppIcon />
        </IconButton>
        <p>Logout</p>
      </MenuItem>
      <div className='button-container'>
        <NavLink to="/CreateWorkOrder" className="CreateWOButton">Create Workorder</NavLink>
      </div>
    </Menu>
  );

  return (
    <>
      <AppBar position="static"
        className={`${isScrolled ? 'scrolledAppBar' : ''}`}
      >
        <Toolbar>
        <Grid container className="appbar-container">
          <Grid item xs={6} sm={6} md={4}>
          <div className="appBarTitle">
                {showBackIcon && (
                 <img
                 src={ArrowBackIcon}
                 onClick={handleBackButtonClick}
                 className="backIcon"
               />
                )}
                <div className="appBar-heading-container">
                  <h2 className="appBar-heading">{pageTitle}</h2>
                  {pageSubtitle ? (
                    <span className="appBar-subHeading">{pageSubtitle}</span>
                  ) : null}
                </div>
              </div>
            {/* <h2>{`${activeTab.main}${activeTab.sub ? ` - ${activeTab.sub}` : ''}`}</h2>   */}
          </Grid>
            <Grid item xs={6} sm={6} md={8}>
            <>
                  <div className={'sectionDesktop'}>
                    {/* <Notification isMobile={false} /> */}
                    {weather && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={weather.weatherIcon}
              alt={weather.weatherState}
              style={{
                width: "32px",
                height: "32px",
                marginRight: "8px",
              }}
            />
            <Typography variant="body1" style={{ fontWeight: "bold" }}>
              {weather.temperature}
            </Typography>
            <Typography
              variant="body2"
              style={{ marginLeft: "8px", color: "gray" }}
            >
              {weather.weatherState}
            </Typography>
            <Typography variant="caption" style={{ marginLeft: "8px" }}>
              {weather.date}
            </Typography>
          </div>
        )}

                    <NavLink to="/Profile">
                      <IconButton
                        edge="start"
                        aria-label="Go to profile"
                        // aria-controls={menuId}
                        // aria-haspopup="true"
                        // onClick={handleProfileMenuOpen}
                        color="inherit"
                      >
                        <AccountCircle className={'navIcon profile'} />
                      </IconButton >
                    </NavLink>
                    <IconButton
                      edge="start"
                      aria-label="Log out"
                      // aria-controls={menuId}
                      aria-haspopup="true"
                      onClick={logOut}
                      color="inherit"
                    >
                      <ExitToAppIcon className={'navIcon logout'} />
                    </IconButton >
                    
                    {(activeTab.main === 'Home' || activeTab.main === 'Search' || activeTab.main === 'Scheduler') && (
                      <div className='button-container'>
                        <NavLink to="/CreateWorkOrder" className="CreateWOButton">Create Workorder</NavLink>
                      </div>
                    )}
 
                  </div>
                  {/* <div className={'sectionMobile'}>
                    <IconButton
                      aria-label="show more"
                      aria-controls={mobileMenuId}
                      aria-haspopup="true"
                      onClick={handleMobileMenuOpen}
                      color="inherit"
                    >
                      <MoreIcon className={'navIcon'} />
                    </IconButton>
                  </div> */}
                </> 
              
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
      </>
  )
  
}

export default AppBarPage