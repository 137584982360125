import React, { useState, useEffect, useCallback } from 'react'
import ReactDOM from 'react-dom'
import utility from '../../../classes/utility'
import { Config } from '../../../classes/config'
import DataTable from '../../Common/CustomASDataGrid'
import Spinner from '../../Common/ReusableComponents/Spinner'
import global from '../../../classes/global'
import CustomStore from 'devextreme/data/custom_store'
import { LoadOptions } from 'devextreme/data'
import notify from 'devextreme/ui/notify';
import DropZoneBox from '../../Common/ReusableComponents/DropZoneBox';

interface LoadResult {
  data: any[]
  totalCount: number
}

const Swms = (props: any) => {
  if (props.role !== global.worxOnline_Admin) {
    window.open('#/AccessDenied', '_self')
  }

  const [dataLoaded, setDataLoaded] = useState(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [items, setItems] = useState<any[]>([])
  const [depts, setDepts] = useState<any[]>([])
  const [editDetails, setEditDetails] = useState<any>(null);
  const [uploadedFiles, setUploadedFiles] = useState<any>([]);
  const [dataURL, setDataURL] = useState<any>([]);

  const setDeptValue = (rowData: any, value: number) => {
    rowData.department_ids = value || null;
    const dept = depts.find(d => d.department_ids === value);
    if (dept) {
      rowData.department_name = dept.department_name;
    }
  };

  const showErrorNotification = (message: string) => {
    notify({
      message: message,
      type: 'error',
      displayTime: 3000,
      position: {
        my: 'center top',
        at: 'center top'
      }
    });
  };

  const columns = [
    {
      field: 'id',
      title: 'ID',
      width: 70,
      type: 'string',
      allowHiding: true,
      visibleColumn: false,
      formItem: {
        visible: false
      }
    },
    {
      field: 'document_number',
      title: 'Document #',
      width: 120,
      type: 'string',
      allowHiding: true,
      isRequired: true
    },
    {
      field: 'file_name',
      title: 'Name',
      width: 400,
      type: 'string',
      allowHiding: false,
      link: 'file'
    },
    {
      field: 'title',
      title: 'Title',
      width: 280,
      type: 'string',
      allowHiding: true,
      isRequired: true
    },
    {
      field: 'department_ids',
      title: 'Department',
      width: 200,
      type: 'string',
      allowHiding: false,
      setCellValue: setDeptValue,
      cellRender: (cellData: any) => {
        return cellData.data.dept_titles
      },
      formItem: {
        visible: true,
        editorType: 'dxTagBox',
        editorOptions: {
          dataSource: depts,
          displayExpr: 'department_name',
          valueExpr: 'department_ids',
          placeholder: 'Select Department',
          showSelectionControls: true
        }
      }
    },
    {
      field: 'file_url',
      title: 'External Url',
      width: 100,
      type: 'string',
      visble: false,
      allowHiding: true,
    },
    {
      field: 'is_active',
      title: 'Active',
      // width: 160,
      type: 'boolean',
      calculateCellValue: (data: any) => data.is_active ?? true, // Default to true
      setCellValue: (newData: any, value: any) => {
        newData.is_active = value
      }
    },
    {
      field: 'attachment',
      title: 'Attachment',
      width: 120,
      type: 'string',
      visibleColumn: false,
      allowHiding: true,
      editCellComponent: (props: any) => (
        <DropZoneBox 
          cellData={props}
          editDetails={editDetails}
          uploadedFiles={uploadedFiles}
          setUploadedFiles={setUploadedFiles}
          fileType="application/pdf"
          fileSizeLimit={5}
        />
      ),
      formItem: {
        visible: true
      }
    },
    {
      field: 'created',
      title: 'Created',
      width: 160,
      type: 'datetime',
      visibleColumn: false,
      allowHiding: true,
      formItem: {
        visible: false,
      }
    },
    {
      field: 'created_by',
      title: 'Created By',
      width: 160,
      type: 'string',
      visibleColumn: false,
      allowHiding: true,
      formItem: {
        visible: false,
      }
    },
    {
      field: 'updated',
      title: 'Updated',
      width: 160,
      type: 'datetime',
      visibleColumn: false,
      allowHiding: true,
      formItem: {
        visible: false,
      }
    },
    {
      field: 'updated_by',
      title: 'Updated By',
      width: 160,
      type: 'string',
      visibleColumn: false,
      allowHiding: true,
      formItem: {
        visible: false,
      }
    }
  ]

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const deptResponse = await utility.genericGetAPICall(`${Config.DepartmentList}`)
        const deptData = deptResponse
          .filter((r: any) => r.is_active === true)
          .map((r: any) => ({
            department_ids: r.id,
            department_name: r.title
          }))
        setDepts(deptData)

        await fetchItemsData()

        setLoading(false)
        setDataLoaded(true)
      } catch (error) {
        console.error(error)
        setLoading(false)
      }
    }

    fetchData()
  }, [])

  const fetchItemsData = async () => {
    setLoading(true)
    try {
      const itemsResponse = await utility.genericGetAPICall(Config.searchswmsDocumentList)
      const itemsData = itemsResponse.map((r: any) => ({
        ...r,
        dept_titles: r.departments?.map((d: any) => d.title).join(', '),
        is_active: r.is_active ? true : false
      }))
      setItems(itemsData)
      setLoading(false)
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  }

  const uploadAttachment = async () => {
    let file = uploadedFiles;
    let accepted = true;
    if (uploadedFiles) {
      uploadedFiles.forEach((item: any) => {
        if (item.file.accepted === false) {
          accepted = false;
        }
      });
      if (!accepted) {
        showErrorNotification('Selected file is not accepted. Please select another file.');
        return;
      }
    }

    // Perform file upload logic here
  }


  const viewDocument = async (file: any) => {
    try {
      if (file !== null) {
        let item = file

        if (file.is_external_url) {
          window.open(file.file_url, '_blank')
        } else {
          let dataToPassToService: any = {}
          if (!utility.validURL(item.file_url) || !item.file_name) {
           showErrorNotification('Unable to view this document. \n File URL or File name does exist')
            // setAlertMessage('Unable to view this document. \n File URL or File name does exist')
            // setShowAlert(true)
            return
          }
          dataToPassToService.file_name = item.name
          dataToPassToService.file_url = item.fileURL
          const doc: any = await utility.genericPostAPIcallForDowloadDoct(
            Config.dowloadDocumentList,
            dataToPassToService
          )
          window.open(doc, '_blank')
        }
      }
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  }

  const processFileData = (values: any, fileKey: string) => {
    if (values[fileKey] && values[fileKey].length > 0) {
      let d = new Date();
      let amOrPm = (d.getHours() < 12) ? "AM" : "PM";
      let hour = (d.getHours() < 12) ? d.getHours() : d.getHours() - 12;
      let date = `${d.getDate()}_${d.getMonth() + 1}_${d.getFullYear()}_${hour}_${amOrPm}`;
  
      let selectedFile = values[fileKey][0].file;
      let data: string | undefined = undefined;

      if (selectedFile.type.startsWith("image/")) {
        // Handle images by converting to dataURL
        data = selectedFile.dataURL.replace(`data:${selectedFile.type};base64,`, "");
      } else if (selectedFile.type === "application/pdf") {
        // For PDFs, store its content directly
        const reader = new FileReader();
        reader.readAsDataURL(selectedFile);
        reader.onload = function () {
          data = reader.result?.toString().replace(`data:${selectedFile.type};base64,`, "");
        };
      }
      // Destructure values to exclude the fileKey
      const { [fileKey]: file, ...restValues } = values;
  
      return {
        ...restValues,
        file_name: selectedFile.name,
        filethumbnail_url_name: data,
        is_external_url: (values.file_url && values.file_url !== '') ? true : false
      };
    }
    return values;
  };

  const dataSource = new CustomStore({
    load: async (loadOptions: LoadOptions): Promise<LoadResult> => {
      return {
        data: items,
        totalCount: items.length
      }
    },
    key: 'id',
    onLoaded: () => {
      console.log('Data loaded')
      setLoading(false)
      setDataLoaded(true)
    },
    insert: async (values: any) => {
      try {
        const dataToPassToService = processFileData(values, 'attachment');
        await utility.genericPostAPICall(Config.swmsDocumentList, dataToPassToService)
        notify('Row added successfully.', 'success', 3000);
        await fetchItemsData()
      } catch (error:any) {
        const errorMessage = error.message || 'An unexpected error occurred.';
        notify(errorMessage, 'error', 5000); 
        console.error(errorMessage);
      }
    },
    update: async (key: any, values: any) => {
      try {
        if (key) {
          await uploadAttachment();
        }
        const dataToPassToService = processFileData(values, 'attachment');
        await utility.genericUpdatePatchAPICall(Config.swmsDocumentList, key, dataToPassToService)
        notify('Row updated successfully.', 'success', 3000)
        await fetchItemsData()
      } catch (error:any) {
        const errorMessage = error.message || 'An unexpected error occurred.';
        notify(errorMessage, 'error', 5000); 
        console.error(errorMessage);
      }
    },
    remove: async (key: any) => {
      try {
        await utility.genericDeleteAPICall(Config.swmsDocumentList, key)
        notify('Row deleted successfully.', 'success', 3000)
        await fetchItemsData()
      } catch (error:any) {
        const errorMessage = error.message || 'An unexpected error occurred.';
        notify(errorMessage, 'error', 5000); 
        console.error(errorMessage);
      }
    }
  })

  return (
    <>
      <div>
        {!loading ? (
          <div className='results-container'>
            <DataTable
              dataSource={dataSource}
              style={{ width: '100%' }}
              filteralbe={true}
              groupable={true}
              columns={columns}
              fileName={'Swms'}
              columnChooser={'SwmsColumns'}
              selectionMode={false}
              showHideColumnMenu={true}
              loading={loading}
              allowExportSelectedData={false}
              columnHidingEnabled={true}
              showCommandBtn={false}
              hideSearchPanel={false}
              hideCommandDeleteBtn={false}
              showNewBtn={false}
              showViewDetailBtn={false}
              hideNewBtn={true}
              showMoreBtn={true}
              viewFile={(e: any) => viewDocument(e)}
              visible={dataLoaded}
            />
          </div>
        ) : (
          <Spinner size='large' className='loader' />
        )}
      </div>
    </>
  )
}
export default Swms
