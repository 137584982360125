import React, { useState, useEffect } from 'react'
import utility from '../../../classes/utility'
import { Config } from '../../../classes/config'
import DataTable from '../../Common/CustomASDataGrid'
import Spinner from '../../Common/ReusableComponents/Spinner'
import global from '../../../classes/global'
import CustomStore from "devextreme/data/custom_store";
import { LoadOptions } from "devextreme/data";
import notify from 'devextreme/ui/notify';

interface Dept {
  department_id: number;
  department_name: string;
}

interface LoadResult {
  data: any[];
  totalCount: number;
}


const Counter = (props: any) => {
  if (props.role !== global.worxOnline_Admin) {
    window.open('#/AccessDenied', '_self')
  }

  const [items, setItems] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [depts, setDepts] = useState<Dept[]>([]);

  const setDepartmentValue = (rowData: any, value: number) => {
    rowData.department_id = value || null;
    const dept = depts.find(d => d.department_id === value);
    if (dept) {
      rowData.department_name = dept.department_name;
    }
  };


  const columns = [
    {
      field: 'id',
      title: 'ID',
      width: 80,
      type: 'string',
      allowHiding: false,
      visibleColumn: false,
      formItem: {
        visible: false
      }
    },
    {
      field: 'title',
      title: 'Title',
      // width: 160,
      type: 'string',
      allowHiding: false,
      isRequired: true
    },
    {
      field: 'department_id',
      title: 'Department',
      type: 'string',
      allowHiding: true,
      isRequired: true,
      setCellValue: setDepartmentValue,
      Lookup: {
        dataSource: depts,
        valueExpr: 'department_id',
        displayExpr: 'department_name'
      }
    },
    {
      field: 'counter',
      title: 'Counter',
      // width: 160,
      type: 'number',
      allowHiding: true,
      isRequired: true
    },
    {
      field: 'is_active',
      title: 'Active',
      type: 'boolean',
      calculateCellValue: (data: any) => data.is_active ?? true, // Default to true
      setCellValue: (newData: any, value: any) => {
        newData.is_active = value
      }
    },
    {
      field: 'created',
      title: 'Created',
      width: 160,
      type: 'datetime',
      visibleColumn: false,
      allowHiding: true,
      formItem: {
        visible: false,
      }
    },
    {
      field: 'created_by',
      title: 'Created By',
      width: 160,
      type: 'string',
      visibleColumn: false,
      allowHiding: true,
      formItem: {
        visible: false,
      }
    },
    {
      field: 'updated',
      title: 'Updated',
      width: 160,
      type: 'datetime',
      visibleColumn: false,
      allowHiding: true,
      formItem: {
        visible: false,
      }
    },
    {
      field: 'updated_by',
      title: 'Updated By',
      width: 160,
      type: 'string',
      visibleColumn: false,
      allowHiding: true,
      formItem: {
        visible: false,
      }
    }
  ]

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const deptResponse = await utility.genericGetAPICall(`${Config.DepartmentList}`);
        const deptData = deptResponse
          .filter((r: any) => r.is_active === true)
          .map((r: any) => ({
            department_id: r.id,
            department_name: r.title
          }));
        setDepts(deptData);

        await itemsData();
        setDataLoaded(true);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const itemsData = async () => {
    setLoading(true);
    try {
      const itemResponse = await utility.genericGetAPICall(Config.CounterList);
      const itemData = itemResponse.map((r: any) => ({
        ...r,
        department_name: r.department?.title,
        department_id: r.department_id,
        is_active: r.is_active ? true : false
      }));
      setItems(itemData);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const dataSource = new CustomStore({
    load: async (loadOptions: LoadOptions): Promise<LoadResult> => {
      return {
        data: items,
        totalCount: items.length
      };
    },
    key: 'id',
    onLoaded: () => {
      console.log('Data loaded');
      setLoading(false);
      setDataLoaded(true);
    },
    insert: async (values: any) => {
      try {
        await utility.genericPostAPICall(Config.CounterList, values);
        notify('Row added successfully', 'success', 3000);
        await itemsData();
      } catch (error:any) {
        const errorMessage = error.message || 'An unexpected error occurred.';
        notify(errorMessage, 'error', 5000); 
        console.error(errorMessage);
      }
    },
    update: async (key: any, values: any) => {
      try {
        await utility.genericUpdatePatchAPICall(Config.CounterList, key, values);
        notify('Row updated successfully', 'success', 3000);
        await itemsData();
      } catch (error:any) {
        const errorMessage = error.message || 'An unexpected error occurred.';
        notify(errorMessage, 'error', 5000); 
        console.error(errorMessage);
      }
    },
    remove: async (key: any) => {
      try {
        await utility.genericDeleteAPICall(Config.CounterList, key);
        notify('Row deleted successfully', 'success', 3000);
        await itemsData();
      }catch (error:any) {
        const errorMessage = error.message || 'An unexpected error occurred.';
        notify(errorMessage, 'error', 5000); 
        console.error(errorMessage);
      }
    }
  });

  return (
    <>
      <div>
        {!loading ? (
          <div className='results-container'>
            <DataTable
              dataSource={dataSource}
              style={{ width: '100%' }}
              filteralbe={true}
              groupable={true}
              columns={columns}
              fileName={'counter'}
              columnChooser={'counterColumns'}
              selectionMode={false}
              showHideColumnMenu={true}
              loading={loading}
              allowExportSelectedData={false}
              columnHidingEnabled={true}
              showCommandBtn={false}
              hideSearchPanel={false}
              hideCommandDeleteBtn={false}
              showNewBtn={false}
              showViewDetailBtn={false}
              hideNewBtn={true}
              //defaultfiltervalue={filterValue}
              showMoreBtn={true}
              visible={dataLoaded}
            />
          </div>
        ) : (
          <Spinner size='large' className='loader' />
        )}
      </div>
    </>
  );
};
 
export default Counter;
