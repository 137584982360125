import React, { useState, useEffect, useCallback } from 'react'
import utility from '../../../classes/utility';
import { Config } from '../../../classes/config';
import DataTable from '../../Common/CustomASDataGrid';
import Spinner from '../../Common/ReusableComponents/Spinner';
import global from '../../../classes/global';
import CustomStore from 'devextreme/data/custom_store'
import { LoadOptions } from 'devextreme/data'
import TreeViewComponent from '../../Common/ReusableComponents/TreeViewComponent'
import notify from 'devextreme/ui/notify';

interface LoadResult {
  data: any[];
  totalCount: number;
}

interface RiskGroup {
  id: number;
  title: string;
  is_active: boolean;
  category_id: number;
}

interface RiskCategory {
  id: number;
  title: string;
  risk_groups: RiskGroup[];
}

let selectedRiskGroups: any = []
let riskCategoryData: any = []

const Risk = (props: any) => {
    if (props.role !== global.worxOnline_Admin) {
      window.open('#/AccessDenied', '_self');
    }
  const [dataLoaded, setDataLoaded] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [items, setItems] = useState<any[]>([]);
  const [riskCategories, setRiskCategories] = useState<RiskCategory[]>([]);
  const [swms, setSwms] = useState<any[]>([]);
  const [RiskHierarchys, setRiskHierarchys] = useState<any[]>([]);
  const [selectedSwmsIds, setSelectedSwmsIds] = useState<any[]>([]);
  const filterValue = [['is_active', '=', true]];
  const [selectedKeys, setSelectedKeys] = useState<any[]>([])

    const setCategoryValue = (rowData: any, value: number) => {
      rowData.category_id = value || null;
      const category = riskCategories.find((d:any) => d.category_id === value);
      if (category) {
        rowData.title = category.title;
      }
    };
  
    const setRiskGroupIdsValue = (rowData: any, value: any[]) => {
      rowData.risk_group_ids = value || []
    } 

   const setSwmsValue = (rowData: any, value: number) => {
      rowData.swms_ids = value || null;
      const swm = swms.find((d:any) => d.swms_ids === value);
      if (swm) {
        rowData.swms_name = swm.swms_name;
      }
    }

    const onUpdateSelectedKeys = useCallback((selectedKeys: number[]) => {
      console.log('Selected Keys:', selectedKeys)
      selectedRiskGroups = selectedKeys
    }, [])

    const columns = [
      {
        field: 'id',
        title: 'ID',
        width: 80,
        type: 'string',
        allowHiding: false,
        visibleColumn: false,
        formItem: {
          visible: false
        }
      },
      {
        field: 'title',
        title: 'Title',
        width: 250,
        type: 'string',
        allowHiding: false,
        isRequired: true
      },
      {
        field: 'formatted_risk_titles',
        title: 'Risk Groups',
        // width: 280,
        type: 'string',
        formItem: {
          visible: false,
        }
      },
      {
        field: 'swms_ids',
        title: 'Read Safe Work Procedure',
        // width: 280,
        type: 'string',
        setCellValue: setSwmsValue,
        Lookup: {
          dataSource: swms,
          valueExpr: 'swms_ids',
          displayExpr: 'swms_name'
        },
        cellRender:   (cellData: any) => {
          return cellData.data.formatted_swms_titles
        },
        formItem: {
          visible: true,
          editorType: 'dxTagBox',
          editorOptions: {
            //dataSource: swms,
            displayExpr:"swms_name",
            valueExpr:"swms_ids",
            //placeholder: 'Select Team',
            showSelectionControls: true
          }
        }
      },
      {
        field: 'is_active',
        title: 'Active',
        // width: 160,
        type: 'boolean',
        allowHiding: false,
        calculateCellValue: (data: any) => data.is_active ?? true, // Default to true
      setCellValue: (newData: any, value: any) => {
        newData.is_active = value
      }
      },
      {
        field: 'tcp',
        title: 'TCP',
        // width: 280,
        type: 'boolean',
        calculateCellValue: (data: any) => data.tcp ?? false, // Default to false
      setCellValue: (newData: any, value: any) => {
        newData.tcp = value
      }
      },
      {
        field: 'risk_group_ids',
        title: 'Risk Group',
        width: 120,
        type: 'string',
        visibleColumn: false,
        allowHiding: true,
        // Lookup: {
        //   dataSource: riskCategories,
        //   valueExpr: 'id',
        //   displayExpr: 'title'
        // },
        setCellValue: setRiskGroupIdsValue,
        editCellComponent: (props: any) => (
          <TreeViewComponent
            cellData={props}
            treeViewItems={props.data?.data?.transformedRiskCategories || riskCategoryData}
            onUpdateSelectedKeys={onUpdateSelectedKeys}
            selectedKeys={selectedKeys}
          />
        ),
        formItem: {
          visible: true
        }
      },
      {
        field: 'created',
        title: 'Created',
        width: 160,
        type: 'datetime',
        visibleColumn: true,
        allowHiding: true,
        formItem: {
          visible: false,
        }
      },
      {
        field: 'created_by',
        title: 'Created By',
        width: 160,
        type: 'string',
        visibleColumn: true,
        allowHiding: true,
        formItem: {
          visible: false,
        }
      },
      {
        field: 'updated',
        title: 'Updated',
        width: 160,
        type: 'datetime',
        visibleColumn: true,
        allowHiding: true,
        formItem: {
          visible: false,
        }
      },
      {
        field: 'updated_by',
        title: 'Updated By',
        width: 160,
        type: 'string',
        visibleColumn: true,
        allowHiding: true,
        formItem: {
          visible: false,
        }
      }
    ];

    useEffect(() => {
      const fetchData = async () => {
        setLoading(true);
        try {
          const swmsResponse:any =  await utility.genericGetAPICall(Config.swmsDocumentList);
          let swmsItemsData = swmsResponse.filter((r: any) => r.is_active === true).map((r: any) => ({ 
            swms_ids: r.id, 
            swms_name: r.title 
          }));
          const sortedSwmsItems = utility._sortItems(swmsItemsData, 'title', false);
         setSwms(sortedSwmsItems);
  
          const riskHierarchyResponse: any = await utility.genericGetAPICall(Config.RiskHierarchy);
          const riskHierarchyData = riskHierarchyResponse.map((r: any) => ({id: r.id, title: r.title }));
          setRiskHierarchys(riskHierarchyData)
  
        setLoading(false);
        setDataLoaded(true);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };
  
      fetchData();
    }, []);

    const transformToTreeStructure = (data: any, selectedIds: any) => {
      return data.map((category: any) => {
        const items = category.risk_groups.map((group: any) => ({
          id: `group-${group.id}`,
          title: group.title,
          is_active: group.is_active,
          category_id: group.category_id,
          parentId: category.id,
          selected: selectedIds && selectedIds.includes(group.id) // Mark as selected if in selectedIds
        }))
  
        // Determine if any item is selected
        const isExpanded = items.some((item: any) => item.selected)
  
        return {
          id: category.id,
          title: category.title,
          created: category.created,
          created_by: category.created_by,
          risk_groups: category.risk_groups,
          parentId: category.parentId,
          items: items,
          expanded: isExpanded
          //selected: selectedIds.includes(category.id)
        }
      })
    }
  
    const dataSource = new CustomStore({
      load: async (loadOptions: LoadOptions): Promise<LoadResult> => {
        const riskCategoriesData: any = await utility.genericGetAPICall(Config.RiskCategorieswithGroups)
        riskCategoryData = transformToTreeStructure(riskCategoriesData, [])
        //console.log('Initial risk categories data:', riskCategories)

        return await utility.genericGetAPICall(Config.GetAllRisksWithRiskGroups).then((riskResponse: any) => {
        const riskData = riskResponse.map((r: any) => {
          const selectedRiskGroupIds = r.risk_group_ids;
          const transformedRiskCategories = transformToTreeStructure(riskCategoriesData, selectedRiskGroupIds)
          return {
          ...r,
          transformedRiskCategories,
          formatted_risk_titles: r.risk_groups?.map((rg: any) => rg.title).join(', '),
          formatted_swms_titles: r.swms_ids
          ? r.swms_ids.map((t: any) => swms?.find((td:any) => td.swms_ids === t)?.swms_name).join(', ')
          : '',
          is_active: r.is_active ? true : false,
        }
      })
        //console.log('Transformed data with risk categories:', riskData)
        return {
          data: riskData,
          totalCount: riskData.length
        };
      })
      },
      key: 'id',
      onLoaded: () => {
        console.log('Data loaded');
        setLoading(false);
        setDataLoaded(true);
      },
      insert: async (values: any) => {
        try {
          if (values.risk_group_ids) {
            values.risk_group_ids = values.risk_group_ids.map((item: any) => {
              if (typeof item === 'string' && item.startsWith("group-")) {
                return Number(item.replace("group-", ""));
              }
              return Number(item); // Ensure that item is returned as a number
            });
          }
          await utility.genericPostAPICall(`${Config.Risks}/`, values);
          notify('Row added successfully', 'success', 3000);
        } catch (error:any) {
          const errorMessage = error.message || 'An unexpected error occurred.';
          notify(errorMessage, 'error', 5000); 
          console.error(errorMessage);
        }
      },
      update: async (key: any, values: any) => {
        try {
          if (values.risk_group_ids) {
            values.risk_group_ids = values.risk_group_ids.map((item: any) => {
              if (typeof item === 'string' && item.startsWith("group-")) {
                return Number(item.replace("group-", ""));
              }
              return Number(item); // Ensure that item is returned as a number
            });
          }
          await utility.genericUpdatePatchAPICall(`${Config.Risks}/`, key, values);
          notify('Row updated successfully', 'success', 3000);
        } catch (error:any) {
          const errorMessage = error.message || 'An unexpected error occurred.';
          notify(errorMessage, 'error', 5000); 
          console.error(errorMessage);
        }
      },
      remove: async (key: any) => {
        try {
          await utility.genericDeleteAPICall(Config.Risks, key);
          notify('Row deleted successfully', 'success', 3000);
        } catch (error:any) {
          const errorMessage = error.message || 'An unexpected error occurred.';
          notify(errorMessage, 'error', 5000); 
          console.error(errorMessage);
        }
      }
    });
  
    return (
      <>
        <div>
          {!loading ? (
            <div className='results-container'>
              <DataTable
                dataSource={dataSource}
                style={{ width: '100%' }}
                filteralbe={true}
                groupable={true}
                columns={columns}
                fileName={'Risk'}
                columnChooser={'RiskColumns'}
                selectionMode={false}
                showHideColumnMenu={true}
                loading={loading}
                allowExportSelectedData={false}
                columnHidingEnabled={true}
                showCommandBtn={false}
                hideSearchPanel={false}
                hideCommandDeleteBtn={false}
                showNewBtn={false}
                showViewDetailBtn={false}
                hideNewBtn={true}
                defaultfiltervalue={filterValue}
                showMoreBtn={true}
                visible={dataLoaded}
              />
            </div>
          ) : (
            <Spinner size='large' className='loader' />
          )}
        </div>
      </>
    );
  };

export default Risk;

