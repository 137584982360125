import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import TreeView, { TreeView as TreeViewInstance, TreeViewTypes } from 'devextreme-react/tree-view';

interface TreeViewComponentProps {
    cellData: any;
    treeViewItems: any[];
    selectedKeys: string[];
    onUpdateSelectedKeys: (selectedKeys: any[]) => void;
}

const TreeViewComponent: React.FC<TreeViewComponentProps> = ({ cellData, treeViewItems, selectedKeys, onUpdateSelectedKeys }) => {
    const treeViewRef = useRef<TreeViewInstance | null>(null);
    const containerRef = useRef<HTMLDivElement>(null); // Ref for the scrollable container
    const [localSelectedKeys, setLocalSelectedKeys] = useState<string[]>(selectedKeys);

    const renderTreeViewItem = (item: { title: any;}) => `${item.title}`;

    // Memoize the tree items to prevent unnecessary renders
    const memoizedTreeViewItems = useMemo(() => treeViewItems, [treeViewItems]);

    // Synchronize parent prop with local state
    useEffect(() => {
        setLocalSelectedKeys(selectedKeys);
    }, [selectedKeys]);

    // Preserve scroll position
    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            const savedScrollPosition = sessionStorage.getItem('treeViewScrollPosition');
            if (savedScrollPosition) {
                container.scrollTop = parseInt(savedScrollPosition, 10);
            }
        }
    }, []);

    const handleScroll = useCallback(() => {
        const container = containerRef.current;
        if (container) {
            sessionStorage.setItem('treeViewScrollPosition', container.scrollTop.toString());
        }
    }, []);

    const syncSelection = useCallback((treeView: any) => {
        const syncSelectedRiskGroups = treeView.getSelectedNodes()
            .map((node: any) => {
                if (typeof node.itemData.id === 'string' && node.itemData.id.includes('-')) {
                    return parseInt(node.itemData.id.split('-')[1], 10); // Extract numeric ID part if '-' exists in string form
                } else if (typeof node.itemData.id === 'string' && !isNaN(parseInt(node.itemData.id, 10))) {
                    return parseInt(node.itemData.id, 10); // Parse as integer if it's a string representation of a number
                } else if (typeof node.itemData.id === 'number') {
                    return node.itemData.id; // Use as is if it's already a number
                }
                return null; // Ignore invalid cases
            })
            .filter((id: any) => id !== null); // Filter out any invalid IDs
        return syncSelectedRiskGroups;
    }, []);

    const handleSelectionChanged = useCallback(
        (e: TreeViewTypes.SelectionChangedEvent) => {
            if (!e.component) return;
            const selectedKeys = syncSelection(e.component); // Use syncSelection to parse IDs
            setLocalSelectedKeys(selectedKeys);
            onUpdateSelectedKeys(selectedKeys);
        },
        [onUpdateSelectedKeys, syncSelection]
    );

    const handleContentReady = useCallback(
        (e: TreeViewTypes.ContentReadyEvent) => {
            if (treeViewRef.current) {
                localSelectedKeys.forEach((key) => {
                    treeViewRef.current?.instance?.selectItem(key);
                });
            }
        },
        [localSelectedKeys]
    );

    // Clean up to avoid memory leaks
    useEffect(() => {
        return () => {
            sessionStorage.removeItem('treeViewScrollPosition');
        };
    }, []);

    return (
        <div
            ref={containerRef}
            style={{ height: 300, overflow: 'auto' }} // Make TreeView scrollable
            onScroll={handleScroll}
        >
            <TreeView
                ref={treeViewRef}
                id="treeview"
                items={memoizedTreeViewItems}
                width="auto"
                height={300}
                selectNodesRecursive={true}
                selectByClick={false}
                showCheckBoxesMode="normal"
                selectionMode="multiple"
                onSelectionChanged={handleSelectionChanged}
                onContentReady={handleContentReady}
                itemRender={renderTreeViewItem}
            />
        </div>
    );
};

export default TreeViewComponent;
