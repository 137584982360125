import React, { useEffect, useState, useCallback, useRef } from 'react'
import utility from '../../classes/utility'
import { Config } from '../../classes/config'
import moment from 'moment'
import Geocode from 'react-geocode'
import { NavLink } from 'react-router-dom'
import DataSource from 'devextreme/data/data_source'
import {
  CircularGauge,
  Scale,
  Label,
  RangeContainer,
  Range,
  Title,
  Font,
  Export
} from 'devextreme-react/circular-gauge'
import {
  Chart,
  CommonSeriesSettings,
  Series,
  ArgumentAxis,
  Legend,
  Point,
  SeriesTemplate
} from 'devextreme-react/chart'
import { createBrowserHistory } from 'history'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import TabPanel from '../../components/Common/TabPanel'
import AlertDialog from '../Common/AlertDialog'
import global from '../../classes/global'
import MapIcon from '@mui/icons-material/Map'
import BarChartIcon from '@mui/icons-material/BarChart'
import { Button, Grid, Paper } from '@mui/material'
import { TagBox } from 'devextreme-react/tag-box'
import MyMapComponent from '../Common/MapComponent/GoogleMapComponent'
import Spinner from '../Common/ReusableComponents/Spinner'
import Autocomplete from 'react-google-autocomplete'
import NumberBoxInput from '../Common/ReusableComponents/NumberBoxInput'
import DateBox from 'devextreme-react/date-box';
import SelectBoxInput from '../Common/ReusableComponents/SelectInputBox'
import { PieChart, Tooltip, Connector } from 'devextreme-react/pie-chart'
import InputLabel from '@mui/material/InputLabel'
import ButtonGroupItem from '../Common/ReusableComponents/ButtonGroup'
import { ReactComponent as AssignedIcon } from '../../images/main/status/AssignedIcon.svg'
import { ReactComponent as OnHoldIcon } from '../../images/main/status/OnHoldIcon.svg'
import { ReactComponent as InProgressIcon } from '../../images/main/status/InProgressIcon.svg'
import { ReactComponent as CompletedIcon } from '../../images/main/status/CompletedIcon.svg'
import { ReactComponent as ArrowRightIcon } from '../../images/main/ArrowRightIcon.svg'
import {Validator, CustomRule} from 'devextreme-react/validator';
import { DateRangeBox } from 'devextreme-react'
import { toBeDisabled } from '@testing-library/jest-dom/matchers'

const options = {
  fields: ['address_components', 'geometry', 'types', 'name', 'formatted_address'],
  strictBounds: true,
  bounds: {
    east: 138.629951,
    north: -34.894027,
    south: -34.950187,
    west: 138.569527
  },
  componentRestrictions: { country: 'au' },
  types: ['point_of_interest', 'street_address', 'street_number', 'locality', 'landmark']
}

export const totalResultsList = [
  { id: 1, text: '10' },
  { id: 2, text: '20' },
  { id: 3, text: '30' },
  { id: 4, text: '50' },
  { id: 5, text: '100' }
]

const Dashboard: React.FC<any> = ({ Department, currentUser, role }) => {
  const history = createBrowserHistory()
  const dateRangeValidatorRef = useRef<any>(null);
  const [state, setState] = useState({
    showingInfoWindow: false,
    // dashboardDateFrom: new Date(new Date().setDate(new Date().getDate() - 30)) || null,
    // dashboardDateTo: new Date() || null,
    dateRange: [new Date(new Date().setDate(new Date().getDate() - 30)), new Date()],
    isInfowWindowOpen: false,
    searchShow: false,
    zoom: 9,
    useStyle: localStorage.getItem('theme') === 'theme-dark',
    suburbNotFound: false,
    selected: 0,
    showWOFilter: false,
    showRightArrow: true,
    loadingAddress: false,
    address: '',
    areasItems: [],
    worktype: [],
    priority: [],
    wostatus: [],
    distanceInMeters: 500,
    markers: [],
    totalResults: +totalResultsList[0].text,
    statusButtonsData: [],
    selectedStatus: ''
  })
  const [priorityItems, setPriorityItems] = useState<DataSource | null>(null)
  const [workTypeItems, setWorkTypeItems] = useState<DataSource | null>(null)
  const [allworkTypeItems, setAllWorkTypeItems] = useState<DataSource | null>(null)
  const [deptOptions, setDeptOptions] = useState<any[]>([])
  const [allWorkorders, setAllWorkorders] = useState<any[]>([])
  const [setupDataPromise, setSetupDataPromise] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingWODashboard, setLoadingWODashboard] = useState<boolean>(false)
  const [alertMessage, setAlertMessage] = useState<string | null>(null)
  const [showAlert, setShowAlert] = useState<boolean>(false)
  const [addressSearch, setAddressSearch] = useState<string | null>(null)
  // const [distanceInMeters, setDistanceInMeters] = useState<number>(0)
  const [reportedDate, setReportedDate] = useState<string | null>(null)
  const [statusDataSource, setStatusDataSource] = useState<DataSource | null>(null)
  const [priorityDataSource, setPriorityDataSource] = useState<DataSource | null>(null)
  const [worktypeDataSource, setWorktypeDataSource] = useState<DataSource | null>(null)
  const [WOMarker, setWOMarker] = useState<any[]>([])
  const [center, setCenter] = useState<{ lng: number; lat: number } | null>(null)
  const [singleMarkers, setSingleMarkers] = useState<any>(null)
  const [department, setDepartment] = useState<any>(Department)
  const [hideAlertButton, setHideAlertButton] = useState<boolean>(false)
  const [woCategoryData, setWoCategoryData] = useState<any[]>([])
  const [selected, setSelected] = useState(0)
  const [loadingFields, setLoadingFields] = useState<boolean>(false)
  const [statusItems, setStatusItems] = useState<any[]>([])
  const [allWorkordersdata, setAllWorkordersdata] = useState<any[]>([])
  const [woCrewData, setWoCrewData] = useState<any[]>([])
  const [woStatusData, setWoStatusData] = useState<any[]>([])
  const [woDateData, setWoDateData] = useState<any[]>([])
  const [woAverageTimeData, setWoAverageTimeData] = useState<any | null>(null)

  useEffect(() => {
    const fetchData = async () => {
      if (!Department) {
        window.open('#/AccessRequest', '_self')
        return
      }

      setSetupDataPromise([])
      await getPrioritiesData()
      await getDepartments()
      await getWorkTypeDataAsync()
      await getStatusDataAsync()
      // Wait for all setup data promises to resolve
      await Promise.all(setupDataPromise)
      await _getWOData(department, state.dateRange[0], state.dateRange[1])
    }
    fetchData()
  }, [])

  const getPrioritiesData = async () => {
    try {
      const data: any[] = await utility.genericGetAPICall(`${Config.PriorityListName}?showAll=true`)
      const items: any = data
        .filter(r => r.type === 'WorkOrder')
        .map(r => ({ id: r.id, text: r.title, 'Select All': r.title }))

      const sortedItems = utility._sortItems(items, 'text', false)
      const dataSource = new DataSource({ store: sortedItems, key: 'id' })

      setPriorityItems(dataSource)
    } catch (err) {
      console.error(err)
    }
  }

  const getDepartments = async () => {
    try {
      const results: any[] = await utility.genericGetAPICall(`${Config.DepartmentList}`)
      const options: any[] = results.map(element => ({
        id: element.id,
        text: element.title
      }))

      setDeptOptions(options)
    } catch (err) {
      console.error(err)
    }
  }

  const _getWODATAforDashboard = async () => {
    let criteriaSelected = false
    const dataToPassToService: any = {}

    // if (!worktype.length) {
    //   setAlertMessage('Please select an activity')
    //   setShowAlert(true)
    //   return
    // }

    if (addressSearch) {
      dataToPassToService.searchAddress = addressSearch
      criteriaSelected = true
    }

    // if (state.distanceInMeters) {
    //   dataToPassToService.searchDistanceInMeters = state.distanceInMeters ? +state.distanceInMeters : 500
    //   criteriaSelected = true
    // }

    if (state.wostatus.length > 0) {
      dataToPassToService.workorder_status_id = state.wostatus
      criteriaSelected = true
    }

    if (state.worktype.length > 0) {
      dataToPassToService.workorder_type_id = state.worktype
      criteriaSelected = true
    }

    if (state.priority.length > 0) {
      dataToPassToService.priority_id = state.priority
      criteriaSelected = true
    }

    if (reportedDate) {
      const reportFrom = new Date(reportedDate)
      reportFrom.setHours(0, 0, 0, 0)
      dataToPassToService.dateReportedFrom = reportFrom.toISOString()

      const reportToDate = new Date(reportedDate)
      reportToDate.setDate(reportToDate.getDate() + 1)
      dataToPassToService.DateReportedTo = reportToDate.toISOString().substring(0, 10) + 'T13:59:59.000Z'

      criteriaSelected = true
    }

    if (!criteriaSelected) {
      setAlertMessage('Please select search criteria')
      setShowAlert(true)
      return
    }

    setLoading(true)
    setLoadingWODashboard(true)

    try {
      const results: any[] = await utility.genericPostAPICall(Config.SearchWorkordersForDashboard, dataToPassToService)

      const markers = results.map(r => {
        const status = r.workorder_status?.title
        const icon = status
        const woLink = `/WorkOrderDetail?WOID=${r.id}&ReqId=${r.work_request_id}`
        //const AsseticwoLink = global.AsseticWOLink + r.erpWorkorderNumber;
        const WRDetailLink = `/WRDetail?ReqId=${r.work_request_id}`

        if (addressSearch) {
          const address = r.workorderLocation || addressSearch
          getLatLongbyAddress(address, 'workorder')
        } else if (r.location_geom) {
          setCenter({ lng: +r.location_geom.x || 0, lat: +r.location_geom.y || 0 })
        }

        return {
          id: r.id,
          title: r.title,
          wonumber: r.id,
          erpWorkorderNumber: r.erpWorkorderNumber,
          link: woLink,
          //   AsseticwoLink: AsseticwoLink,
          icon: icon,
          WRDetailLink: WRDetailLink,
          status: r.status,
          workType: r.workorder_type ? r.workorder_type.title : '',
          crew: r.crew ? r.crew.title : '',
          workReq: r.work_request?.id,
          area: r.area || '',
          reporteddate: r.reported_date ? moment(r.dateStarted).format('DD/MM/YYYY h:mm a') : ' ',
          priority: r.priority?.title || '',
          lat: r.location_geom ? +r.location_geom.y : 0,
          lng: r.location_geom? +r.location_geom.x : 0,
          workRequestId: r.work_request?.id
        }
      })

      const sortedMarkers = utility._sortItems(markers, 'location', false)
      const resultGroup = utility.groupBy(sortedMarkers, 'lat')
      console.log('resultGroup', resultGroup)
      setWOMarker(resultGroup)
      setAllWorkorders(results)

      setTimeout(() => {
        setLoading(false)
        setLoadingWODashboard(false)
      }, 500)
    } catch (err) {
      console.error(err)
      setTimeout(() => {
        setLoading(false)
        setLoadingWODashboard(false)
      }, 500)
    }
  }

  const getWorkTypeDataAsync = async () => {
    utility.genericGetAPICall(`${Config.WTlistname}`).then(
      (data: any) => {
        // console.log(data);
        let worktypeItems = []
        let worktypedataSource: any = []
        for (let r of data) {
          worktypeItems.push({ id: r.id, text: r.title, selected: true, 'Select All': r.title })
        }
        const sortedItems = utility._sortItems(worktypeItems, 'text', false)
        worktypedataSource = new DataSource({
          store: sortedItems,
          key: 'id'
        })

        setWorkTypeItems(worktypedataSource)
        setAllWorkTypeItems(worktypedataSource)
      },
      err => {
        console.log(err)
      }
    )
  }

  const getLatLongbyAddress = async (place: string, type: string) => {
    if (state.distanceInMeters !== null) {
      if (state.distanceInMeters <= 200) {
        setState(prevState => ({ ...prevState, zoom: 19 }))
      } else if (state.distanceInMeters <= 400) {
        setState(prevState => ({ ...prevState, zoom: 17 }))
      } else if (state.distanceInMeters <= 600) {
        setState(prevState => ({ ...prevState, zoom: 15 }))
      } else if (state.distanceInMeters <= 800) {
        setState(prevState => ({ ...prevState, zoom: 13 }))
      } else if (state.distanceInMeters <= 1000) {
        setState(prevState => ({ ...prevState, zoom: 11 }))
      } else {
        setState(prevState => ({ ...prevState, zoom: 9 }))
      }
    }

    let address = place.toLowerCase()
    if (address.includes('street')) {
      address = address.replace('street', 'st')
    }

    try {
      const response = await Geocode.fromAddress(address)
      const { lat, lng } = response.results[0].geometry.location

      if (lat && lng) {
        const marker = { lat, lng }

        if (type === 'noworkorder') {
          setSingleMarkers(marker)
          setCenter(marker)
          // Empty arrays if no work orders
          // setWOMarker([]);
          // setAllWorkorders([]);
        } else {
          setSingleMarkers(marker)
          setCenter(marker)
        }

        setLoading(false)
        setLoadingWODashboard(false)
      }
    } catch (error) {
      console.error(error)
      setLoading(false)
      setLoadingWODashboard(false)
    }
  }

  const getStatusDataAsync = () => {
    setSetupDataPromise(prev => [
      ...prev,
      utility.genericGetAPICall(`${Config.statuslist}?showAll=true`).then((data: any) => {
        const statusItems = data.map((r: any) => ({ id: r.id, text: r.title, 'Select All': r.title }))
        const sortedItems = utility._sortItems(statusItems, 'text', false)
        const newStatusDataSource = new DataSource({ store: sortedItems, key: 'id' })

        setStatusDataSource(newStatusDataSource)
        setStatusItems(sortedItems)
        setState(prevState => ({ ...prevState, wostatus: [] }))
      })
    ])
  }

  const _getWOData = (dept:any, fromDate:any, toDate:any) => {
    let dataToPassToService: any = {}
    if (dept && dept.id !== 'All') {
      dataToPassToService.department_id = dept.id
    }
    if (fromDate && toDate) {
      const dateFrom = new Date(fromDate)
      const dateTo = new Date(toDate)
      dateFrom.setHours(0, 0, 0, 0);
      dateTo.setHours(0, 0, 0, 0);
      if (dateFrom.getTime() > dateTo.getTime()) {
        setAlertMessage('Date To should be greater than Date From');
        setShowAlert(true);
        return;
      } else {
        dataToPassToService.date_started_from = dateFrom.toISOString().split('T')[0];
        dataToPassToService.date_started_to = dateTo.toISOString().split('T')[0];
      }
    }

    // if(fromDate || toDate){
    //   if (fromDate !== null  && toDate === null) {
    //   const dateFrom = new Date(fromDate);
    //   const formattedDateFrom = dateFrom.toISOString().split('T')[0];
    //   dataToPassToService.date_started = formattedDateFrom;
    //   }
    //   if(toDate !== null && fromDate === null){
    //   const dateTo = new Date(toDate);
    //   const formattedDateTo = dateTo.toISOString().split('T')[0];
    //   dataToPassToService.date_completed = formattedDateTo;
    //   }
    // }
    //dataToPassToService.scheduledStartTo = new Date(state.dashboardDateTo).toISOString()
    setState(prevState => ({ ...prevState, loading: true }))

    utility.genericPostAPICall(Config.SearchWorkordersForDashboard, dataToPassToService).then((results: any) => {
      const workOrdersGroupedByStatus = {
        assigned: results
          .filter((st: any) => st.workorder_status?.title === 'Assigned')
          .map((st: any) => ({ id: st.workorder_status.id, title: st.workorder_status.title })),
        onHold: results
          .filter((st: any) => st.workorder_status?.title === 'On Hold')
          .map((st: any) => ({ id: st.workorder_status.id, title: st.workorder_status.title })),
        inProgress: results
          .filter((st: any) => st.workorder_status?.title === 'In Progress')
          .map((st: any) => ({ id: st.workorder_status.id, title: st.workorder_status.title })),
        completed: results
          .filter((st: any) => st.workorder_status?.title === 'Completed')
          .map((st: any) => ({ id: st.workorder_status.id, title: st.workorder_status.title }))
      }
      console.log(workOrdersGroupedByStatus)

      const statusButtonsData = [
        {
          title: 'Assigned',
          count: workOrdersGroupedByStatus['assigned'].length,
          id: workOrdersGroupedByStatus['assigned'][0]?.id || null,
          image: <AssignedIcon />,
          disabled: workOrdersGroupedByStatus['assigned'].length === 0
        },
        {
          title: 'On Hold',
          count: workOrdersGroupedByStatus['onHold'].length,
          id: workOrdersGroupedByStatus['onHold'][0]?.id || null,
          image: <OnHoldIcon />,
          disabled: workOrdersGroupedByStatus['onHold'].length === 0
        },
        {
          title: 'In Progress',
          count: workOrdersGroupedByStatus['inProgress'].length,
          id: workOrdersGroupedByStatus['inProgress'][0]?.id || null,
          image: <InProgressIcon />,
          disabled: workOrdersGroupedByStatus['inProgress'].length === 0
        },
        {
          title: 'Completed',
          count: workOrdersGroupedByStatus['completed'].length,
          id: workOrdersGroupedByStatus['completed'][0]?.id || null,
          image: <CompletedIcon />,
          disabled: workOrdersGroupedByStatus['completed'].length === 0
        }
      ]

      console.log(statusButtonsData)

      // const woCrewData = _getChartData('crew.title', results)
      // const woStatusData = _getChartData('workorder_status.title', results)
      // const woDateData = _getLineChartData(results)
      // const woAverageTimeData = _getGaugeChartData(results)
      // const woCategoryData = _getChartData('workorder_type.title', results)
      // const groupedBySuburbData = utility.groupBy(results, 'Suburb')

      const groupedBySuburbData = utility.groupBy(results, 'Suburb');
      const updatedMarkers = state.markers.map((marker: any) => ({
        ...marker,
        Count: groupedBySuburbData[marker.title] ? groupedBySuburbData[marker.title].length : 0,
        position: marker.geom ? parseGeomToLatLng(marker.geom) : marker.position, // Set marker position based on suburb's geom
      }));

      setWoAverageTimeData(_getGaugeChartData(results));
      setWoDateData(_getLineChartData(results));
      setWoCrewData(_getChartData('crew.code', results));
      setWoStatusData(_getChartData('workorder_status.title', results));
      setWoCategoryData(_getChartData('workorder_type.code', results));
      setAllWorkordersdata(results);

      setState((prevState: any) => ({
        ...prevState,
        loading: false,
        markers: updatedMarkers,
        statusButtonsData
      }))
    })
  }

  const parseGeomToLatLng = (geom: string) => {
    const coords = geom.match(/POINT \(([-.\d]+) ([-.\d]+)\)/);
    if (coords) {
      return { lat: parseFloat(coords[2]), lng: parseFloat(coords[1]) };
    }
    return null;
  };

  const _getLineChartData = useCallback(
    (results: any) => {
      let InitiatedDates: any = []
      if (results && results.length > 0) {
        results.forEach((item: any) => {
          const datefomat = moment(item.date_scheduled_start).format('MM-DD')
          InitiatedDates[datefomat] = (InitiatedDates[datefomat] || 0) + 1
        })
      }
      const getDateArray = (start: any, end: any, groupedDatesWorkOrder: any) => {
        const arr: any = {}
        let dt = start
        while (dt <= end) {
          const datefomat = moment(dt).format('MM-DD')
          arr[datefomat] = groupedDatesWorkOrder[datefomat] || 0
          dt.setDate(dt.getDate() + 1)
        }
        return arr
      }
      const end = new Date(state.dateRange[1])
      const start = new Date(state.dateRange[0])
      const DatesListArray = getDateArray(end, start, InitiatedDates)
      const WODatelabelList = Object.keys(DatesListArray)
      return WODatelabelList.map((item: any) => ({ name: item, value: DatesListArray[item] }))
    },
    [state.dateRange]
  )

  const _getGaugeChartData = (results: any) => {
    const MeterGaugeData = results
      .map((item: any) => {
        if (item.date_scheduled_start && item.date_scheduled_end) {
          const start = moment(item.date_scheduled_start)
          const end = moment(item.date_scheduled_end)
          return end.diff(start)
        }
        return 0
      })
      .filter(Boolean)

    const maxValue = Math.max(...MeterGaugeData)
    const sumof = MeterGaugeData.reduce((memo: number, num: number) => memo + num, 0)
    const average = Math.round(sumof / MeterGaugeData.length)
    return {
      avgTime: parseFloat(moment(average).format('h.mm.ss')),
      maxValueTime: parseFloat(moment(maxValue).format('h:mm:ss'))
    }
  }

  const _getChartData = (chart: any, data: any) => {
    const groupedByData = utility.groupBy(data, (item: any) => {
      // Safely accessing nested properties
      const value = chart.split('.').reduce((acc: any, key: any) => acc?.[key], item)
      return value || 'Unassigned'
    })

    const retunrData = Object.keys(groupedByData).map(key => ({
      name: key,
      value: groupedByData[key].length
    }))

    console.log(retunrData)
    return retunrData
  }

  const clearFilter = () => {
    setState(prevState => ({
      ...prevState,
      dateRange: [new Date(new Date().setDate(new Date().getDate() - 30)), new Date()],
      wostatus: [],
      priority: [],
      worktype: [],
      addressSearch: '',
      reportedDate: '',
      suburbNotFound: false,
      WOMarker: [],
      totalResults: +totalResultsList[0].text
    }))
    setTimeout(_getWOData, 1000)
  }

  const _fieldChanged = (event: any, fieldName: string, options?: any) => {
    if (options) {
      const alloption = `all${options}`
      setState((prevState: any) => ({ ...prevState, [options]: prevState[alloption] }))
    }
    setState(prevState => ({ ...prevState, [fieldName]: event.target.value }))
    if (fieldName === 'addressSearch') {
      setState(prevState => ({ ...prevState, callAfterInit: true, searchShow: true }))
    }
  }

  const handleValueChange = (e: any, fieldName: string, validatorRef?: any) => {
    setState(prevState => ({ ...prevState, [fieldName]: e.value }))

    if (fieldName === 'dateRange' && e.value) {
      const [startDate, endDate] = e.value;

      if ((startDate && !endDate) || (!startDate && endDate)) {
          if (validatorRef && validatorRef.current) {
              validatorRef.current.instance.validate();
          }
      } else if (!startDate && !endDate) {
          setTimeout(() => _getWOData(department, null, null), 3000);
      }else if (startDate && endDate) {
          setTimeout(() => _getWOData(department, startDate, endDate), 3000);
      }
  }
};

  const handleSelect = (event: any, newValue: any) => {
    setSelected(newValue)
  }

  const handleDropdownChange = (e: any, fieldName: string) => {
    setDepartment(e.selectedItem)
    // setTimeout(_getWOData, 1000)
    setTimeout(()=> _getWOData(e.selectedItem, state.dateRange[0], state.dateRange[1]),1000);
  }

  const _multiFieldChanged = (event: any, fieldName: string) => {
    setState(prevState => ({ ...prevState, [fieldName]: event.value }))
  }

  // const handleSelectTabStrip = (e: any) => {
  //   setState(prevState => ({ ...prevState, selectedTab: e.selected }))
  // }

  // const onSelectDept = (dept: any) => {
  //   setState(prevState => ({ ...prevState, department: dept }))
  //   setTimeout(_getWOData, 1000)
  // }

  // const onHorizontalChange = (event: any) => {
  //   setState(prevState => ({
  //     ...prevState,
  //     horizontalPanes: event.newState
  //   }))
  // }

  // const stopCommsAfterInit = () => {
  //   setState(prevState => ({ ...prevState, callAfterInit: false }))
  // }

  // const getSelectedItem = (item: any) => {
  //   const tempAddress = `${item.street} ${item.suburb} ${item.state}`
  //   setState(prevState => ({
  //     ...prevState,
  //     addressSearch: tempAddress,
  //     searchShow: false
  //   }))
  // }

  // const setDistanceInMetersValue = (e: any) => {
  //   setState(prevState => ({ ...prevState, distanceInMeters: e.value }))
  // }

  const pointClickHandler = (e: any) => {
    toggleVisibility(e.target)
  }

  const legendClickHandler = (e: any) => {
    const arg = e.target
    const item = e.component.getAllSeries()[0].getPointsByArg(arg)[0]
    toggleVisibility(item)
  }

  const toggleVisibility = (item: any) => {
    item.isVisible() ? item.hide() : item.show()
  }

  const CenterTemplate = (pieChart: any) => (
    <svg>
      <circle cx='100' cy='100' r={pieChart.getInnerRadius() - 6} fill='#eee'></circle>
      <text textAnchor='middle' x='100' y='100' style={{ fontSize: 24 }}>{`${woCategoryData.length}%`}</text>
    </svg>
  )

  //   useEffect(() => {
  //     _getWOData()
  //   }, [])

  const alert = () => {
    return (
      <AlertDialog
        showDialog={showAlert}
        message={alertMessage}
        title={Config.productTitle}
        onClose={() => {
          setAlertMessage('')
          setShowAlert(false)
        }}
        hideAlertButton={hideAlertButton}
      />
    )
  }

  const navigateToSearchWODetails = (button: any) => (
    button.disabled ? (
      <Button className='button-card' disabled>
        <div className='button-content'>
          {button.image}
          <div className='button-text'>
            <h3 className='button-heading'>{button.title}</h3>
            <p className='button-subtext'>{button.count}</p>
          </div>
          <ArrowRightIcon className='button-arrow' />
        </div>
      </Button>
    ) : (
      <NavLink
        to={{
          pathname: '/Searchworkorder',
          state: {
            searchFromDashboardProps: {
              woStatus: { id: button.id, text: button.title }
            }
          }
        }}
        style={{ textDecoration: 'none' }}
      >
        <Button className='button-card'>
          <div className='button-content'>
            {button.image}
            <div className='button-text'>
              <h3 className='button-heading'>{button.title}</h3>
              <p className='button-subtext'>{button.count}</p>
            </div>
            <ArrowRightIcon className='button-arrow' />
          </div>
        </Button>
      </NavLink>
    )
  );
  

//   const navigateToSearchWODetails = (statusId: any, statusTitle: string) => {
//     history.push({
//         pathname: '/#/Searchworkorder',
//         state: { 
//           searchFromDashboardProps: {
//             woStatus: { id: statusId, text: statusTitle }
//           }
//         }
//       } as any);
//     //window.location.href = `/#/Searchworkorder?woStatusId=${statusId}&woStatusTitle=${encodeURIComponent(statusTitle)}`;
//     // const searchFromDashboardProps: any = {
//     //   pathname: '/#/Searchworkorder',
//     //   searchFromDashboardProps: {
//     //     woStatus: { id: statusId, text: statusTitle }
//     //   }
//     // }
//     // return <NavLink to={searchFromDashboardProps} />
//   }

  return (
    <div className={'dashboardHome tabsDiv'}>
      <div className='Tabs-container'>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Tabs value={selected} onChange={handleSelect} className='tabs-section'>
            {role !== global.worxOnline_Reader && (
                <Tab
                  value={0}
                  label={
                    <span className='tabHeading'>
                      {/* <MapIcon className={'subMenuLinkIcon'} /> */}
                      My Team Dashboard
                    </span>
                  }
                />
              )}
              <Tab
                value={1}
                label={
                  <span className='tabHeading'>
                    {/* <BarChartIcon className={'subMenuLinkIcon'} /> */}
                    Work Order Dashboard
                  </span>
                }
              />
            </Tabs>
          </Grid>
        </Grid>
      </div>
      <TabPanel value={selected} index={1}>
        <div className={'searchFieldDiv'}>
          <div className='search-fields'>
            {!loadingFields ? (
              <>
                <div className='ui-container'>
                  <div className='filterOptionDiv'>
                    <h4 className='pl-30'>Search Criteria</h4>
                  </div>
                  <div>
                    {/* <Paper elevation={6} className='mt-30 homeChart displayFlex'> */}
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6} md={6} lg={4}>
                        <InputLabel shrink className='label bold'>
                          Distance In Meters
                        </InputLabel>
                        <NumberBoxInput
                          stylingMode='outlined'
                          min={1}
                          value={state.distanceInMeters}
                          onValueChanged={(e: any) => handleValueChange(e, 'distanceInMeters')}
                        />
                      </Grid>
                      {state.worktype && (
                        <Grid item xs={12} sm={6} md={6} lg={4}>
                          <InputLabel shrink className='label bold'>
                            Activity
                          </InputLabel>
                          <TagBox
                            dataSource={workTypeItems}
                            valueExpr='id'
                            value={state.worktype}
                            searchEnabled={true}
                            displayExpr='text'
                            onValueChanged={(e: any) => _multiFieldChanged(e, 'worktype')}
                          />
                        </Grid>
                      )}
                      {state.priority && (
                        <Grid item xs={12} sm={6} md={6} lg={4}>
                          <InputLabel shrink className='label bold'>
                            Priority
                          </InputLabel>
                          <TagBox
                            dataSource={priorityItems}
                            valueExpr='id'
                            value={state.priority}
                            searchEnabled={true}
                            displayExpr='text'
                            onValueChanged={(e: any) => _multiFieldChanged(e, 'priority')}
                          />
                        </Grid>
                      )}
                      {state.wostatus && (
                        <Grid item xs={12} sm={6} md={6} lg={4}>
                          <InputLabel shrink className='label bold'>
                            Status
                          </InputLabel>
                          <TagBox
                            dataSource={statusItems}
                            valueExpr='id'
                            value={state.wostatus}
                            searchEnabled={true}
                            displayExpr='text'
                            onValueChanged={e => _multiFieldChanged(e, 'wostatus')}
                          />
                        </Grid>
                      )}
                      <Grid item xs={12} sm={6} md={6} lg={4}>
                        <InputLabel shrink className='label bold'>
                          Reported Date
                        </InputLabel>
                        <DateBox
                          stylingMode='outlined'
                          type='date'
                          displayFormat={'dd/MM/yy'}
                          showClearButton={true}
                          value={reportedDate ? new Date(reportedDate) : undefined}
                          onValueChanged={(e: any) => handleValueChange(e, 'reportedDate')}
                        />
                      </Grid>

                      {/* <Autocomplete
                    apiKey={global.googleAPIKey}
                    style={{ width: '90%' }}
                    onPlaceSelected={(place: any) => handleValueChange(place, 'place')}
                /> */}
                    </Grid>
                  </div>
                  <div className='actions-container'>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6}>
                        <div className='buttongroups-container displayFlex'>
                          <span className='mr-10 bold'>Result count: {allWorkorders.length}</span>
                          <span className='show-results bold'>show: </span>
                          <ButtonGroupItem
                            items={totalResultsList}
                            keyExpr='text'
                            stylingMode='text'
                            selectedItemKeys={[state.totalResults.toString()]}
                            onItemClick={(e: any) => handleValueChange(e, 'totalResults')}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <div className='action-buttons'>
                          <Button onClick={clearFilter} variant='outlined' className={'clearButton'}>
                            Clear
                          </Button>
                          <Button
                            onClick={_getWODATAforDashboard}
                            variant='outlined'
                            color='primary'
                            className='primary-button'
                          >
                            Search
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </div>
                <div className='map-container'>
                  <Grid item xs={12} sm={12} md={8}>
                    {!loadingWODashboard ? (
                      <MyMapComponent
                        markers={WOMarker}
                        useStyle={state.useStyle}
                        center={center ?? undefined}
                        showWODetail={true}
                        showMarker={true}
                        zoom={state.zoom}
                        height={'450px'}
                        singleMarkers={singleMarkers}
                        showMap={true}
                      />
                    ) : (
                      <>
                        <br />
                        <Spinner size='large' label='Loading...' />
                      </>
                    )}
                  </Grid>
                </div>
                <br />
              </>
            ) : (
              <>
                <br />
                <Spinner size='large' label='Loading...' />
              </>
            )}
          </div>
          <br />
        </div>
      </TabPanel>
      {role !== global.worxOnline_Reader && (
        <TabPanel value={selected} index={0}>
          <div className={'schedulerDiv select-contrainer'}>
            <div className='mt-20'>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} sx={{ marginTop: `-20px` }}>
                  <Grid container spacing={3}>
                    <Grid item sm={12} md={4} lg={3}>
                      <h2 className='mt-10'>Work Orders </h2>
                    </Grid>

                    <Grid item sm={12} md={8} lg={9} className='dashboard-filter'>
                      <div className={'m-10'}>
                        <Grid container spacing={3}>
                          <Grid item sm={6} lg={4} justifyContent='flex-end'>
                            <SelectBoxInput
                              stylingMode='outlined'
                              displayExpr='text'
                              valueExpr='id'
                              showClearButton={false}
                              items={deptOptions}
                              selectedItem={department}
                              disabled={
                                role === global.worxOnline_Contractor || role === global.worxOnline_ContractorAdmin
                                  ? true
                                  : false
                              }
                              label='Department'
                              onSelectionChanged={(e: any) => handleDropdownChange(e, 'department')}
                              className='schedulerSelect'
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={8}>
                            <DateRangeBox
                              stylingMode="outlined"
                             defaultValue={state.dateRange}
                             displayFormat="dd/MM/yyyy"
                             showClearButton={true}
                             onValueChanged={(e: any) =>
                               handleValueChange(e, "dateRange", dateRangeValidatorRef)
                             }
                             startDateLabel={"Date Started From"}
                              endDateLabel={"Date Started To"}
                              >
                                <Validator ref={dateRangeValidatorRef}>
                                <CustomRule
                                  message="Both start and end dates must be selected."
                                  validationCallback={() => {
                                    const [startDate, endDate] = state.dateRange || [];
                                    return !(startDate && !endDate || !startDate && endDate);
                                  }}
                                />
                              </Validator>
                            </DateRangeBox>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </div>
          <div className='status-buttons-container'>
            <Grid container spacing={3}>
              {state.statusButtonsData.map((button: any, index: any) => (
                <Grid item xs={12} sm={6} md={3} key={index}>
                  {/* <Button className='button-card' onClick={() => navigateToSearchWODetails(button.id, button.title)}>
                    <div className='button-content'>
                      {button.image}
                      <div className='button-text'>
                        <h3 className='button-heading'>{button.title}</h3>
                        <p className='button-subtext'>{button.count}</p>
                      </div>
                      <ArrowRightIcon className='button-arrow' />
                    </div>
                  </Button> */}
                  {navigateToSearchWODetails(button)}
                </Grid>
              ))}
            </Grid>
          </div>
          <div>
            {!loading ? (
              <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                  <div className='chart'>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={12} md={4}>
                        <h2 className='chart-heading'>By Crew</h2>
                        <Paper elevation={6} className='chartPaper chart-container'>
                          <div className='m-10'>
                            {woCrewData && woCrewData.length > 0 ? (
                              <Chart id='chart' dataSource={woCrewData}>
                                <CommonSeriesSettings
                                  argumentField='name'
                                  valueField='value'
                                  type='bar'
                                  ignoreEmptyPoints={true}
                                />
                                <SeriesTemplate nameField='name' />
                                {/* <Title text='By crew' subtitle='BY CREW' horizontalAlignment={'left'}/> */}
                                <Legend verticalAlignment='bottom' horizontalAlignment='center' />
                                <Export enabled={true} />
                              </Chart>
                            ) : (
                              <p className='alignCenter'>No data found</p>
                            )}
                            <br />
                          </div>
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={12} md={8}>
                        <Grid item xs={12} sm={12} md={12}>
                          <h2 className='chart-heading'>Over the period</h2>
                          <Paper elevation={6} className='chartPaper chart-container'>
                            <div className='m-10'>
                              {woDateData && woDateData.length > 0 ? (
                                <Chart id='chart' dataSource={woDateData}>
                                  <Series argumentField='name' valueField='value'>
                                    <Point hoverMode='allArgumentPoints' />
                                  </Series>
                                  <ArgumentAxis>
                                    <Label
                                    // wordWrap="none"
                                    // overlappingBehavior={'stagger'}
                                    />
                                  </ArgumentAxis>
                                  {/* <Title text='Work Orders' subtitle='OVER THE PERIOD' /> */}
                                  <Legend verticalAlignment='bottom' horizontalAlignment='center' />
                                  <Export enabled={true} />
                                </Chart>
                              ) : (
                                <p className='alignCenter'>No data found</p>
                              )}
                              <br />
                            </div>
                          </Paper>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={12} md={8}>
                        <h2 className='chart-heading'>By Suburb</h2>
                        <Paper elevation={6} className='chartPaper' sx={{height: '85%'}}>
                            <div>
                                {/* To do */}
                                {/* implement <myMapComponent /> similar to the one in the other tab with the markers */}
                                <div className='map-container dashboard'>
                                    <MyMapComponent
                                        markers={state.markers}
                                        useStyle={state.useStyle}
                                        center={center ?? undefined}
                                        // showWODetail={true}
                                        showMarker={true}
                                        zoom={state.zoom}
                                        height={'410px'}
                                        singleMarkers={singleMarkers}
                                        showMap={true}
                                    />
                            </div>  
                                {/* <p className='alignCenter no-content'>No data found</p> */}
                            </div>
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={12} md={4}>
                      <h2 className='chart-heading'>By Average Time</h2>
                        <Paper elevation={6} className='chartPaper chart-container'>
                          <div className='m-10'>
                            {woAverageTimeData ? (
                              <CircularGauge id='gauge' value={woAverageTimeData.avgTime} height='377px'>
                                <Scale startValue={0} endValue={woAverageTimeData.maxValueTime} tickInterval={1}>
                                  <Label useRangeColors={true} />
                                </Scale>
                                <Tooltip enabled={true}>
                                  <Font size={40} color='#DCD0FF' />
                                </Tooltip>
                                <RangeContainer>
                                  <Range startValue={0} endValue={woAverageTimeData.avgTime} />
                                  <Range
                                    startValue={woAverageTimeData.avgTime}
                                    endValue={woAverageTimeData.maxValueTime}
                                  />
                                </RangeContainer>
                                {/* <Geometry
                                                                    startAngle={180}
                                                                    endAngle={360}
                                                                >
                                                                </Geometry> */}
                                {/* <Title horizontalAlignment={'left'} text='Work Orders' subtitle='BY AVERAGE TIME' /> */}
                                <Export enabled={true} />
                              </CircularGauge>
                            ) : (
                              <p className='alignCenter'>No data found</p>
                            )}
                            <br />
                            <br />
                          </div>
                        </Paper>
                      </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={4}>
                        <h2 className='chart-heading'>By Status</h2>
                        <Paper elevation={6} className='chartPaper chart-container'>
                          <div className='m-10'>
                            {woStatusData && woStatusData.length > 0 ? (
                              <PieChart
                                id='pie'
                                type='doughnut'
                                dataSource={woStatusData}
                                palette='Bright'
                                onPointClick={pointClickHandler}
                                onLegendClick={legendClickHandler}
                                innerRadius={0.65}
                                centerRender={(e: any) => CenterTemplate(e)}
                              >
                                <Series argumentField='name' valueField='value'>
                                  <Label visible={true}>
                                    <Connector visible={true} width={1} />
                                  </Label>
                                </Series>
                                {/* <Title horizontalAlignment={'left'} text='Work Orders' subtitle='BY STATUS' /> */}
                                <Export enabled={true} />
                              </PieChart>
                            ) : (
                              <p className='alignCenter'>No data found</p>
                            )}
                            <br />
                          </div>
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={12} md={8}>
                      <h2 className='chart-heading'>By Activity</h2>
                        <Paper elevation={6} className='chartPaper chart-container'>
                          <div className='m-10'>
                            {allWorkordersdata && allWorkordersdata.length > 0 ? (
                              <Chart id='chart' dataSource={woCategoryData} rotated={true}>
                                <CommonSeriesSettings
                                  argumentField='name'
                                  valueField='value'
                                  type='bar'
                                  ignoreEmptyPoints={true}
                                />
                                <SeriesTemplate nameField='name' />
                                {/* <Title text='Work Orders' subtitle='BY ACTIVITY' /> */}
                                <Legend verticalAlignment='bottom' horizontalAlignment='center' />
                                <Export enabled={true} />
                              </Chart>
                            ) : (
                              <p className='alignCenter'>No data found</p>
                            )}
                            <br />
                          </div>
                        </Paper>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            ) : (
              <div>
                <br />
                <Spinner size='large' label='Loading..' />
              </div>
            )}
          </div>
        </TabPanel>
      )}
    </div>
  )
}

export default Dashboard
