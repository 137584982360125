import React, { useState, useEffect } from 'react';
import utility from '../../../classes/utility';
import { Config } from '../../../classes/config';
import DataTable from '../../Common/CustomASDataGrid';
import Spinner from '../../Common/ReusableComponents/Spinner';
import global from '../../../classes/global';
import CustomStore from "devextreme/data/custom_store";
import { LoadOptions } from "devextreme/data";
import notify from 'devextreme/ui/notify';

interface LoadResult {
    data: any[];
    totalCount: number;
  }

interface DefectCodeProps {
    currentUser: any;
    role: string;
    hideNewBtn: boolean;
}


const DefectCode = (props: DefectCodeProps) => {

    const [items, setItems] = useState<any>([]);
    const [assetClassItems, setAssetClassItems] = useState<any>([]);
    const [assetRegisterItems, setAssetRegisterItems] = useState<any>([]);
    const [assetTypeItems, setAssetTypeItems] = useState<any>([]);
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const setRegisterValue = (rowData: any, value: number) => {
      rowData.asset_register_id = value || null
      const assetRegister = assetRegisterItems.find((ar:any) => ar.asset_register_id === value)
      if (assetRegister) {
        rowData.asset_register_title = assetRegister.asset_register_title
      }
    }

    const setAssetClassValue = (rowData: any, value: number) => {
      // rowData.asset_class = value || null
      const assetClass = assetClassItems.find((ac:any) => ac.asset_class === value)
      if (assetClass) {
        rowData.asset_class = assetClass.asset_class
      }
    }

    const setAssetTypeValue = (rowData: any, value: number) => {
      // rowData.asset_type_id = value || null
      const assetType = assetTypeItems.find((at:any) => at.asset_classification_type === value)
      if (assetType) {
        rowData.asset_classification_type = assetType.asset_classification_type
      }
    }
   
    const columns = [
        {
            field: 'id',
            title: 'ID',
            width: 80,
            type: 'string',
            allowHiding: false,
            visibleColumn: false,
            formItem: {
                visible: false,
            }
        },
        {
            field: 'code',
            title: 'Code',
            // width: 280,
            type: 'string',
            allowHiding: false,
            isRequired: true,
        },
        {
            field: 'title',
            title: 'Title',
            // width: 160,
            type: 'string',
            allowHiding: false,
            isRequired: true,
        },
        {
            field: 'asset_register_id',
            title: 'Asset Register',
            // width: 160,
            type: 'string',
            allowHiding: true,
            isRequired: true,
            setCellValue: setRegisterValue,
            Lookup: {
              dataSource: assetRegisterItems,
              valueExpr: 'asset_register_id',
              displayExpr: 'asset_register_title'
            }
          },
        {
            field: 'asset_class',
            title: 'Asset Class',
            // width: 160,
            type: 'string',
            allowHiding: true,
            setCellValue: setAssetClassValue,
            Lookup: {
              dataSource: assetClassItems,
              valueExpr: 'asset_class',
              displayExpr: 'asset_class'
            }
        },
        {
            field: 'asset_classification_type',
            title: 'Asset Classification Type',
            // width: 160,
            type: 'string',
            allowHiding: true,
            setCellValue: setAssetTypeValue,
            Lookup: {
              dataSource: assetTypeItems,
              valueExpr: 'asset_classification_type',
              displayExpr: 'asset_classification_type'
            }
        },
        {
            field: 'created',
            title: 'Created',
            width: 160,
            type: 'datetime',
            visibleColumn: false,
            allowHiding: true,
            sortOrder: 'desc',
            formItem: {
                visible: false,
            }
        },
        {
            field: 'created_by',
            title: 'Created By',
            width: 160,
            type: 'string',
            visibleColumn: false,
            allowHiding: true,
            formItem: {
                visible: false,
            }
        },
        {
          field: 'updated',
          title: 'Updated',
          width: 160,
          type: 'datetime',
          visibleColumn: false,
          allowHiding: true,
          formItem: {
            visible: false,
          }
        },
        {
          field: 'updated_by',
          title: 'Updated By',
          width: 160,
          type: 'string',
          visibleColumn: false,
          allowHiding: true,
          formItem: {
            visible: false,
          }
        }
    ];

    useEffect(() => {
      fetchItemsData()
    }, [])

    const fetchItemsData = async() => {
      setLoading(true)
      await getAssetClassData();
      await getAssetRegisterData();
      await getAssetTypeData();
      await getDefectData();
    }

    const getAssetClassData = async () => {
      try{
      const assetData:any = await utility.genericGetAPICall(Config.assetClass) 
        const assetclasses = assetData.filter((r: any) => r.is_active === true && r.class_type === 'C')
          .map((r: any) => ({
            asset_class_id: r.id,
            asset_class: r.title,
            'Select All': r.title,
            class_type: r.class_type,
            level: r.level
          }))
        const sortedAssetclasses = utility._sortItems(assetclasses, 'text', false)
        setAssetClassItems(sortedAssetclasses)
      } catch (error) {
        console.error(error)
      }
    }

    const getAssetRegisterData = async () => {
      try {
        const assetData:any = await utility.genericGetAPICall(Config.assetRegister) 
        let assetRegisters = []
        assetRegisters = assetData
          .map((r: any) => ({
            asset_register_id: r.id,
            asset_register_title: r.title,
            'Select All': r.title
          }))
        const sortedAssetRegisters = utility._sortItems(assetRegisters, 'text', false)
        setAssetRegisterItems(sortedAssetRegisters)
      } catch (error) {
        console.error(error)
      }
    }

    const getAssetTypeData = async () => {
      try {
        const assetData:any = await utility.genericGetAPICall(Config.assetType) 
        let assetTypes = []
        assetTypes = assetData
          .filter((r: any) => r.is_active === true && r.type === 'T')
          .map((r: any) => ({
            asset_type_id: r.id,
            asset_classification_type: r.title,
            'Select All': r.title,
            level: r.level
          }))
        const sortedAssetTypes = utility._sortItems(assetTypes, 'text', false)
        setAssetTypeItems(sortedAssetTypes)
      } catch (error) {
        console.error(error)
      }
    }

    const getDefectData = async () => {
      try {
        const itemsResponse = await utility.genericGetAPICall(Config.DefectCodeList);
        let items: any[] = []
  
        for (let item of itemsResponse) {
          items.push({
            ...item,
            asset_register_id: item.asset_register ? item.asset_register.id : null,
            asset_register_title: item.asset_register ? item.asset_register.title : '',
            // asset_class_title: item.asset_class ? item.asset_class : '',
            // asset_type_title: item.asset_classification_type ? item.asset_classification_type : '',
          })
        }
        items = utility._sortItems(items, 'id', true)
        setItems(items)
        setLoading(false)
      } catch (error) {
        console.error(error)
        setLoading(false)
      }
    }

    const dataSource = new CustomStore({
        load: async (loadOptions: LoadOptions): Promise<LoadResult> => {
            return {
                data: items,
                totalCount: items.length
            };
        },
        key: 'id',
        onLoaded: () => {
          console.log('Data loaded');
          setLoading(false);
          setDataLoaded(true);
        },
        insert: async (values: any) => {
          try {
            const { asset_type_id, asset_class_id, ...filteredValues } = values;
            await utility.genericPostAPICall(Config.DefectCodeList, filteredValues);
            notify('Row added successfully', 'success', 3000)
            await getDefectData();
          } catch (error:any) {
            const errorMessage = error.message || 'An unexpected error occurred.';
            notify(errorMessage, 'error', 5000); 
            console.error(errorMessage);
          }
        },
        update: async (key: any, values: any) => {
          try {
            const existingItem = items.find((item:any) => item.id === key) || {};
            const { asset_type_id, asset_class_id, ...filteredValues } = values;
            const updatedItem = { ...existingItem, ...filteredValues };
            await utility.genericUpdatePatchAPICall(Config.DefectCodeList, key, updatedItem);
            notify('Row updated successfully', 'success', 3000)
            await getDefectData();
          } catch (error:any) {
            const errorMessage = error.message || 'An unexpected error occurred.';
            notify(errorMessage, 'error', 5000); 
            console.error(errorMessage);
          }
        },
        remove: async (key: any) => {
          try {
            await utility.genericDeleteAPICall(Config.DefectCodeList, key);
            notify('Row deleted successfully', 'success', 3000);
            await getDefectData();
          } catch (error:any) {
            const errorMessage = error.message || 'An unexpected error occurred.';
            notify(errorMessage, 'error', 5000); 
            console.error(errorMessage);
          }
        }
      });
    
      return (
        <>
          <div>
            {/* {!loading ? ( */}
              <div className='results-container'>
                <DataTable
                  dataSource={dataSource}
                  style={{ width: '100%' }}
                  filteralbe={true}
                  groupable={true}
                  columns={columns}
                  fileName={'DefectCodes'}
                  columnChooser={'defectCodeColumns'}
                  selectionMode={false}
                  showHideColumnMenu={true}
                  loading={loading}
                  allowExportSelectedData={false}
                  columnHidingEnabled={true}
                  showCommandBtn={false}
                  hideSearchPanel={false}
                  hideCommandDeleteBtn={false}
                  showNewBtn={false}
                  showViewDetailBtn={false}
                  hideNewBtn={true}
                  //defaultfiltervalue={filterValue}
                  showMoreBtn={true}
                  visible={dataLoaded}
                />
              </div>
            {/* ) : (
              <Spinner size='large' className='loader' />
            )} */}
          </div>
        </>
      );
    };
   
export default DefectCode;