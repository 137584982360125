import React, { useState, useEffect } from 'react'
import utility from '../../../classes/utility'
import { Config } from '../../../classes/config'
import DataTable from '../../Common/CustomASDataGrid'
import Spinner from '../../Common/ReusableComponents/Spinner'
import global from '../../../classes/global'
import CustomStore from 'devextreme/data/custom_store'
import { LoadOptions } from 'devextreme/data'
import TextAreaInput from '../../Common/ReusableComponents/TextArea';
import notify from 'devextreme/ui/notify';

interface LoadResult {
  data: any[];
  totalCount: number;
}

const RiskForm = (props: any) => {
  // if (props.role !== global.worxOnline_Admin) {
  //   window.open('#/AccessDenied', '_self')
  // }

  const [dataLoaded, setDataLoaded] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [items, setItems] = useState<any[]>([]);

  const columns = [
    {
      field: 'id',
      title: 'ID',
      width: 80,
      type: 'string',
      allowHiding: false,
      visibleColumn: false,
      formItem: {
        visible: false
      }
    },
    {
      field: 'title',
      title: 'Title',
      width: 250,
      type: 'string',
      allowHiding: false,
      isRequired: true
    },
    {
      field: 'model',
      title: 'Model',
      width: 250,
      type: 'string',
      allowHiding: false,
      visibleColumn: false,
      formItem: {
        visible: true,
        editorType: 'dxTextArea',
        editorOptions: {
          height: 100,
          render: (data: any) => (
            <div>
              <TextAreaInput
                stylingMode='outlined'
                value={data.model}
                onValueChanged={(e: any) => {
                  data.model = e.value;
                }}
              />
            </div>
          )
        }
      }
    },
    {
      field: 'form_name',
      title: 'Form Name',
      width: 250,
      type: 'string',
      allowHiding: false,
      isRequired: true
    },
    {
      field: 'is_adhoc',
      title: 'Adhoc',
      width: 160,
      type: 'boolean',
      allowHiding: false,
      calculateCellValue: (data: any) => data.is_adhoc ?? false, // Default to true
      setCellValue: (newData: any, value: any) => {
        newData.is_adhoc = value
      }
    },
    {
      field: 'is_active',
      title: 'Active',
      width: 160,
      type: 'boolean',
      allowHiding: false,
      calculateCellValue: (data: any) => data.is_active ?? true, // Default to true
      setCellValue: (newData: any, value: any) => {
        newData.is_active = value
      }
    },
    {
      field: 'created',
      title: 'Created',
      // width: 160,
      type: 'datetime',
      visibleColumn: true,
      // allowHiding: true,
      formItem: {
        visible: false,
      }
    },
    {
      field: 'created_by',
      title: 'Created By',
      // width: 160,
      type: 'string',
      visibleColumn: true,
      // allowHiding: true,
      formItem: {
        visible: false,
      }
    },
    {
      field: 'updated',
      title: 'Updated',
      // width: 160,
      type: 'datetime',
      visibleColumn: true,
      allowHiding: true,
      formItem: {
        visible: false,
      }
    },
    {
      field: 'updated_by',
      title: 'Updated By',
      // width: 160,
      type: 'string',
      visibleColumn: true,
      allowHiding: true,
      formItem: {
        visible: false,
      }
    }
  ]

  useEffect(() => {
    itemsData();
  }, []);

  const itemsData = async () => {
    setLoading(true);
    try {
      const itemResponse = await utility.genericGetAPICall(Config.RiskForms);
      const itemData = itemResponse.map((r: any) => ({
        ...r,
        model: JSON.stringify(r.model, null, 2),
        is_active: r.is_active ? true : false
      }));
      setItems(itemData);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const dataSource = new CustomStore({
    load: async (loadOptions: LoadOptions): Promise<LoadResult> => {
      return {
        data: items,
        totalCount: items.length
      };
    },
    key: 'id',
    onLoaded: () => {
      console.log('Data loaded');
      setLoading(false);
      setDataLoaded(true);
    },
    insert: async (values: any) => {
      try {
        if (values.model && typeof values.model === 'string') {
          values.model = JSON.parse(values.model)
        }
        await utility.genericPostAPICall(Config.RiskForms, values);
        notify('Row added successfully', 'success', 3000);
        await itemsData();
      } catch (error:any) {
        const errorMessage = error.message || 'An unexpected error occurred.';
        notify(errorMessage, 'error', 5000); 
        console.error(errorMessage);
      }
    },
    update: async (key: any, values: any) => {
      try {
        if (values.model && typeof values.model === 'string') {
          values.model = JSON.parse(values.model)
        }
        await utility.genericUpdatePatchAPICall(Config.RiskForms, key, values);
        notify('Row updated successfully', 'success', 3000);
        await itemsData();
      } catch (error:any) {
        const errorMessage = error.message || 'An unexpected error occurred.';
        notify(errorMessage, 'error', 5000); 
        console.error(errorMessage);
      }
    },
    remove: async (key: any) => {
      try {
        await utility.genericDeleteAPICall(Config.RiskForms, key);
        notify('Row deleted successfully', 'success', 3000);
        await itemsData();
      } catch (error:any) {
        const errorMessage = error.message || 'An unexpected error occurred.';
        notify(errorMessage, 'error', 5000); 
        console.error(errorMessage);
      }
    }
  });

  return (
    <>
      <div>
        {!loading ? (
          <div className='results-container'>
            <DataTable
              dataSource={dataSource}
              style={{ width: '100%' }}
              filteralbe={true}
              groupable={true}
              columns={columns}
              fileName={'Risk Form'}
              columnChooser={'riskFormColumns'}
              selectionMode={false}
              showHideColumnMenu={true}
              loading={loading}
              allowExportSelectedData={false}
              columnHidingEnabled={true}
              showCommandBtn={false}
              hideSearchPanel={false}
              hideCommandDeleteBtn={false}
              showNewBtn={false}
              showViewDetailBtn={false}
              hideNewBtn={true}
              //defaultfiltervalue={filterValue}
              showMoreBtn={true}
              visible={dataLoaded}
            />
          </div>
        ) : (
          <Spinner size='large' className='loader' />
        )}
      </div>
    </>
  );
};

export default RiskForm
