import React, { useState, useEffect } from 'react';
import utility from '../../../classes/utilityOld';
import { Config } from '../../../classes/config';
import DataTable from '../../Common/CustomASDataGrid'
import AlertDialog from '../../Common/AlertDialog';
import DeleteDialog from '../../Common/DeleteAlert';
import Spinner from '../../Common/ReusableComponents/Spinner';

interface AssetQuestionProps {
    currentUser: string;
}

const AssetQuestion: React.FC<AssetQuestionProps>= (props) => {
  const currentUser: any = props.currentUser;
  const columns: any = [
    {
        field: 'id',
        title: 'ID',
        width: 80,
        type: 'string',
        allowHiding: false,
    },
    {
        field: 'type',
        title: 'Type',
        // width: 280,
        type: 'string',
    },
    {
        field: 'title',
        title: 'Title',
        // width: 160,
        type: 'string',
        allowHiding: false,
    },
    {
        field: 'sequenceno',
        title: 'Sequence #',
        // width: 160,
        type: 'string',
        allowHiding: true
    },
    {
        field: 'collectionTypeCode',
        title: 'Collection Type Code',
        // width: 160,
        type: 'string',
        allowHiding: true,
    },
    {
        field: 'maintenanceScheduleCode',
        title: 'Maintenance Schedule Code',
        // width: 160,
        type: 'string',
        allowHiding: true,
    },
    {
        field: 'active',
        title: 'Active',
        width: 120,
        type: 'boolean',
        allowHiding: true
    },
    {
        field: 'updatedOn',
        title: 'Updated',
        width: 160,
        type: 'datetime',
        visibleColumn: false,
        allowHiding: true,
        sortOrder: 'desc'
    },
    {
        field: 'updatedBy',
        title: 'Updated By',
        width: 160,
        type: 'string',
        visibleColumn: false,
        allowHiding: true
    },
    {
        field: 'createdOn',
        title: 'Created',
        width: 160,
        type: 'datetime',
        visibleColumn: false,
        allowHiding: true
    },
    {
        field: 'createdBy',
        title: 'Created By',
        width: 160,
        type: 'string',
        visibleColumn: false,
        allowHiding: true
    },
];
  const [items, setItems] = useState([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [mode, setMode] = useState('add');
  const [details, setDetails] = useState(null);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<JSX.Element | string>('');
  const [showDeleteAlert, setShowDeleteAlert] = useState<boolean>(false);
  const [DeletealertMessage, setDeletealertMessage] = useState('');
  const [deleteItem, setDeleteItem] = useState(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [callAfterInit, setCallAfterInit] = useState<boolean>(true);
  const [hideAlertButton, setHideAlertButton] = useState<boolean>(false);


  useEffect(() => {
    getItemData();
  }, []);

  const DeleteItem = (item: any) => {
    setAlertMessage(<div className={'alertLoader'}><Spinner size='large' label="Loading..." /></div>);
    setHideAlertButton(true);
    setShowAlert(true);
    setShowDeleteAlert(false);
   
    utility.genericDeleteAPICall(Config.AssetQuestionList, item).then(
      (data: any) => {
        setAlertMessage('Asset Question has been deleted.');
        setShowAlert(true);
        setShowDeleteAlert(false);
        setLoading(false);
        getItemData();
      },
      (err) => {
        console.log(err);
        setAlertMessage('Cannot delete asset question. Please try again later.');
        setShowAlert(true);
        setShowDeleteAlert(false);
        setHideAlertButton(false);
      }
    );
  };

  const EditItemModal = (item: any) => {
    setDetails(item);
    setMode('edit');
    setShowModal(true);
  };

  // const renderNewModal = () => {
  //   return (
  //     <AddEditAssetQuestion
  //       showModal={showModal}
  //       onClose={closeEditModal}
  //       onClick={getItemData}
  //       message={message}
  //       currentUser={currentUser}
  //       mode={mode}
  //       details={details}
  //     />
  //   );
  // };

  const closeEditModal = () => {
    setShowModal(false);
  };

  const showNewModal = () => {
    setShowModal(true);
    setDetails(null);
    setMode('add');
  };

  const message = (message: any) => {
    setAlertMessage(message + ' successfully');
    setShowAlert(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };


  const getItemData = () => {
    let dataToPassToService: any = {}; // Initialize with appropriate data
    setLoading(true);
    utility.genericGetAPICall(Config.SearchAssetFields).then(
      (data: any) => {
        let _items:any = [];
        //console.log(data);

        for (let r of data) {
            _items.push({
                id: r.Id,
                title: r.Title,
                type: r.Type,
                sequenceno: r.Sequenceno,
                collectionTypeCode: r.CollectionTypeCode,
                maintenanceScheduleCode: r.MaintenanceScheduleCode,
                active: r.Isactive,
                updatedBy: r.updatedBy,
                updatedOn: r.updated,
                createdBy: r.createdBy,
                createdOn: r.created,
            });
        }

        _items = utility._sortItems(_items, 'updatedOn', true);
        setItems(_items);
        setCallAfterInit(true)
        setLoading(false);
      },
      (err) => {
        console.log(err);
        setLoading(false);
      }
    );
  };

  const stopCommsAfterInit = () => {
    setCallAfterInit(false);
  };

  const alert = () => {
    return (
      <AlertDialog
        showDialog={showAlert}
        message={alertMessage}
        title={Config.productTitle}
        hideAlertButton={!hideAlertButton}
        onClose={() => {setAlertMessage(''); setShowAlert(false);}}
      />
    );
  };

  const DeleteDialogData = (item: any) => {
    setDeletealertMessage('Are you sure you want to delete?');
    setShowDeleteAlert(true);
    setDeleteItem(item);
  };

  const DeleteAlert = () => {
    return (
      <DeleteDialog
        showDialog={showDeleteAlert}
        message={DeletealertMessage}
        title="worxOnline"
        onClose={() => setShowDeleteAlert(false)}
        onOkButton={() => DeleteItem(deleteItem)}
        onCancelButton={() => setShowDeleteAlert(false)}
      />
    );
  };

  return (
    <>
      {/* {renderNewModal()} */}
      {showDeleteAlert && DeleteAlert()}
      {showAlert && alert()}
      <div>
        {!loading ? (
        <div className='results-container'>
          <DataTable
            style={{ width: '100%' }}
            filteralbe={true}
            groupable={true}
            dataSource={items}
            columns={columns}
            fileName={'Asset Questions'}
            columnChooser={'assetquestionColumns'}
            selectionMode={false}
            showHideColumnMenu={true}
            callAfterInit={callAfterInit}
            stopCommsAfterInit={stopCommsAfterInit}
            loading={loading}
            allowExportSelectedData={false}
            showNewModal={showNewModal}
            deleteItem={DeleteDialogData}
            editItem={EditItemModal}
            showCommandBtn={false}
            showNewBtn={true}
            showMoreBtn={true}
          />
          </div>
        ) : (
          <Spinner size="large" label="Loading..." />
        )}
      </div>
    </>
  );
};

export default AssetQuestion;
