import * as React from 'react'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import utility from '../../../classes/utility'
import global from '../../../classes/global'
import { Config } from '../../../classes/config'
import AlertDialog from '../../Common/AlertDialog'
import AssignDatatable from '../../Common/CustomASDataGrid'
import DateBoxInput from '../../Common/ReusableComponents/DateBox'
import SelectBoxInput from '../../Common/ReusableComponents/SelectInputBox'
import TextBoxInput from '../../Common/ReusableComponents/TextBox'
import Spinner from '../../Common/ReusableComponents/Spinner'
// import DisplayDetailsModal from '../common/DisplayDetailModal/DisplayDetailModal';
import moment from 'moment-timezone'
import InputLabel from '@mui/material/InputLabel'
import Typography from '@mui/material/Typography'

const PlantUsage: React.FC<any> = props => {
    const searchResultsText = 'Please Search To Populate Results'
  const [items, setItems] = React.useState<any[]>([])
  const [columns] = React.useState<any[]>([
    {
      field: 'equipmentnumber',
      title: 'Plant and Fleet #',
      type: 'string',
      allowHiding: false
    },
    {
      field: 'Workordernumber',
      title: 'Workorder #',
      type: 'string',
       link: 'workorderLink',
      allowHiding: true
    },
    {
      field: 'title',
      title: 'Title',
      type: 'string',
      allowHiding: true
    },
    {
      field: 'Regonumber',
      title: 'Rego #',
      type: 'string',
      allowHiding: false
    },
    {
      field: 'starttime',
      title: 'Start Time',
      width: 160,
      type: 'datetime',
      allowHiding: true
    },
    {
      field: 'endtime',
      title: 'End Time',
      width: 160,
      type: 'datetime',
      allowHiding: true
    },
    {
      field: 'hoursworked',
      title: 'Hours Worked',
      type: 'string',
      allowHiding: true
    },
    {
      field: 'department',
      title: 'Department',
      type: 'string',
      allowHiding: true
    },
    {
      field: 'updatedOn',
      title: 'Updated',
      width: 160,
      type: 'datetime',
      visibleColumn: false,
      allowHiding: true,
      sortOrder: 'desc'
  },
  {
      field: 'updatedBy',
      title: 'Updated By',
      width: 160,
      type: 'string',
      visibleColumn: false,
      allowHiding: true
  },
  {
      field: 'createdOn',
      title: 'Created',
      width: 160,
      type: 'datetime',
      visibleColumn: false,
      allowHiding: true
  },
  {
      field: 'createdBy',
      title: 'Created By',
      width: 160,
      type: 'string',
      visibleColumn: false,
      allowHiding: true
  },
  ])
  const [loading, setLoading] = React.useState(false)
  const [showModal, setShowModal] = React.useState(false)
  const [showAlert, setShowAlert] = React.useState(false)
  const [alertMessage, setAlertMessage] = React.useState('')
  const [details, setDetails] = React.useState<any>(null)
  const [departmentItems, setDepartmentItems] = React.useState<any[]>([])
  const [alldepartmentItems, setAllDepartmentItems] = React.useState<any[]>([])
  const [state, setState] = React.useState({
    items: [],
    dateCreatedfrom: null,
    dateCreatedTo: null,
    equipmentno: '',
    workorderno: '',
    department:
      props.role === global.worxOnline_Contractor || props.role === global.worxOnline_ContractorAdmin
        ? props.Department
        : ''
  })
  const [resultText, setResultText] = React.useState(searchResultsText)

  React.useEffect(() => {
    const tempstate = localStorage.getItem('equipmentUsageState')
    _getDepartmentData()
    if (tempstate) {
      const allState = JSON.parse(tempstate)
      setState(prevState => ({
        ...prevState,
        dateCreatedfrom: allState.dateCreatedfrom ? allState.dateCreatedfrom : '',
        dateCreatedTo: allState.dateCreatedTo ? allState.dateCreatedTo : '',
        equipmentno: allState.equipmentno ? allState.equipmentno : '',
        workorderno: allState.workorderno ? allState.workorderno : '',
        department: allState.department ? allState.department : ''
      }))
    }
  }, [])

  const handleValueChange = (e: any, fieldName: string) => {
    setState(prevState => ({ ...prevState, [fieldName]: e.value }))
  }

  const handleDropdownChange = (e: any, fieldName: string) => {
    setState(prevState => ({ ...prevState, [fieldName]: e.selectedItem }))
  }

  const _handleKeyDown = (e: any) => {
    if (e.event.originalEvent.key === 'Enter') {
      getequipmentnumberwithcriteria()
    }
  }

  const alert = () => {
    return (
      <AlertDialog
        showDialog={showAlert}
        message={alertMessage}
        title={Config.productTitle}
        onClose={() => {
          setAlertMessage('')
          setShowAlert(false)
        }}
      />
    )
  }

  const clearFilter = () => {
    setState({
      items: [],
      workorderno: '',
      equipmentno: '',
      dateCreatedfrom: null,
      dateCreatedTo: null,
      department:
        props.role === global.worxOnline_Contractor || props.role === global.worxOnline_ContractorAdmin
          ? state.department
          : ''
    })
    setItems([])
    localStorage.setItem('equipmentUsageState', '')
    localStorage.removeItem('datagridFilterValue')
  }

  const viewDetials = (item: any) => {
    setDetails(item)
    setShowModal(true)
  }

  // const renderDetailsModal = () => {
  //     return (
  //         <DisplayDetailsModal
  //             details={details}
  //             columns={columns}
  //             showModal={showModal}
  //             onClick={() => setShowModal(false)}
  //         />
  //     );
  // };

  const _getDepartmentData = () => {
    utility
      .genericGetAPICall(Config.DepartmentList)
      .then((data: any) => {
        const deptItems = data.map((r: any) => ({ id: r.id, text: r.title }))
        const sortedDeptItems = utility._sortItems(deptItems, 'text', false)
        setDepartmentItems(sortedDeptItems)
        setAllDepartmentItems(sortedDeptItems)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const getequipmentnumberwithcriteria = () => {
    let criteriaSelected = false
    const dataToPassToService: any = {}

    if (state.workorderno) {
      dataToPassToService.workorder_id = [state.workorderno]
      criteriaSelected = true
    }

    if (state.equipmentno) {
      dataToPassToService.equipment_id = [state.equipmentno]
      criteriaSelected = true
    }

    if (state.department) {
      dataToPassToService.department_id = [state.department.id]
      criteriaSelected = true
    }

    if (state.dateCreatedTo && state.dateCreatedfrom) {
        let dcreatedfrom = moment(state.dateCreatedfrom);
        let dcreatedTo = moment(state.dateCreatedTo);
        if (dcreatedTo.isBefore(dcreatedfrom, 'day')) {
        setAlertMessage('Date To should be greater than Date From')
        setShowAlert(true)
        return
      }
    }

    if (state.dateCreatedfrom || state.dateCreatedTo) {
      if (state.dateCreatedfrom) {
        const startOfDay = moment(state.dateCreatedfrom).startOf('day')
        const startOfDayUTC = startOfDay.utc()
        dataToPassToService.used_from = startOfDayUTC.toISOString()
      }
      if (state.dateCreatedTo) {
        const endOfDay = moment(state.dateCreatedTo).endOf('day')
        const endOfDayUTC = endOfDay.utc()
        dataToPassToService.used_to = endOfDayUTC.toISOString()
      }
      criteriaSelected = true
    }

    if (!criteriaSelected) {
      setAlertMessage('Please select search criteria')
      setShowAlert(true)
      return
    }

    setLoading(true)

    utility
      .genericPostAPICall(Config.SearchEquipmentUsage, dataToPassToService)
      .then((data: any) => {
        const itemsData: any = data.map((item: any) => {
          const starttime: Date = new Date(item.start_time)
          const endTime: Date = new Date(item.end_time)
          const delta: number = Math.abs(endTime.getTime() - starttime.getTime()) / 1000

          const days = Math.floor(delta / 86400)
          const hours = Math.floor(delta / 3600) % 24
          const minutes = Math.floor(delta / 60) % 60

          return {
            Workordernumber: item.workorder_id,
            title: item.title,
            id: item.id,
            equipmentnumber: item.resource_code,
            Regonumber: item.rego_number,
            department: item.department_title,
            equipmentid: item.equipment_id,
            starttime: item.start_time,
            endtime: item.end_time,
            hoursworked: item.hours_worked ? item.hours_worked : `${days} days ${hours} hours ${minutes} minutes`,
            created: item.created_by,
            createdOn: item.created,
            updated: item.updated_by,
            updatedOn: item.updated,
          }
        })

        if (itemsData.length === 0) {
          setAlertMessage('No results found for this criteria')
          setShowAlert(true)
        }

        setItems(utility._sortItems(itemsData, 'updatedOn', true))
        setLoading(false)

        localStorage.setItem('equipmentUsageState', JSON.stringify(state))
      })
      .catch(err => {
        console.log(err)
        setLoading(false)
      })
  }

  const renderAssignDataTable = () => {
    if (loading) return <Spinner />
    return (
      <AssignDatatable
        dataSource={items}
        columns={columns}
        filteralbe={true}
        groupable={true}
        fileName={'PlantUsage'}
        columnChooser={'plantUsageColumns'}
        allowExportSelectedData={false}
        showNewBtn={false}
        showCommandBtn={false}
        showViewDetailBtn={false}
        showHideColumnMenu={true}
      />
    )
  }

  return (
    <React.Fragment>
      {showAlert && alert()}
      {/* {showModal && renderDetailsModal()} */}
      <div className='ui-container'>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4}>
            <InputLabel shrink className='label bold'>
              Equipment No
            </InputLabel>
            <TextBoxInput
              placeholder='Equipment No'
              value={state.equipmentno}
              onValueChanged={(e: any) => handleValueChange(e, 'equipmentno')}
              onKeyDown={_handleKeyDown}
              stylingMode='outlined'
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <InputLabel shrink className='label bold'>
              Workorder No
            </InputLabel>
            <TextBoxInput
              placeholder='Workorder No'
              value={state.workorderno}
              onValueChanged={(e: any) => handleValueChange(e, 'workorderno')}
              onKeyDown={_handleKeyDown}
              stylingMode='outlined'
            />
          </Grid>
          {props.role !== global.worxOnline_Contractor && props.role !== global.worxOnline_ContractorAdmin && (
            <Grid item xs={12} sm={6} md={4}>
              <InputLabel shrink className='label bold'>
                Department
              </InputLabel>
              <SelectBoxInput
                placeholder='Department'
                items={departmentItems}
                selectedItem={state.department}
                onSelectionChanged={(e: any) => handleDropdownChange(e, 'department')}
                stylingMode='outlined'
                displayExpr={'text'}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={6} md={4}>
                <InputLabel shrink className='label bold'>
                Date From
                </InputLabel>
                    <DateBoxInput
                        stylingMode='outlined'
                        placeholder="Date From"
                        type={'date'}
                        value={state.dateCreatedfrom ? new Date(state.dateCreatedfrom) : null}
                        displayFormat={'dd/MM/yyyy'}
                        onValueChanged={(e: any) => handleValueChange(e, 'dateCreatedfrom')}
                        showClearButton={true}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                <InputLabel shrink className='label bold'>
                Date To
                </InputLabel>
                    <DateBoxInput
                    stylingMode='outlined'
                        placeholder="Date To"
                        type={'date'}
                        value={state.dateCreatedTo ? new Date(state.dateCreatedTo) : null}
                        displayFormat={'dd/MM/yyyy'}
                        onValueChanged={(e: any) => handleValueChange(e, 'dateCreatedTo')}
                        showClearButton={true}
                    />
                </Grid>
          </Grid>
          <div className='actions-container'>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}></Grid>
              <Grid item xs={12} sm={6}>
              <div className='action-buttons'>
              <Button onClick={clearFilter} variant='outlined'>
                    Clear
                  </Button>
                <Button onClick={getequipmentnumberwithcriteria}variant='outlined' color='primary' className='primary-button'>
                  Search
                </Button>
                </div>
              </Grid>
            </Grid>
          </div>
        
      </div>
      <div className=''>
          <h2 className='pl-10'>Results</h2>
          <Typography>{resultText}</Typography>
          {!loading ? (
            <div>
              {items.length > 0 && (
                <div className='results-container'>
            {renderAssignDataTable()}
            </div>
              )}
            </div>
          ) : (
            <Spinner size='large' label={'Loading..'} className='loader' />
          )}
        </div>
      
    </React.Fragment>
  )
}

export default PlantUsage
