import React, { useState, useEffect } from 'react'
import utility from '../../../classes/utility'
import { Config } from '../../../classes/config'
import DataTable from '../../Common/CustomASDataGrid'
import Spinner from '../../Common/ReusableComponents/Spinner'
import global from '../../../classes/global'
import CustomStore from 'devextreme/data/custom_store'
import { LoadOptions } from 'devextreme/data'
import JsonCellRenderer from '../../Common/ReusableComponents/JsonCellRenderer';
import ModelEditor from '../../Common/ReusableComponents/ModelEditor';
import TextAreaInput from '../../Common/ReusableComponents/TextArea';
import notify from 'devextreme/ui/notify'

interface LoadResult {
  data: any[]
  totalCount: number
}

const PrestartForms = (props: any) => {
  if (props.role !== global.worxOnline_Admin) {
    window.open('#/AccessDenied', '_self')
  }

  const [dataLoaded, setDataLoaded] = useState(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [items, setItems] = useState<any[]>([])
  const filterValue = [['is_active', '=', true]]

  const columns = [
    {
      field: 'id',
      title: 'ID',
      width: 80,
      type: 'string',
      allowHiding: false,
      visibleColumn: false,
      formItem: {
        visible: false
      }
    },
    {
      field: 'title',
      title: 'Title',
      // width: 160,
      type: 'string',
      allowHiding: false,
      isRequired: true
    },
    // {
    //   field: 'pre_model',
    //   title: 'Model',
    //   width: 280,
    //   type: 'string',
    //   cellRenderer: JsonCellRenderer,
    //   editCellComponent: ModelEditor
    // },
    {
      field: 'pre_model',
      title: 'Model',
      width: 250,
      type: 'string',
      allowHiding: false,
      visibleColumn: false,
      formItem: {
        visible: true,
        editorType: 'dxTextArea',
        editorOptions: {
          height: 100,
          render: (data: any) => (
            <div>
              <TextAreaInput
                stylingMode='outlined'
                value={data.model}
                onValueChanged={(e: any) => {
                  data.model = e.value;
                }}
              />
            </div>
          )
        }
      }
    },
    {
      field: 'header',
      title: 'Header',
      width: 280,
      type: 'string',
      isRequired: true
    },
    {
      field: 'is_active',
      title: 'Is Active',
      type: 'boolean',
      allowHiding: false,
      calculateCellValue: (data: any) => data.is_active ?? true, // Default to true
      setCellValue: (newData: any, value: any) => {
        newData.is_active = value
      }
    },
    {
      field: 'post_check_list',
      title: 'Post Checklist',
      type: 'boolean',
      allowHiding: false,
      calculateCellValue: (data: any) => data.post_check_list ?? false, // Default to true
      setCellValue: (newData: any, value: any) => {
        newData.post_check_list = value
      }
    },
    // {
    //   field: 'post_model',
    //   title: 'Post Model',
    //   width: 280,
    //   type: 'string',
    //   cellRenderer: JsonCellRenderer,
    //   editCellComponent: ModelEditor
    // },
    {
      field: 'post_model',
      title: 'Post Model',
      width: 250,
      type: 'string',
      allowHiding: false,
      visibleColumn: false,
      formItem: {
        visible: true,
        editorType: 'dxTextArea',
        editorOptions: {
          height: 100,
          render: (data: any) => (
            <div>
              <TextAreaInput
                stylingMode='outlined'
                value={data.model}
                onValueChanged={(e: any) => {
                  data.model = e.value;
                }}
              />
            </div>
          )
        }
      }
    },
    {
      field: 'created',
      title: 'Created',
      width: 160,
      type: 'datetime',
      visibleColumn: false,
      allowHiding: true,
      formItem: {
        visible: false,
      }
    },
    {
      field: 'created_by',
      title: 'Created By',
      width: 160,
      type: 'string',
      visibleColumn: false,
      allowHiding: true,
      formItem: {
        visible: false,
      }
    },
    {
      field: 'updated',
      title: 'Updated',
      width: 160,
      type: 'datetime',
      visibleColumn: false,
      allowHiding: true,
      formItem: {
        visible: false,
      }
    },
    {
      field: 'updated_by',
      title: 'Updated By',
      width: 160,
      type: 'string',
      visibleColumn: false,
      allowHiding: true,
      formItem: {
        visible: false,
      }
    }
  ]

  useEffect(() => {
    fetchItemsData()
  }, [])

  const fetchItemsData = async () => {
    setLoading(true)
    try {
      const itemsResponse = await utility.genericGetAPICall(Config.PrestartFormsListName);
      let items: any[] = []

      for (let r of itemsResponse) {
        items.push({
          ...r,
          pre_model: JSON.stringify(r.pre_model, null, 2),
          post_model: JSON.stringify(r.post_model, null, 2),
          id: r.id,
          is_active: r.is_active ? true : false,
          post_check_list: r.post_check_list ? true : false
        })
      }
      items = utility._sortItems(items, 'id', true)
      setItems(items)
      setLoading(false)
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  }

  const handleData = (data: any) => {
    return {
      ...data,
      pre_model: JSON.parse(data.pre_model), 
      post_model: JSON.parse(data.post_model) // Convert model back to array of strings
    }
  }

  const dataSource = new CustomStore({
    load: async (loadOptions: LoadOptions): Promise<LoadResult> => {
      return {
        data: items,
        totalCount: items.length
      }
    },
    key: 'id',
    onLoaded: () => {
      console.log('Data loaded')
      setLoading(false)
      setDataLoaded(true)
    },
    insert: async (values: any) => {
      try {
        if (values.pre_model && typeof values.pre_model === 'string') {
          values.pre_model = JSON.parse(values.pre_model)
        }
        if (values.post_model && typeof values.post_model === 'string') {
          values.post_model = JSON.parse(values.post_model)
        }
        await utility.genericPostAPICall(`${Config.PrestartFormsListName}/`, values)
        notify('Row added successfully.', 'success', 3000)
        await fetchItemsData()
      } catch (error: any) {
        const errorMessage = error.message || 'An unexpected error occurred.';
        notify(errorMessage, 'error', 5000); 
        console.error(errorMessage);
      }
    },
    update: async (key: any, values: any) => {
      try {
        if (values.pre_model && typeof values.pre_model === 'string') {
          values.pre_model = JSON.parse(values.pre_model)
        }
        if (values.post_model && typeof values.post_model === 'string') {
          values.post_model = JSON.parse(values.post_model)
        }
        await utility.genericUpdatePatchAPICall(`${Config.PrestartFormsListName}/`, key, values)
        notify('Row updated successfully.', 'success', 3000)
        await fetchItemsData()
      } catch (error: any) {
        const errorMessage = error.message || 'An unexpected error occurred.';
        notify(errorMessage, 'error', 5000); 
        console.error(errorMessage);
      }
    },
    remove: async (key: any) => {
      try {
        await utility.genericDeleteAPICall(Config.PrestartFormsListName, key)
        notify('Row deleted successfully.', 'success', 3000)
        await fetchItemsData()
      } catch (error: any) {
        const errorMessage = error.message || 'An unexpected error occurred.';
        notify(errorMessage, 'error', 5000); 
        console.error(errorMessage);
      }
    }
  })

  return (
    <>
      <div>
        {!loading ? (
          <div className='results-container'>
            <DataTable
              dataSource={dataSource}
              style={{ width: '100%' }}
              filteralbe={true}
              groupable={true}
              columns={columns}
              fileName={'PrestartForms'}
              columnChooser={'PrestartFormColumns'}
              selectionMode={false}
              showHideColumnMenu={true}
              loading={loading}
              allowExportSelectedData={false}
              columnHidingEnabled={true}
              showCommandBtn={false}
              hideSearchPanel={false}
              hideCommandDeleteBtn={false}
              showNewBtn={false}
              showViewDetailBtn={false}
              hideNewBtn={true}
              defaultfiltervalue={filterValue}
              showMoreBtn={true}
              visible={dataLoaded}
            />
          </div>
        ) : (
          <Spinner size='large' className='loader' />
        )}
      </div>
    </>
  )
}

export default PrestartForms;
