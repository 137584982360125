import React, { useState, useEffect } from 'react'
import utility from '../../../classes/utility'
import { Config } from '../../../classes/config'
import DataTable from '../../Common/CustomASDataGrid'
import Spinner from '../../Common/ReusableComponents/Spinner'
import CustomStore from "devextreme/data/custom_store";
import { LoadOptions } from "devextreme/data";
import CustomEditWorkTypeField from './CustomEditWorkTypeField';
import notify from 'devextreme/ui/notify'

interface WorkType {
  worktype_id: number;
  worktype_name: string;
}

interface LoadResult {
  data: any[];
  totalCount: number;
}

const WorkTypeField: React.FC<any> = props => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [items, setItems] = useState<any[]>([]);
  const [workTypes, setWorkTypes] = useState<WorkType[]>([]);

  const setWorktypeValue = (rowData: any, value: number) => {
    rowData.worktype_id = value || null;
    const worktype = workTypes.find(d => d.worktype_id === value);
    if (worktype) {
      rowData.worktype_name = worktype.worktype_name;
    }
  };
  
  const columns = [
    {
      field: 'id',
      title: 'ID',
      width: 80,
      type: 'string',
      allowHiding: true,
      formItem: {
        visible: false,
      },
      visibleColumn: false
    },
    {
      field: 'type',
      title: 'Type',
      // width: 160,
      type: 'string',
      allowHiding: false,
      isRequired: true,
    },
    {
      field: 'title',
      title: 'Title',
      // width: 160,
      type: 'string',
      allowHiding: false,
      isRequired: true,
    },
    {
      field: 'worktype_id',
      title: 'Activity',
      // width: 160,
      type: 'string',
      allowHiding: true,
      isRequired: true,
      setCellValue: setWorktypeValue,
      // Lookup: {
      //   dataSource: workTypes,
      //   valueExpr: 'worktype_id',
      //   displayExpr: 'worktype_name'
      // },
      // formItem: {
      //   colSpan: 2,
        //editorType: 'dxSelectBox',
        // editorOptions: {
        //   value: '',
        //   valueExpr: 'worktype_id',
        //   displayExpr: 'worktype_name',
        //   dataSource: workTypes
        // }
      // },
      editCellComponent: (props:any) => (
        <CustomEditWorkTypeField
          {...props}
          workTypes={workTypes}
          detailColumns={detailColumns}
          rowData={props.data}
          onDataChange={(updatedData: any) => handleDataChange(props.data.id, updatedData)}
        />
      ),
    },
    {
      field: 'created',
      title: 'Created',
      width: 160,
      type: 'datetime',
      visibleColumn: false,
      allowHiding: true,
      formItem: {
        visible: false,
      }
    },
    {
      field: 'created_by',
      title: 'Created By',
      width: 160,
      type: 'string',
      visibleColumn: false,
      allowHiding: true,
      formItem: {
        visible: false,
      }
    },
    {
      field: 'updated',
      title: 'Updated',
      width: 160,
      type: 'datetime',
      visibleColumn: false,
      allowHiding: true,
      formItem: {
        visible: false,
      }
    },
    {
      field: 'updated_by',
      title: 'Updated By',
      width: 160,
      type: 'string',
      visibleColumn: false,
      allowHiding: true,
      formItem: {
        visible: false,
      }
    }
  ];

  const detailColumns = [
    {
      field: 'title',
      title: 'Title',
      // width: 280,
      type: 'string'
    },
    {
      field: 'sequence_no',
      title: 'Sequence #',
      // width: 280,
      type: 'string',
      isRequired: true,
    },
    {
      field: 'parent_sequence_no',
      title: 'Parent Sequence #',
      // width: 280,
      type: 'string'
    },
    {
      field: 'trigger',
      title: 'Trigger',
      // width: 160,
      type: 'string',
      allowHiding: true,
      isRequired: true,
    },
    {
      field: 'erp_field',
      title: 'ERP Field',
      // width: 160,
      type: 'string',
      allowHiding: true,
      isRequired: true,
    },
    {
      field: 'erp_table',
      title: 'ERP Table',
      // width: 160,
      type: 'string',
      allowHiding: true,
      isRequired: true,
    },
    {
      field: 'control_name',
      title: 'Control Name',
      // width: 160,
      type: 'string',
      allowHiding: true
    },
    {
      field: 'condition',
      title: 'Condition',
      // width: 160,
      type: 'string',
      allowHiding: true
    },
    {
      field: 'is_active',
      title: 'Active',
      // width: 160,
      type: 'boolean',
      allowHiding: true
    },
    {
      field: 'is_required',
      title: 'Is Required',
      // width: 160,
      type: 'boolean',
      allowHiding: true
    }
  ];

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {

        const workTypeResponse = await utility.genericGetAPICall(`${Config.WTlistname}`);
        const workTypeData = workTypeResponse.filter((r: any) => r.is_active === true).map((r: any) => ({
          worktype_id: r.id,
          worktype_name: r.title,
        }));
        const sortedFormData = utility._sortItems(workTypeData, 'worktype_name', false);
        setWorkTypes(sortedFormData);
        console.log('workTypeData', workTypeData);

     await fetchItemsData(workTypeData);

      setLoading(false);
      setDataLoaded(true);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

    fetchData();
  }, []);

  const fetchItemsData = async (workTypeData?: any[]) => {
    setLoading(true);
  try {
    const itemsResponse = await utility.genericGetAPICall(Config.workTypeFieldList);
    let items: any[] = [];

    for (let r of itemsResponse) {
      let workTypeNameArray: any[] = [];
      let workTypeIdArray: any[] = [];
      
      r.wotype_worktype_field && r.wotype_worktype_field.forEach((wt: any) => {
        workTypeNameArray.push(wt.title);
        workTypeIdArray.push(wt.worktype_id);
      });

      // Find the matched workType from workTypeData (if available)
      let matchedWorkType = workTypeData?.find((w: any) => w.worktype_id === workTypeIdArray[0]);

      // Construct the item object
      let item = {
        ...r,
        worktype_id: workTypeIdArray.length > 0 ? workTypeIdArray[0] : null,
        worktype_name: matchedWorkType ? matchedWorkType.worktype_name : '', // Default to empty string if no match
        choices: r.choices ? JSON.stringify(r.choices) : '',
        active: r.is_active ? true : false,
        wotype_worktype_field: r.wotype_worktype_field,
      };

      items.push(item);
    }

    items = utility._sortItems(items, 'worktype_id', true);
    setItems(items);
    setLoading(false);
  } catch (error) {
    console.error(error);
    setLoading(false);
  }
};


const handleDataChange = async (id: number, updatedData: any) => {
  const newData = items.map(item => item.id === id ? { ...item, ...updatedData } : item);
  setItems(newData);
  try {
    await utility.genericUpdatePatchAPICall(Config.workTypeFieldList, id, updatedData);
    await fetchItemsData();
  } catch (error) {
    console.error('Error updating data:', error);
  }
};
  const dataSource = new CustomStore({
    load: async (loadOptions: LoadOptions): Promise<LoadResult> => {
      return {
        data: items,
        totalCount: items.length
      };
    },
    key: 'id',
    onLoaded: () => {
      console.log('Data loaded');
      setLoading(false);
      setDataLoaded(true);
    },
    insert: async (values: any) => {
      try {
        await utility.genericPostAPICall(Config.workTypeFieldList, values);
        notify('Row added successfully', 'success', 3000)
        await fetchItemsData();
      } catch (error:any) {
        const errorMessage = error.message || 'An unexpected error occurred.';
        notify(errorMessage, 'error', 5000); 
        console.error(errorMessage);
      }
    },
    update: async (key: any, values: any) => {
      try {
        await utility.genericUpdatePatchAPICall(Config.workTypeFieldList, key, values);
        notify('Row updated successfully', 'success', 3000)
        await fetchItemsData();
      } catch (error:any) {
        const errorMessage = error.message || 'An unexpected error occurred.';
        notify(errorMessage, 'error', 5000); 
        console.error(errorMessage);
      }
    },
    remove: async (key: any) => {
      try {
        await utility.genericDeleteAPICall(Config.workTypeFieldList, key);
        notify('Row deleted successfully', 'success', 3000)
        await fetchItemsData();
      } catch (error:any) {
        const errorMessage = error.message || 'An unexpected error occurred.';
        notify(errorMessage, 'error', 5000); 
        console.error(errorMessage);
      }
    }
  });

  return (
    <>
      <div>
        {!loading ? (
          <div className='results-container'>
            <DataTable
              dataSource={dataSource}
              style={{ width: '100%' }}
              filteralbe={true}
              groupable={true}
              columns={columns}
              fileName={'ActivityFields'}
              columnChooser={'ActivityFieldColumns'}
              selectionMode={false}
              showHideColumnMenu={true}
              loading={loading}
              allowExportSelectedData={false}
              columnHidingEnabled={true}
              showCommandBtn={false}
              hideSearchPanel={false}
              hideCommandDeleteBtn={false}
              showNewBtn={false}
              showViewDetailBtn={false}
              hideNewBtn={true}
              //defaultfiltervalue={filterValue}
              showMoreBtn={true}
              visible={dataLoaded}
              showDetailTable={true}
              detailColumns={detailColumns}
            />
          </div>
        ) : (
          <Spinner size='large' className='loader' />
        )}
      </div>
    </>
  );
};

export default WorkTypeField;
