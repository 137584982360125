import DataSource from 'devextreme/data/data_source'
import global from './global'

interface JSONData {
  expires_at: string
  refresh_token: string
}

interface LoadResult {
  data: any[];
  totalCount: number;
}

interface ApiResponse {
  data: any[];
  totalCount?: number;
}

const utility = {
  async getTokenUsingAPI() {
    const currentUserEmail = localStorage.getItem('currentDetail')
    const storagedata = localStorage.getItem(global.accessToken)

    if (storagedata) {
      const data = JSON.parse(storagedata);
      const currentDateTime = new Date();
      const endDate = new Date(data.expires_at * 1000);

      if (currentDateTime >= endDate) {
        const res = await this.tokenRefreshAPI(data.refresh_token);
        if (res) return res.access_token;
      } else {
        return data.access_token;
      }
    }
  },

  async tokenAPIWithUserDetails(currentUserEmail: any, password: any) {
    const body = {
      username: currentUserEmail,
      password: password,
      grant_type: '',
      scope: '',
      client_id: '',
      client_secret: ''
    }

    const formBody = Object.keys(body)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(body[key as keyof typeof body]))
      .join('&')

    try {
      const result = await fetch(`${global.AuthAPIURL}`, {
        method: 'POST',
        body: formBody,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        }
      })

      if (result.ok) {
        const jsonResult = await result.json()
        return jsonResult
      } else {
        const text = await result.text()
        throw new Error(text)
      }
    } catch (error: any) {
      console.error(error)
      throw error
    }
  },

  async tokenRefreshAPI(refreshToken: string) {
    try {
      const result = await fetch(`${global.spAPIURL}/auth/refresh-token`, {
        method: 'POST',
        body: JSON.stringify({ refresh_token: refreshToken }),
        headers: {
          'Content-Type': 'application/json'
        }
      })
      if (result.ok) {
        const jsonResult = await result.json()
        localStorage.setItem(global.accessToken, JSON.stringify(jsonResult));
        return jsonResult
      } else {
        const text = await result.text()
        throw new Error('Network response was not ok.' + text)
      }
    } catch (error: any) {
      console.error(error)
      throw error
    }
  },

  createDataSource(endpoint: string, method: string = 'GET', data: any = null) {
    return new DataSource({
      load: async loadOptions => {
        const token = await utility.getTokenUsingAPI();
        const [urlPath, queryParams] = endpoint?.split('?');
        const params: any = queryParams ? Object.fromEntries(new URLSearchParams(queryParams)) : {};

        const queryString = new URLSearchParams(params).toString();
        const url = `${global.spAPIURL}/${urlPath}${queryString ? `?${queryString}` : ''}`;

        const result = await fetch(url, {
          method,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          body: data ? JSON.stringify(data) : null
        });

        if (result.ok) {
          const responseStatus = await result.status;
                if (responseStatus === 204) {
                    return {
                        data: [],
                        totalCount: 0
                    };
                }
          try {
            const response: ApiResponse = await result.json();
            const data = Array.isArray(response) ? response : response;

            return {
              data: data,
            };
          } catch (jsonError: any) {
            throw new Error('Failed to parse JSON response: ' + jsonError.message);
          }
        } else {
          const errorText = await result.text();
          throw new Error(`Network response was not ok: ${errorText}`);
        }
      }
    });
  },

  async genericGetAPICall(endpoint: string) {
    try {
    const dataSource =  this.createDataSource(endpoint, 'GET')
      const response = await dataSource.load()
      return response 
    } catch (error) {
      console.error(error)
      throw error
    }
  },

  async genericPostAPICall(endpoint: string, data: any) {
    try {
      const dataSource = this.createDataSource(endpoint, 'POST', data);
      const response = await dataSource.load();
        return response;
    } catch (error: any) {
      console.error('Error in genericPostAPICall:', error);
  
      let defaultMessage = 'An error occurred.';
  
      try {
        // Attempt to parse the error.message
        if (error.message.startsWith('Network response was not ok:')) {
          const rawErrorMessage = error.message.replace('Network response was not ok: ', '');
          const errorDetails = JSON.parse(rawErrorMessage);
  
          if (errorDetails?.detail) {
            // Extract all "msg" values and join them into a single string
            defaultMessage = errorDetails.detail
            .map((d: any) => {
              const location = d.loc ? d.loc.join(' -> ') : 'Unknown location';
              return `${location}: ${d.msg}`;
            })
            .join(' ');
          } else if (errorDetails?.message) {
            defaultMessage = 'Network response was not ok' + errorDetails.message;
          }
        } else {
          // Fallback to the error message if parsing is not possible
          defaultMessage = 'Network response was not ok' + error.message;
        }
      } catch (parseError) {
        console.error('Error parsing the error message:', parseError);
        defaultMessage = 'An unexpected error occurred. Please try again later.';
      }
      throw new Error(defaultMessage);
    }
  },
  
  async LayerGetAPICallResponse(endpoint: string) {
    try {
      return await this.createDataSource(endpoint, 'GET')
    } catch (error) {
      console.error(error)
      throw error
    }
  },

  async genericGetAPICallForList(apiname: string, method?: any, data?: any) {
    try {
      const dataSource = this.createDataSource(apiname, method, data)
      const response = await dataSource.load()

      return response // Assuming you only need the data here
    } catch (error) {
      console.error(error)
      throw new Error('Failed to fetch data from API.')
    }
  },

  async genericGetDataByIdAPICall(apiname: string, id: any) {
    try {
      const dataSource = this.createDataSource(`${apiname}/${id}`, 'GET');
      const response = await dataSource.load();
      return response;
    } catch (error) {
      console.error(`Failed to fetch data for ${apiname}/${id}:`, error);
      throw new Error('Failed to fetch data from API.');
    }
  },

  async genericDeleteAPICall(apiname: string, id: any) {
    try {
      const dataSource = this.createDataSource(`${apiname}${id}`, 'DELETE')
      const response = await dataSource.load()
      return response
    }catch (error: any) {
        console.error('Error in genericPostAPICall:', error);
    
        let defaultMessage = 'An error occurred.';
    
        try {
          // Attempt to parse the error.message
          if (error.message.startsWith('Network response was not ok:')) {
            const rawErrorMessage = error.message.replace('Network response was not ok: ', '');
            const errorDetails = JSON.parse(rawErrorMessage);
    
            if (errorDetails?.detail) {
              // Extract all "msg" values and join them into a single string
              defaultMessage = errorDetails.detail
              .map((d: any) => {
                const location = d.loc ? d.loc.join(' -> ') : 'Unknown location';
                return `${location}: ${d.msg}`;
              })
              .join(' ');
            } else if (errorDetails?.message) {
              defaultMessage = 'Network response was not ok' + errorDetails.message;
            }
          } else {
            // Fallback to the error message if parsing is not possible
            defaultMessage = 'Network response was not ok' + error.message;
          }
        } catch (parseError) {
          console.error('Error parsing the error message:', parseError);
          defaultMessage = 'An unexpected error occurred. Please try again later.';
        }
        throw new Error(defaultMessage);
      }
    },

  async deleteBulkWorkorder(apiname: string, data: any, spAPIURL: any) {
    try {
      const dataSource = this.createDataSource(apiname, 'DELETE', data)
      const response = await dataSource.load()

      // Assuming you only need to return the response here
      return response
    } catch (error) {
      console.error(error)
      throw new Error('Failed to delete data.')
    }
  },

  async genericUpdateAPICall(apiname: string, id: any, data: any) {
    try {
      const dataSource = this.createDataSource(`${apiname}${id}`, 'PUT', data)
      const response = await dataSource.load()

      // Assuming you only need to return the response here
      return response
    } catch (error: any) {
      console.error('Error in genericPostAPICall:', error);
  
      let defaultMessage = 'An error occurred.';
  
      try {
        // Attempt to parse the error.message
        if (error.message.startsWith('Network response was not ok:')) {
          const rawErrorMessage = error.message.replace('Network response was not ok: ', '');
          const errorDetails = JSON.parse(rawErrorMessage);
  
          if (errorDetails?.detail) {
            // Extract all "msg" values and join them into a single string
            defaultMessage = errorDetails.detail
            .map((d: any) => {
              const location = d.loc ? d.loc.join(' -> ') : 'Unknown location';
              return `${location}: ${d.msg}`;
            })
            .join(' ');
          } else if (errorDetails?.message) {
            defaultMessage = 'Network response was not ok' + errorDetails.message;
          }
        } else {
          // Fallback to the error message if parsing is not possible
          defaultMessage = 'Network response was not ok' + error.message;
        }
      } catch (parseError) {
        console.error('Error parsing the error message:', parseError);
        defaultMessage = 'An unexpected error occurred. Please try again later.';
      }
      throw new Error(defaultMessage);
    }
  },

  async genericUpdatePatchAPICall(apiname: string, id: any, data: any) {
    try {
      const dataSource = this.createDataSource(`${apiname}${id}`, 'PATCH', data)
      const response = await dataSource.load()

      // Assuming you only need to return the response here
      return response
    } catch (error: any) {
      console.error('Error in genericPostAPICall:', error);
  
      let defaultMessage = 'An error occurred.';
  
      try {
        // Attempt to parse the error.message
        if (error.message.startsWith('Network response was not ok:')) {
          const rawErrorMessage = error.message.replace('Network response was not ok: ', '');
          const errorDetails = JSON.parse(rawErrorMessage);
  
          if (errorDetails?.detail) {
            // Extract all "msg" values and join them into a single string
            defaultMessage = errorDetails.detail
            .map((d: any) => {
              const location = d.loc ? d.loc.join(' -> ') : 'Unknown location';
              return `${location}: ${d.msg}`;
            })
            .join(' ');
          } else if (errorDetails?.message) {
            defaultMessage = 'Network response was not ok' + errorDetails.message;
          }
        } else {
          // Fallback to the error message if parsing is not possible
          defaultMessage = 'Network response was not ok' + error.message;
        }
      } catch (parseError) {
        console.error('Error parsing the error message:', parseError);
        defaultMessage = 'An unexpected error occurred. Please try again later.';
      }
      throw new Error(defaultMessage);
    }
  },

  async genericUpdatePortalAPICall(apiname: string, data: any) {
    try {
      const dataSource = this.createDataSource(apiname, 'POST', data)
      const response = await dataSource.load()

      // Assuming you only need to return the response here
      return response
    } catch (error: any) {
      console.error('Error in genericPostAPICall:', error);
  
      let defaultMessage = 'An error occurred.';
  
      try {
        // Attempt to parse the error.message
        if (error.message.startsWith('Network response was not ok:')) {
          const rawErrorMessage = error.message.replace('Network response was not ok: ', '');
          const errorDetails = JSON.parse(rawErrorMessage);
  
          if (errorDetails?.detail) {
            // Extract all "msg" values and join them into a single string
            defaultMessage = errorDetails.detail
            .map((d: any) => {
              const location = d.loc ? d.loc.join(' -> ') : 'Unknown location';
              return `${location}: ${d.msg}`;
            })
            .join(' ');
          } else if (errorDetails?.message) {
            defaultMessage = 'Network response was not ok' + errorDetails.message;
          }
        } else {
          // Fallback to the error message if parsing is not possible
          defaultMessage = 'Network response was not ok' + error.message;
        }
      } catch (parseError) {
        console.error('Error parsing the error message:', parseError);
        defaultMessage = 'An unexpected error occurred. Please try again later.';
      }
      throw new Error(defaultMessage);
    }
  },

  async genericUpdateNatureStripAPICallForWO(apiname: string, woID: number, data: any) {
    try {
      const dataSource = this.createDataSource(`${apiname}${woID}`, 'PATCH', data)
      const response = await dataSource.load()

      // Assuming you only need to return the response here
      return response
    } catch (error) {
      console.error(error)
      throw new Error('Failed to update data.')
    }
  },

  async genericUpdateNatureStripAPICall(apiname: string, data: any) {
    try {
      const dataSource = this.createDataSource(apiname, 'PATCH', data)
      const response = await dataSource.load()

      // Assuming you only need to return the response here
      return response
    } catch (error) {
      console.error(error)
      throw new Error('Failed to update data.')
    }
  },

  async genericGETAPIcallwithdata(apiName: string, data: any, spAPIURL: any) {
    try {
      const token = await utility.getTokenUsingAPI()

      const dataSource = this.createDataSource(apiName, 'GET', data)
      const response = await dataSource.load()

      // Assuming you only need to return the response here
      return response
    } catch (error) {
      console.error(error)
      throw new Error('Failed to fetch data.')
    }
  },

  // async genericPostAPIcall(apiName: string, data: any) {
  //   try {
  //     const dataSource = this.createDataSource(apiName, 'POST', data)
  //     const response = await dataSource.load();
  //     return response;
  //   } catch (error: any) {
  //     console.error(error)
  //     const errorMessage = utility.extractErrorMessage(error);
  //     throw new Error(errorMessage);
  //   }
  // },

  async genericPostAPIcallById(apiname: string, id: any, data: any) {
    try{
      const dataSource = this.createDataSource(`${apiname}${id}`, 'POST', data)
      const response = await dataSource.load()
      return response
    
  } catch (error: any) {
    console.error('Error in genericPostAPICall:', error);

    let defaultMessage = 'An error occurred.';

    try {
      // Attempt to parse the error.message
      if (error.message.startsWith('Network response was not ok:')) {
        const rawErrorMessage = error.message.replace('Network response was not ok: ', '');
        const errorDetails = JSON.parse(rawErrorMessage);

        if (errorDetails?.detail) {
          // Extract all "msg" values and join them into a single string
          defaultMessage = errorDetails.detail
          .map((d: any) => {
            const location = d.loc ? d.loc.join(' -> ') : 'Unknown location';
            return `${location}: ${d.msg}`;
          })
          .join(' ');
        } else if (errorDetails?.message) {
          defaultMessage = 'Network response was not ok' + errorDetails.message;
        }
      } else {
        // Fallback to the error message if parsing is not possible
        defaultMessage = 'Network response was not ok' + error.message;
      }
    } catch (parseError) {
      console.error('Error parsing the error message:', parseError);
      defaultMessage = 'An unexpected error occurred. Please try again later.';
    }
    throw new Error(defaultMessage);
  }
},

  async genericPostAPIcallForWODocument(apiName: string, data: any) {
    try {
      const dataSource = this.createDataSource(apiName, 'POST', data)
      const response = await dataSource.load()

      // Assuming you only need to return the response here
      return response
    } catch (error) {
      console.error(error)
      throw new Error('Failed to post data for WO document.')
    }
  },

  async genericPostAPIcallForDowloadDoct(apiName: string, data: any) {
    try {
      const dataSource = this.createDataSource(apiName, 'POST', data)
      const response = await dataSource.load()

      // Assuming you only need to return the response here
      return response
    } catch (error) {
      console.error(error)
      throw new Error('Failed to post data for downloading document.')
    }
  },

  async loadNaturalSearchFromApi(relativeUrl: string, apiName: string) {
    try {
      const dataSource = this.createDataSource(`${apiName}?utterance=${relativeUrl}`, 'GET')
      const response = await dataSource.load()

      // Assuming you only need to return the response here
      return response
    } catch (error) {
      console.error(error)
      throw new Error('Failed to load natural search from API.')
    }
  },

  cuniq: () => {
    const currentdate = new Date()
    const datetime =
      currentdate.getFullYear() +
      '-' +
      (currentdate.getMonth() + 1) +
      '-' +
      currentdate.getDate() +
      '-' +
      currentdate.getHours() +
      '-' +
      currentdate.getMinutes() +
      '-' +
      currentdate.getSeconds()

    return datetime
  },

  goBack: () => {
    window.history.back()
  },

  _sortItems: (items: any[], sortBy: string, descending = false): any[] => {
    if (descending) {
      return items.sort((a: any, b: any) => {
        if (a[sortBy] < b[sortBy]) {
          return 1
        }
        if (a[sortBy] > b[sortBy]) {
          return -1
        }

        return 0
      })
    } else {
      return items.sort((a: any, b: any) => {
        if (a[sortBy] < b[sortBy]) {
          return -1
        }
        if (a[sortBy] > b[sortBy]) {
          return 1
        }

        return 0
      })
    }
  },

  // groupBy(xs: any, key: any) {
  //   if (xs && xs.length > 0) {
  //     return xs.reduce(function (rv: any, x: any) {
  //       ;(rv[x[key]] = rv[x[key]] || []).push(x)

  //       return rv
  //     }, {})
  //   } else {
  //     return false
  //   }
  // },

  groupBy(xs: any, key: any) {
    if (xs && xs.length > 0) {
      return xs.reduce((rv: any, x: any) => {
        // Determine the group key
        const groupKey = typeof key === 'function' 
          ? key(x)
          : key.split('.').reduce((acc: any, k: any) => acc?.[k], x) || 'Unassigned';
  
        // Group the items
        (rv[groupKey] = rv[groupKey] || []).push(x);
        return rv;
      }, {});
    } else {
      return {};
    }
  },

  validURL(str: any) {
    const pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
      'i'
    ) // fragment locator

    return !!pattern.test(str)
  },

  populateKeyTextforDD(arr: any, keyCol: any, textCol: any) {
    arr.forEach((element: any) => {
      element.id = element[keyCol]
      element.text = element[textCol]
    })
    arr.sort((a: any, b: any) => a[textCol].localeCompare(b[textCol]))
  },

  GetPathwayObject(data: any, idColumn: any, id: any) {
    let objPathway
    for (let i = 0; i < data.length; i++) {
      if (data[i][idColumn] === id) {
        objPathway = data[i]
        break
      }
    }
    return objPathway
  },

  async getItem(storageName: any) {
    try {
      const indexedDB = window.indexedDB
      const request = indexedDB.open('worxOnline-saas', 2)

      const db = await new Promise<IDBDatabase | null>((resolve, reject) => {
        request.onupgradeneeded = event => {
          const db = request.result
          if (!db.objectStoreNames.contains('worxOnline-saas-Store')) {
            db.createObjectStore('worxOnline-saas-Store', { keyPath: 'id' })
            resolve(db)
          }
        }

        request.onsuccess = event => {
          const db = request.result
          if (!db.objectStoreNames.contains('worxOnline-saas-Store')) {
            resolve(db)
          } else {
            const transaction = db.transaction('worxOnline-saas-Store', 'readonly')
            const stores = transaction.objectStore('worxOnline-saas-Store')
            const requestItem = stores.get(storageName)

            requestItem.onsuccess = function () {
              if (requestItem.result && requestItem.result.items) {
                const result = JSON.parse(requestItem.result.items)
                resolve(result)
              } else {
                resolve(null) // Return null if no data found
              }
            }

            requestItem.onerror = function () {
              // handle error if needed
              // reject(requestItem.error);
            }

            transaction.oncomplete = function () {
              db.close()
            }
          }
        }

        request.onerror = event => {
          // handle error if needed
          console.log('Finished doing something, now closing')
          // resolve(true);
          reject()
        }
      })

      return db
    } catch (error) {
      console.error(error)
      throw error
    }
  },

  async setItem(storageName: string, data: any) {
    try {
      const indexedDB = window.indexedDB
      const request = indexedDB.open('worxOnline-saas', 2)

      request.onupgradeneeded = () => {
        const db = request.result
        if (!db.objectStoreNames.contains('worxOnline-saas-Store')) {
          db.createObjectStore('worxOnline-saas-Store', { keyPath: 'id' }) // create it
        }
      }

      const db: IDBDatabase = await new Promise((resolve, reject) => {
        request.onsuccess = () => {
          resolve(request.result)
        }

        request.onerror = () => {
          // handle error if needed
          console.log('Finished doing something, now closing')
          // resolve(true);
          reject()
        }
      })

      const transaction = db.transaction(['worxOnline-saas-Store'], 'readwrite')
      const stores = transaction.objectStore('worxOnline-saas-Store')

      const store = {
        id: storageName,
        items: JSON.stringify(data)
      }

      const requestItem = stores.add(store)

      await new Promise((resolve, reject) => {
        requestItem.onsuccess = function () {
          resolve(true)
        }

        requestItem.onerror = function () {
          console.log('Error', requestItem.error)
          reject(requestItem.error)
        }
      })

      // Close the database after transaction
      db.close()

      return true // Indicate success
    } catch (error) {
      console.error(error)
      throw error
    }
  },

  async removeItem(storeId: string) {
    try {
      const indexedDB = window.indexedDB
      const open = indexedDB.open('worxOnline-saas', 2)

      open.onsuccess = async function () {
        const db = open.result
        const transaction = db.transaction(['worxOnline-saas-Store'], 'readwrite')
        const store = transaction.objectStore('worxOnline-saas-Store')

        await store.delete(storeId)

        transaction.oncomplete = function () {
          db.close()
        }
      }

      open.onerror = function () {
        return
      }
    } catch (error: any) {
      console.error(error.message)
      throw error
    }
  },

  extractErrorMessage(errorData: any): string {
    if (typeof errorData === 'string') {
      try {
        errorData = JSON.parse(errorData); // In case errorData is still a JSON string
      } catch (e) {
        return 'An unexpected error occurred';
      }
    }

    if (errorData && errorData.detail && Array.isArray(errorData.detail)) {
      return errorData.detail.map((detail: any) => detail.msg).join(', ');
    }

    return 'An unexpected error occurred';
  },

  debounce(func: Function, delay: number){
    let timeoutId: NodeJS.Timeout;
    return (...args: any[]) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  }
};


export default utility
