import React, { useState, useEffect } from 'react'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import utility from '../../../classes/utility'
import { Config } from '../../../classes/config'
import AlertDialog from '../../Common/AlertDialog'
import DataTable from '../../Common/DataTable'
import DateBoxInput from '../../Common/ReusableComponents/DateBox'
import SelectBoxInput from '../../Common/ReusableComponents/SelectInputBox'
import TextBoxInput from '../../Common/ReusableComponents/TextBox'
import Spinner from '../../Common/ReusableComponents/Spinner'
import moment from 'moment-timezone'
import Typography from '@mui/material/Typography'
import InputLabel from '@mui/material/InputLabel'

const InspectionForm: React.FC = (props: any) => {
  const searchResultText = 'Please Search To Populate Results'
  const columns = [
    { field: 'fileName', title: 'File Name', type: 'string', allowHiding: false, link: 'file' },
    { field: 'title', title: 'Title', type: 'string', allowHiding: true },
    { field: 'createdOn', title: 'Created', width: 160, type: 'datetime', allowHiding: true },
    { field: 'fileURL', title: 'File Url', width: 160, type: 'string', allowHiding: true },
    { field: 'createdBy', title: 'Created By', width: 160, type: 'string', allowHiding: true }
  ]
  const [loading, setLoading] = useState(false)
  const [items, setItems] = useState<any[]>([])
  const [callAfterInit, setCallAfterInit] = useState(true)
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [FormItems, setFormItems] = useState([] as any[])
  const [userItems, setUserItems] = useState([] as any[])
  const [allFormItems, setAllFormItems] = useState([] as any[])
  const [allUserItems, setAllUserItems] = useState([] as any[])
  const [resultText, setResultText] = useState(searchResultText)

  const [state, setState] = useState({
    dateCreatedTo: '',
    dateCreatedfrom: '',
    form: { id: '', text: '' },
    title: '',
    user: { id: '', text: '' }
  })

  useEffect(() => {
    if (!props.Department) {
      window.open('#/AccessRequest', '_self')
    }
    _getFormData()
    _getUserData()
    const tempstate = localStorage.getItem('inspectionDatastate')
    if (tempstate) {
      const allState = JSON.parse(tempstate)
      setState(prevState => ({
        ...prevState,
        dateCreatedTo: allState.dateCreatedTo || '',
        dateCreatedfrom: allState.dateCreatedfrom || '',
        form: allState.form || '',
        title: allState.title || '',
        user: allState.user || ''
      }))
    }
  }, [])

  const handleValueChange = (event: any, fieldName: string) => {
    setState(prevState => ({
      ...prevState,
      [fieldName]: event.value
    }))
  }

  const handleDropdownChange = (e: any, fieldName: string) => {
    setState(prevState => ({
      ...prevState,
      [fieldName]: e.selectedItem
    }))
  }

  const clearFilter = () => {
    setState(prevState => ({
      ...prevState,
      dateCreatedTo: '',
      dateCreatedfrom: '',
      form: { id: '', text: '' },
      title: '',
      user: { id: '', text: '' }
    }))
    localStorage.setItem('inspectionDatastate', '')
    const datagrid = localStorage.getItem('datagridFilterValue')
    if (datagrid) {
      localStorage.removeItem('datagridFilterValue')
    }
  }

  const _getFormData = () => {
    utility
      .genericGetAPICall(Config.FormsListName)
      .then((data: any) => {
        const FormItems = data.map((r: any) => ({ id: r.id, text: r.title }))
        const sortedItems = utility._sortItems(FormItems, 'text', false)
        setFormItems(sortedItems)
        setAllFormItems(sortedItems)
      })
      .catch(err => console.log(err))
  }

  const _getUserData = () => {
    utility
      .genericGetAPICall(Config.employeelistname)
      .then((data: any) => {
        const UserItems = data.map((r: any) => ({ id: r.id, text: r.full_name }))
        const sortedItems = utility._sortItems(UserItems, 'text', false)
        setUserItems(sortedItems)
        setAllUserItems(sortedItems)
      })
      .catch(err => console.log(err))
  }

  const getInspectionFormwithcriteria = () => {
    let criteriaSelected = false
    const dataToPassToService: any = {}

    if (state.title) {
      dataToPassToService.title = state.title
      criteriaSelected = true
    }

    if (state.form && state.form.id !== '') {
      dataToPassToService.form_id = [state.form.id]
      criteriaSelected = true
    }
    if (state.user && state.user.id !== '') {
      dataToPassToService.userId = [state.user.id]
      criteriaSelected = true
    }
    if (state.dateCreatedTo && state.dateCreatedfrom) {
      const dcreatedfrom = new Date(state.dateCreatedfrom)
      const dcreatedTo = new Date(state.dateCreatedTo)
      if (dcreatedTo.getTime() < dcreatedfrom.getTime()) {
        setAlertMessage('Date To should be greater than Date From');
        setShowAlert(true);
        return
      }
    }

    if (state.dateCreatedfrom || state.dateCreatedTo) {
      if (state.dateCreatedfrom) {
        const startOfDay = moment(state.dateCreatedfrom).startOf('day').utc().toISOString()
        dataToPassToService.created_from = startOfDay
      }
      if (state.dateCreatedTo) {
        const endOfDay = moment(state.dateCreatedTo).endOf('day').utc().toISOString()
        dataToPassToService.created_to = endOfDay
      }
      criteriaSelected = true
    }

    if (!criteriaSelected) {
      setAlertMessage('Please select search criteria');
      setShowAlert(true);
      return
    }

    setState(prevState => ({
      ...prevState,
      loading: true
    }))

    utility
      .genericPostAPICall(Config.SearchFormsSubmitted, dataToPassToService)
      .then((data: any) => {
        const items = data
          ? data.map((item: any) => ({
              id: item.id,
              fileName: item.form_name,
              formTitle: item.form_title,
              title: item.title,
              fileURL: item.file_url,
              workorderId: item.workorder_id,
              crewName: item.crew_name,
              userId: item.user_id,
              createdBy: item.submitted_by,
              createdOn: item.created
            }))
          : []

        const sortedItems = utility._sortItems(items, 'createdOn', true);
        setLoading(false);
        setItems(sortedItems);
        setAlertMessage(sortedItems.length > 0 ? '' : 'No results found for this criteria');
        setShowAlert(sortedItems.length <= 0);
  
        localStorage.setItem('inspectionDatastate', JSON.stringify({ ...state, items: sortedItems }))
      })
      .catch(err => {
        console.log(err)
        setState(prevState => ({
          ...prevState,
          loading: false
        }))
      })
  }

  const viewFile = (file: any) => {
    if (!utility.validURL(file.fileURL) || !file.fileName) {
      setAlertMessage('Unable to view this document. \n File URL or File name does exist');
      return
    }
    const dataToPassToService = {
      fileName: file.fileName,
      fileURL: file.fileURL
    }
    utility.genericPostAPIcallForDowloadDoct(Config.dowloadDocumentList, dataToPassToService).then((doc: any) => {
      window.open(doc, '_blank')
    })
  }

  const stopCommsAfterInit = () => {
    setState(prevState => ({
      ...prevState,
      callAfterInit: false
    }))
  }

  const alert = () => {
    return (
      <AlertDialog
        showDialog={showAlert}
        message={alertMessage}
        title={Config.productTitle}
        onClose={() => (setShowAlert(false), setAlertMessage(''))}
      />
    )
  }

  return (
    <>
      {showAlert && alert()}
      <div className='ui-container'>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={6} lg={4}>
            <InputLabel shrink className='label bold'>
              Date From
            </InputLabel>
            <DateBoxInput
              stylingMode='outlined'
              type='date'
              displayFormat='dd/MM/yy'
              showClearButton={true}
              value={state.dateCreatedfrom ? new Date(state.dateCreatedfrom) : null}
              onValueChanged={(e: any) => handleValueChange(e, 'dateCreatedfrom')}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={4}>
            <InputLabel shrink className='label bold'>
              Date To
            </InputLabel>
            <DateBoxInput
              stylingMode='outlined'
              type='date'
              displayFormat='dd/MM/yy'
              showClearButton={true}
              value={state.dateCreatedTo ? new Date(state.dateCreatedTo) : null}
              onValueChanged={(e: any) => handleValueChange(e, 'dateCreatedTo')}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={4}>
            <InputLabel shrink className='label bold'>
              Form
            </InputLabel>
            <SelectBoxInput
              stylingMode='outlined'
              items={FormItems}
              displayExpr='text'
              selectedItem={state.form}
              showClearButton={true}
              onSelectionChanged={(e: any) => handleDropdownChange(e, 'form')}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={4}>
            <InputLabel shrink className='label bold'>
              Title
            </InputLabel>
            <TextBoxInput
              stylingMode='outlined'
              value={state.title}
              showClearButton={true}
              onValueChanged={(e: any) => handleValueChange(e, 'title')}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={4}>
            <InputLabel shrink className='label bold'>
              User
            </InputLabel>
            <SelectBoxInput
              stylingMode='outlined'
              items={userItems}
              displayExpr={'text'}
              selectedItem={state.user}
              showClearButton={true}
              onSelectionChanged={(e: any) => handleDropdownChange(e, 'user')}
            />
          </Grid>
        </Grid>
        <div className='actions-container'>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}></Grid>
            <Grid item xs={12} sm={6}>
              <div className='action-buttons'>
                <Button variant='outlined' color='secondary' onClick={clearFilter}>
                  Clear
                </Button>
                <Button
                  variant='outlined'
                  color='primary'
                  className='primary-button'
                  onClick={getInspectionFormwithcriteria}
                >
                  Search
                </Button>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className=''>
        <h2 className='pl-10'>Results</h2>
        <Typography>{resultText}</Typography>
        {!loading ? (
          <div>
            {items && items.length > 0 && (
              <div className='results-container'>
                <DataTable
                  dataSource={items}
                  style={{ width: '100%' }}
                  filteralbe={true}
                  groupable={true}
                  columns={columns}
                  fileName={'InspectionForms'}
                  columnChooser={'nspectionFormColumns'}
                  showHideColumnMenu={true}
                  loading={loading}
                  allowExportSelectedData={false}
                  columnHidingEnabled={true}
                  showNewBtn={false}
                  showCommandBtn={false}
                  showViewDetailBtn={false}
                />
              </div>
            )}
          </div>
        ) : (
          <Spinner size='large' className='loader' />
        )}
      </div>
    </>
  )
}

export default InspectionForm
