import React, { useState, useEffect, useRef } from 'react'

import utility from '../../../../classes/utility'
import { Config } from '../../../../classes/config'
import DataTable from '../../../Common/CustomASDataGrid'
import { useLocation } from 'react-router-dom';
import SelectBoxInput from '../../../Common/ReusableComponents/SelectInputBox'
import MultiSelect from '../../../Common/ReusableComponents/MultiSelectBox'
import TextBoxInput from '../../../Common/ReusableComponents/TextBox'
import NumberBoxInput from '../../../Common/ReusableComponents/NumberBoxInput'
import CheckBoxInput from '../../../Common/ReusableComponents/CheckBox'
import Spinner from '../../../Common/ReusableComponents/Spinner'
import DateBoxInput from '../../../Common/ReusableComponents/DateBox'
import AssetMap from '../../Assets/AssetMap'
import DataSource from 'devextreme/data/data_source'
import { TagBox } from 'devextreme-react/tag-box'
import { Text } from 'devextreme-react/linear-gauge'
import DisplayDetailsModal from '../../../Common/DisplayDetailModal/DisplayDetailModal'
import AlertDialog from '../../../Common/AlertDialog'
import ButtonGroupItem from '../../../Common/ReusableComponents/ButtonGroup'

//import '../../css/Workorder.css';

//MUI Imports
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import 'devextreme/dist/css/dx.light.css'
import Typography from '@mui/material/Typography'
import RadioGroup from '@mui/material/RadioGroup'
import FormControl from '@mui/material/FormControl'
import Radio, { RadioProps } from '@mui/material/Radio'
import FormControlLabel from '@mui/material/FormControlLabel'
import InputLabel from '@mui/material/InputLabel'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import TabPanel from '../../../Common/TabPanel'
import { set } from 'react-hook-form/dist/utils';

const selectedtab: any = 0
const searchResultsText = 'Please Search To Populate Results'

const AssetTypes = [
  { id: 'Asset', text: 'Asset' },
  { id: 'Sewer Main', text: 'Sewer Main' },
  { id: 'Water Main', text: 'Water Main' }
]

export const totalResultsList = [
  { id: 1, text: '100' },
  { id: 2, text: '200' },
  { id: 3, text: '300' },
  { id: 4, text: '500' },
  { id: 5, text: '1000' }
]

const _columns = [
  {
    field: 'WorkorderID',
    title: 'Work Order #',
    type: 'string',
    allowHiding: false,
    link: 'workorderLink'
  },
  {
    field: 'title',
    title: 'Description',
    // width: 160,
    type: 'string',
    allowHiding: true
  },
  {
    field: 'worktype',
    title: 'Activity',
    // width: 160,
    type: 'string',
    allowHiding: true
  },
  {
    field: 'priority',
    title: 'Priority',
    // width: 110,
    type: 'string',
    allowHiding: true
  },
  {
    field: 'crew',
    title: 'Crew',
    // width: 160,
    type: 'string',
    allowHiding: true
  },
  {
    field: 'department',
    title: 'Department',
    // width: 160,
    type: 'string',
    allowHiding: true
  },
  {
    field: 'fieldstatus',
    title: 'Status',
    // width: 120,
    type: 'string',
    allowHiding: true
  },
  {
    field: 'workrequest',
    title: 'Work Req #',
    // width: 160,
    type: 'string',
    allowHiding: true
  },
  {
    field: 'AssetType',
    title: 'Asset Type',
    // width: 180,
    type: 'string',
    allowHiding: true
  },
  {
    field: 'Scope',
    title: 'Scope',
    // width: 160,
    type: 'string',
    visibleColumn: false,
    allowHiding: true
  },
  {
    field: 'workorderLocation',
    title: 'Location',
    // width: 160,
    type: 'string',
    allowHiding: true
  },

  {
    field: 'eststartdate',
    title: 'Scheduled Date',
    // width: 180,
    type: 'datetime',
    allowHiding: true
  },
  {
    field: 'Datereported',
    title: 'Reported Date',
    // width: 160,
    type: 'datetime',
    visibleColumn: false,
    allowHiding: true
  },
  {
    field: 'Datecompleted',
    title: 'Completed Date',
    // width: 160,
    type: 'datetime',
    visibleColumn: false,
    allowHiding: true
  },
  {
    field: 'updatedOn',
    title: 'Updated On',
    // width: 160,
    type: 'datetime',
    visibleColumn: false,
    allowHiding: true
  },
  {
    field: 'updated',
    title: 'Updated By',
    // width: 160,
    type: 'string',
    visibleColumn: false,
    allowHiding: true
  },
  {
    field: 'createdOn',
    title: 'Created On',
    // width: 160,
    type: 'datetime',
    visibleColumn: false,
    allowHiding: true
  },
  {
    field: 'created',
    title: 'Created By',
    // width: 160,
    type: 'string',
    visibleColumn: false,
    allowHiding: true
  }
]

interface DataToPassToService {
  showAll?: boolean
  id?: number[]
  workorder_type_id?: string[]
  workorder_location?: string[]
  work_request_id?: string
  workorder_status_id?: string[]
  //workorder_type_id?: string[]
  crew_id?: number[]
  team_id?: number[]
  department_id?: number[]
  erp_workorder_number?: string
  assetnumber?: string
  priority_id?: number[]
  asset_id?: string
  date_scheduled_start?: string
  date_scheduled_end?: string
  date_started?: string
  date_completed?: string
  dateReportedFrom?: string
  dateReportedTo?: string
}

interface StateComponentProps {
  id?: number
  text?: string
}

interface CrewComponentProps {
  id?: number
  title?: string
  show_on_scheduler?: boolean
  is_active?: boolean
}

interface StateComponent {
  department: StateComponentProps
  team: StateComponentProps
  crew: CrewComponentProps
  [key: string]: any
  totalResults: number
}

interface SearchWOProps {
  onClick?: (e: any) => any
  selectMode?: any
  location?: any
  selectCreateScheduleMode?: any
  selectScheduleMode?: any
  heading?: boolean
  hideAssetMapButton?: any
  myhttpclient?: any
  aadHttpClient?: any
  cacheLifecycles?: any
  selectItem?: ((arg1: any) => void) | null
}

interface AuthProps {
  role?: any
  Department?: any
  currentUser?:any
  pageListItems?:any
  isContractor?:any
  team?:any
}

const Searchworkorder = (props: SearchWOProps & AuthProps) => {
  const location = useLocation();
  const selectedStatus = location.state?.selectedStatus || '';
  const [suburbItems, setSuburbItems] = useState<any[]>([])
  const [suburbdataSource, setSuburbDataSource] = useState<any[]>([])
  const [crewItems, setCrewItems] = useState<StateComponentProps[]>([])
  const [workTypeItems, setWorkTypeItems] = useState<DataSource<any, any> | any[]>([])
  const [allWorkTypeItems, setAllWorkTypeItems] = useState<DataSource<any, any> | any[]>([])
  const [priorityItems, setPriorityItems] = useState<DataSource<any, any> | any[]>([])
  const [allPriorityItems, setAllPriorityItems] = useState<DataSource<any, any> | any[]>([])
  const [tempCrewItems, setTempCrewItems] = useState<any[]>([])
  const [criteriaval, setCriteriaVal] = useState('')
  const [StatusItems, setStatusItems] = useState<any[]>([])
  const [AssetType, setAssetType] = useState<any[]>([])
  const [departmentItems, setDepartmentItems] = useState<StateComponentProps[]>([])
  const [statusDataSource, setStatusDataSource] = useState<any[]>([])
  const [prioritydataSource, setPriorityDataSource] = useState([])
  const [worktypedataSource, setWorkTypeDataSource] = useState([])
  const [teamItems, setTeamItems] = useState<any[]>([])
  const [tempTeamItems, setTempTeamItems] = useState<any[]>([])
  const [assetTypes, setAssetTypes] = useState(AssetTypes)
  const [allAssetTypes, setAllAssetTypes] = useState(AssetTypes)
  const [assetNumber, setAssetNumber] = useState('')
  const myRef = useRef<HTMLDivElement>(null)
  const [loadingFields, setLoadingFields] = useState(true)
  const [erpWONumber, setErpWONumber] = useState('')
  const [workorderno, setWorkorderno] = useState('')
  const [worktype, setWorktype] = useState<any[]>([])
  const [priority, setPriority] = useState<any[]>([])
  const [woStatus, setWoStatus] = useState<any[]>([])
  const [allStatusItems, setAllStatusItems] = useState<any[]>([])
  const [WOLocation, setWOLocation] = useState('')
  const [assetTypeItem, setAssetTypeItem] = useState('')
  const [assetId, setAssetId] = useState('')
  const [unitId1, setUnitId1] = useState('')
  const [unitId2, setUnitId2] = useState('')
  const [dateCreatedFrom, setDateCreatedFrom] = useState<Date | null>(null)
  const [dateCreatedTo, setDateCreatedTo] = useState<Date | null>(null)
  const [reportFrom, setReportFrom] = useState<Date | null>(null)
  const [reportTo, setReportTo] = useState<Date | null>(null)
  const [completedDateFrom, setCompletedDateFrom] = useState<Date | null>(null)
  const [completedDateTo, setCompletedDateTo] = useState<Date | null>(null)
  const [isStateFromPropsUpdated, setIsStateFromPropsUpdated] = useState(false);

  //const [crew, setCrew] = useState<Crew | null>(null)
  //const [department, setDepartment] = useState<StateComponentProps | null>(null)
  //const [teams, setTeams] = useState<Team | null>(null)
  const [workReqNo, setWorkReqNo] = useState('')
  const [totalRecords, setTotalRecords] = useState('')

  const [showAllWO, setShowAllWO] = useState(false)
  const [items, setItems] = useState([])
  const [columns, setColumns] = useState(_columns)

  const [loading, setLoading] = useState(false)
  const [reasonToAttendsItems, setReasonToAttendsItems] = useState<any[]>([])
  const [scopesItems, setScopesItems] = useState<any[]>([])

  //const [totalResults, setTotalResults] = useState(+totalResultsList[0].text)
  const [expanded, setExpanded] = useState('panel1')
  const [disabledTeam, setDisabledTeam] = useState(true)
  const [disabledCrew, setDisabledCrew] = useState(true)

  const [details, setDetails] = useState<any>(null)
  const [showModal, setShowModal] = useState(false)
  const [contStatus, setContStatus] = useState([])
  const [suburb, setSuburb] = useState('')
  const [employee, setEmployee] = useState('')
  const [actdateCreatedfrom, setActdateCreatedfrom] = useState('')
  const [actdateCreatedTo, setActdateCreatedTo] = useState('')
  const [scope, setScope] = useState('')
  const [reasonToAttend, setReasonToAttend] = useState('')
  const [saleForceNo, setSaleForceNo] = useState('')
  const [contractorWo, setContractorWo] = useState('')

  const [iFramePanelOpened, setIFramePanelOpened] = useState(false)
  const [iFramePanelOpenedForDetail, setIFramePanelOpenedForDetail] = useState(false)
  const [selectParent, setSelectParent] = useState(true)
  const [selectModeMap, setSelectModeMap] = useState(false)
  const [parentId, setParentId] = useState(null)
  const [parentDetail, setParentDetail] = useState(null)
  const [selectMapDetail, setSelectMapDetail] = useState(false)
  const [callAfterInit, setCallAfterInit] = useState(false)
  const [fieldValue, setFieldValue] = useState('')
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [resultText, setResultText] = useState(searchResultsText)
  const [activeRadioTab, setActiveRadioTab] = useState(0)
  const [isDashboardPage, setIsDashboardPage] = useState(false);
  const [isWoStatusSet, setIsWoStatusSet] = useState(false);
  const [state, setState] = useState<StateComponent>({
    department: {},
    team: {},
    crew: {},
    totalResults: +totalResultsList[0].text
  })

  useEffect(() => {
    if (!props.Department) {
      window.open('#/AccessRequest', '_self')
    }
  }, [props.Department])

  useEffect(() => {
    if (props.cacheLifecycles) {
      props.cacheLifecycles.didCache(componentDidCache)
      props.cacheLifecycles.didRecover(componentDidRecover)
    }
  }, [props.cacheLifecycles])

  const componentDidCache = () => {
    // Handle component caching logic here
  }

  const componentDidRecover = () => {
    // Handle component recovery logic here
  }


  useEffect(() => {
    const search = props.location?.state;
    console.log('woProps', search)
    const searchFromDashboardProps = location.state?.searchFromDashboardProps;
    if (search) {
      setState(prevState => ({
        ...prevState,
        department: search.department,
        crew: search.crew,
      }))
      setDateCreatedFrom(search.range?.startDate ? new Date(search.range?.startDate) : null)
      setDateCreatedTo(search.range?.endDate ? new Date(search.range?.endDate) : null)
      setIsStateFromPropsUpdated(true);
    }

    if (searchFromDashboardProps && searchFromDashboardProps.woStatus) {
      console.log('searchFromDashboardProps', searchFromDashboardProps.woStatus)
      setWoStatus([searchFromDashboardProps.woStatus.id]);
      setIsDashboardPage(true);
    }else {
      setIsDashboardPage(false);
    }

    setLoadingFields(true)
    const tempstate = localStorage.getItem('woState')
    const fetchData = async () => {
      try {
        // await _getsuburbDataAsync(),
        // await _getEmployeeDataAsync(),
        await _getCrewDataAsync()
          await _getworkTypeDataAsync()
          await _getStatusDataAsync()
          //await _getAssetTypeData(),
          await _getTeamDataAsync()
          await _getDepartmentData()
          await _getPrioritiesData()

        console.log('---all async/await done---')
        if (tempstate && !search) {
          const allState = JSON.parse(tempstate)
          setState(prevState => ({
            ...prevState,
            erpWONumber: allState.erpWONumber ? allState.erpWONumber : ''
          }))
          setWorkorderno(allState.workorderno ? allState.workorderno : '')
          setWorktype(allState.worktype ? getMultiselectProps(allState.worktype, 'worktypeItems') : [])
          setPriority(allState.priority ? getMultiselectProps(allState.priority, 'priorityItems') : [])
          //setWoStatus(allState.worktype ? getMultiselectProps(allState.wostatus, 'StatusItems') : [])
          setWOLocation(allState.WOLocation ? allState.WOLocation : '')
          setAssetTypeItem(allState.assetTypeItem ? allState.assetTypeItem : '')
          setAssetId(allState.assetId ? allState.assetId : '')
          setUnitId1(allState.unitId1 ? allState.unitId1 : '')
          setUnitId2(allState.unitId2 ? allState.unitId2 : '')
          setDateCreatedFrom(allState.dateCreatedfrom ? new Date(allState.dateCreatedfrom) : null)
          setDateCreatedTo(allState.dateCreatedTo ? new Date(allState.dateCreatedTo) : null)
          setReportFrom(allState.reportfrom ? new Date(allState.reportfrom) : null)
          setReportTo(allState.reportTo ? new Date(allState.reportTo) : null)
          setCompletedDateFrom(allState.completedDateFrom ? new Date(allState.completedDateFrom) : null)
          setCompletedDateTo(allState.completeddateTo ? new Date(allState.completeddateTo) : null)
          setState(prevState => ({
            ...prevState,
            crew: allState.crew ? allState.crew : {}
          }))
          setState(prevState => ({
            ...prevState,
            department: allState.department ? getMultiselectProps(allState.department, 'departmentItems') : {}
          }))
          setState(prevState => ({
            ...prevState,
            team: allState.team ? allState.team : {}
          }))
          setState(prevState => ({
            ...prevState,
            workReqNo: allState.workReqNo ? allState.workReqNo : ''
          }))
          setExpanded(allState.expanded)
          setTotalRecords(allState.totalRecords ? allState.totalRecords : '')
          setLoadingFields(false)
        } else {
          setLoadingFields(false)
        }
      } catch (error) {
        console.error('Error:', error)
      }
    }

    fetchData()
    
  }, [location])

  useEffect(() => {
    if (isStateFromPropsUpdated) {
      getWorkOrder();
      setIsStateFromPropsUpdated(false); // Reset flag to avoid retriggering
    }
  }, [isStateFromPropsUpdated]);

  useEffect(() => {
    if (woStatus.length > 0) {
      setIsWoStatusSet(true);
    }
  }, [woStatus]);

  useEffect(() => {
    if (isDashboardPage && isWoStatusSet) {
      getWorkOrder(); // Call getWorkOrder only after woStatus is set and page is from dashboard
    }
  }, [isDashboardPage, isWoStatusSet]);


  const getMultiselectProps = (data: any, listData: any) => {
    const returnData: Array<any> = []
    if (listData && data) {
      data.forEach((item: any) => {
        const tempItem = listData.find((l: any) => l.id === item.id)
        returnData.push(tempItem)
      })
    }

    return returnData
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      getWorkOrder()
    }
  }

  const clearFilter = () => {
    setItems([])
    setWorkorderno('')
    setWorkReqNo('')
    setAssetNumber('')
    setState(prevState => ({ ...prevState, erpWONumber: '' }))
    setWoStatus([])
    setContStatus([])
    setPriority([])
    setState(prevState => ({ ...prevState, crew: {} }))
    setState(prevState => ({ ...prevState, department: {} }))
    setState(prevState => ({ ...prevState, team: {} }))
    setWorktype([])
    setWOLocation('')
    setSuburb('')
    setState(prevState => ({ ...prevState, workReqNo: '' }))
    setEmployee('')
    setDateCreatedFrom(null)
    setDateCreatedTo(null)
    setActdateCreatedfrom('')
    setActdateCreatedTo('')
    setCompletedDateFrom(null)
    setCompletedDateTo(null)
    setReportFrom(null)
    setReportTo(null)
    setScope('')
    setReasonToAttend('')
    setAssetTypeItem('')
    setAssetId('')
    setUnitId1('')
    setUnitId2('')
    setSaleForceNo('')
    setContractorWo('')
    setLoading(false)
    setState(prevState => ({ ...prevState, totalResults: +totalResultsList[0].text }))

    localStorage.setItem('woState', '')
    localStorage.removeItem('datagridFilterValue')
  }

  const viewDetails = (item: any) => {
    setDetails(item)
    setShowModal(true)
  }

  const _getCrewDataAsync = () => {
    const apiName = `${Config.crewlistname}`

    utility
      .genericGetAPICall(apiName)
      .then((data: any) => {
        //console.log(data);
        const crewItemsData = []

        for (const r of data) {
          crewItemsData.push({ id: r.id, text: r.title, team_id: r.team_id, email: r.email })
        }
        const sortedCrewItems = utility._sortItems(crewItemsData, 'text', false)

        setCrewItems(sortedCrewItems)
        console.log(' crew items in searchWO' + crewItems + 'or' + sortedCrewItems)
        setTempCrewItems(sortedCrewItems)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const _getworkTypeDataAsync = () => {
    const apiName = `${Config.WTlistname}`
    utility
      .genericGetAPICall(apiName)
      .then((data: any) => {
        const worktypeItems = data.map((r: any) => ({
          id: r.id,
          text: r.title,
          selected: true,
          'Select All': r.title
        }))

        const worktypedataSource = new DataSource({
          store: worktypeItems,
          key: 'id'
        })

        setWorkTypeItems(worktypedataSource)
        setAllWorkTypeItems(worktypedataSource)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const _getStatusDataAsync = () => {
    const apiName = `${Config.statuslist}`

    utility
      .genericGetAPICall(apiName)
      .then((data: any) => {
        //const selectedStatus: any = []
        const filteredItems = data
          .filter((r: any) => r.is_active)
          .map((r: any) => ({ id: r.id, text: r.title, 'Select All': r.title }))

        const sortedItems = utility._sortItems(filteredItems, 'text', false)
        const statusDataSource = sortedItems

        setStatusItems(statusDataSource)
        setAllStatusItems(statusDataSource)
        // setWoStatus(woStatus ? woStatus : [])
      })
      .catch(err => {
        console.log(err)
      })
  }

  const _getTeamDataAsync = () => {
    const apiName = `${Config.TeamList}`

    utility
      .genericGetAPICall(apiName)
      .then((data: any) => {
        const teamsData = data.map((r: any) => ({ id: r.id, text: r.title, department_id: r.department_id }))

        const sortedTeamsData = utility._sortItems(teamsData, 'text', false)
        setTeamItems(sortedTeamsData)
        setTempTeamItems(sortedTeamsData)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const _getDepartmentData = () => {
    const apiName = `${Config.DepartmentList}`

    utility
      .genericGetAPICall(apiName)
      .then((data: any) => {
        const departmentItemsData = data.map((r: any) => ({ id: r.id, text: r.title }))
        const sortedDepartmentItems = utility._sortItems(departmentItemsData, 'text', false)

        setDepartmentItems(sortedDepartmentItems)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const _getPrioritiesData = () => {
    const apiName = `${Config.PriorityListName}`
    utility
      .genericGetAPICall(apiName)
      .then((data: any) => {
        const selectedStatus: any = []
        const filteredItems = data
          .filter((r: any) => r.is_active)
          .map((r: any) => ({ id: r.id, text: r.title, 'Select All': r.title }))

        const sortedItems = utility._sortItems(filteredItems, 'text', false)
        const priorityDataSource = new DataSource({
          store: sortedItems,
          key: 'id'
        })

        setPriorityItems(priorityDataSource)
        setAllPriorityItems(priorityDataSource)
        //setWoStatus(selectedStatus)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const onDismissMapforDetail = () => {
    setIFramePanelOpenedForDetail(false)
  }

  const searchClicked = () => {
    setIFramePanelOpened(true)
    setSelectModeMap(false)
    setSelectParent(true)
  }

  const onDismissMap = () => {
    setIFramePanelOpened(false)
  }

  const renderAssetMap = () => {
    return (
      <AssetMap
        selectAssetsforParent={(Asset: any[]) => selectAssetsonMapforParent(Asset)}
        iFramePanelOpened={iFramePanelOpened}
        onClose={onDismissMap}
        selectParent={selectParent}
        selectModeMap={selectModeMap}
      />
    )
  }

  const selectAssetsonMapforParent = (selectedAsset: any[]) => {
    const Asset = selectedAsset[0]
    setParentId(Asset.id)
    setParentDetail(Asset.name)
    setIFramePanelOpened(false)
    setSelectModeMap(false)
    setSelectParent(false)
    setSelectMapDetail(false)
  }

  const getWorkOrder = async () => {
    let criteriaSelected = false
    setResultText('')

    const dataToPassToService: DataToPassToService = {
      //showAll: showAllWO
    }

    if (workorderno) {
      dataToPassToService.id = [+workorderno]
      criteriaSelected = true
    }
    if (WOLocation) {
      dataToPassToService.workorder_location = [WOLocation]
      criteriaSelected = true
    }

    if (workReqNo) {
      dataToPassToService.work_request_id = state.workReqNo
      criteriaSelected = true
    }
    if (woStatus) {
      // Ensure woStatus is an array
      const tempWoStatus: any[] = Array.isArray(woStatus) ? woStatus : [woStatus];
      console.log('woStatus', woStatus)
      // Use the array to populate dataToPassToService
      if (tempWoStatus.length > 0) {
        dataToPassToService.workorder_status_id = tempWoStatus;
        criteriaSelected = true;
      }
    }
    if (worktype && worktype.length > 0) {
      const tempworktype: any = []
      worktype.forEach((element: any) => {
        tempworktype.push(element)
      })
      dataToPassToService.workorder_type_id = tempworktype
      criteriaSelected = true
    }

    if (state.crew && Object.keys(state.crew).length !== 0) {
      const crewIdData: any = []
      crewIdData.push(state.crew.id)
      dataToPassToService.crew_id = crewIdData
      criteriaSelected = true
    }

    if (state.team && Object.keys(state.team).length !== 0) {
      const teamIdData: any = []
      teamIdData.push(state.team.id)
      dataToPassToService.team_id = teamIdData
      criteriaSelected = true
    }

    if (state.department && Object.keys(state.department).length !== 0) {
      const deptIdData: any = []
      deptIdData.push(state.department.id)
      dataToPassToService.department_id = deptIdData
      criteriaSelected = true
    }
    // if (erpWONumber) {
    //   dataToPassToService.erp_workorder_number = erpWONumber
    //   criteriaSelected = true
    // }
    if (priority && priority.length > 0) {
      const temppriority: any = []
      priority.forEach((element: any) => {
        temppriority.push(element)
      })
      dataToPassToService.priority_id = temppriority
      criteriaSelected = true
    }
    if (assetNumber) {
      dataToPassToService.asset_id = assetNumber
      criteriaSelected = true
    }

    if (dateCreatedTo && dateCreatedFrom) {
      const dcreatedfrom = new Date(dateCreatedFrom)
      const dcreatedTo = new Date(dateCreatedTo)
      if (dcreatedTo.getTime() < dcreatedfrom.getTime()) {
        setAlertMessage('Scheduled Start Date To should be greater than Scheduled Start Date From')
        setShowAlert(true)

        return
      }
    }

    if (dateCreatedFrom || dateCreatedTo) {
      let createddatefrom
      let createdTo
      if (dateCreatedFrom) {
        createddatefrom = new Date(dateCreatedFrom)
        createddatefrom.setHours(0, 0, 0, 0)
        dataToPassToService.date_scheduled_start = createddatefrom.toISOString()
      }
      if (dateCreatedTo) {
        const extraday = new Date(dateCreatedTo)
        extraday.setDate(extraday.getDate() + 1)
        createdTo = extraday.toISOString().substring(0, 10) + 'T13:59:59.000Z'
        dataToPassToService.date_scheduled_end = createdTo
      }
      criteriaSelected = true
    }

    //completed
    if (completedDateFrom && completedDateTo) {
      const dcompletedfrom = new Date(completedDateFrom)
      const dcompletedTo = new Date(completedDateTo)
      if (dcompletedTo.getTime() < dcompletedfrom.getTime()) {
        setAlertMessage('Completed Date To should be greater than Completed Date From')
        setShowAlert(true)

        return
      }
    }
    if (completedDateFrom || completedDateTo) {
      let dfrom
      let dTo
      if (completedDateFrom) {
        dfrom = new Date(completedDateFrom)
        dfrom.setHours(0, 0, 0, 0)
        dataToPassToService.date_started = dfrom.toISOString()
      }
      if (completedDateTo) {
        const extradaytocompleted = new Date(completedDateTo)
        extradaytocompleted.setDate(extradaytocompleted.getDate() + 1)
        dTo = extradaytocompleted.toISOString().substring(0, 10) + 'T13:59:59.000Z'

        // dTo = CompleteddateTo.toISOString();
        dataToPassToService.date_started = dTo
      }
      criteriaSelected = true
    }

    if (reportFrom && reportTo) {
      setReportFrom(new Date(reportFrom))
      setReportTo(new Date(reportTo))
      if (reportTo.getTime() < reportFrom.getTime()) {
        setAlertMessage('Reported Date To should be greater than Reported Date From')
        setShowAlert(true)

        return
      }
    }
    if (reportFrom || reportTo) {
      let reportfrom
      let reportTo
      if (reportfrom) {
        reportfrom = new Date(reportfrom)
        reportfrom.setHours(0, 0, 0, 0)
        dataToPassToService.dateReportedFrom = reportfrom.toISOString()
      }
      if (reportTo) {
        const extradaytoreportTodate = new Date(reportTo)
        extradaytoreportTodate.setDate(extradaytoreportTodate.getDate() + 1)
        reportTo = extradaytoreportTodate.toISOString().substring(0, 10) + 'T13:59:59.000Z'

        // actualcreatedTo = actdateCreatedTo.toISOString();
        dataToPassToService.dateReportedTo = reportTo
      }
      criteriaSelected = true
    }

      if (!criteriaSelected) {
        setAlertMessage('Please select search criteria')
        setShowAlert(true)
        return
      }
    
    setCallAfterInit(true)
    setLoading(true)
    const skip = 0
    const limit = !showAllWO ? `limit=${state.totalResults}` : ``
    const searchWOAPI = `${Config.WOSearchAPI}?skip=${skip}&${limit}`
    try {
      const data = await utility.genericPostAPICall(searchWOAPI, dataToPassToService)

      let _items: any = []
      if (data.length === 0) {
        if (_items.length <= 0) {
          setAlertMessage('No results found for this criteria')
          setShowAlert(true)
        }
        setItems(_items)
        setLoading(false)

        return
      } else {
        _items = data.map((item: any) => {
          return {
            id: item.id,
            woid: item.id,
            assetIds: item.asset_ids,
            workrequest: item.work_request_id && item.work_request_id > 1 ? item.work_request_id : '',
            WorkorderID: item.id,
            erpWorkorderNumber: item.erp_workorder_number,
            title: item.title,
            priority: item.priority ? item.priority.title : '',
            worktype: item.workorder_type.title,
            crew: item.crew ? item.crew.title : '',
            department: item.department ? item.department.title : '',
            latlong: item.lat_long,
            createdOn: item.created,
            Datecompleted: item.dateCompleted ? item.dateCompleted : null,
            eststartdate: item.date_scheduled_start ? item.date_scheduled_start : null,
            fieldstatus: item.workorder_status.title,
            suburb: item.suburb_id ? item.suburb_id : '',
            workorderLocation: item.workorder_location ? item.workorder_location : '',
            AssetType: item.assets_info ? item.assets_info.AssetType : '',
            updated: item.updated_by,
            updatedOn: item.updated,
            created: item.created_by,
            createOn: item.created,
          }
        })
      }
      if (_items.length === 1 && !props.selectMode) {
        window.open(`#/WorkOrderDetail?WOID=${_items[0].woid}`, '_self')
      }
      if (_items.length <= 0) {
        setAlertMessage('No results found for this criteria')
        setShowAlert(true)
      }
      _items = utility._sortItems(_items, 'createdOn', true)
      setItems(_items)
      setLoading(false)

      //setTotalResults(data.TotalRecords)
      if (myRef.current) {
        window.scrollTo(0, myRef.current.offsetTop)
      }
    } catch (err: any) {
      console.log(err)
      setItems(items)
      setLoading(false)
      setAlertMessage('Something went wrong!')
      setShowAlert(true)
    }
  }

  const stopCommsAfterInit = () => {
    setCallAfterInit(false)
  }

  const handleValueChange = (event: any, fieldName: string) => {
    if (fieldName === 'totalResults') {
      setState(prevState => ({
        ...prevState,
        [fieldName]: +event.itemData.text
      }))
    } else {
      setState(prevState => ({
        ...prevState,
        [fieldName]: event.value
      }))

      switch (fieldName) {
        case 'workorderno':
          setWorkorderno(event.value)
          break
        case 'workReqNo':
          setWorkReqNo(event.value)
          break
        case 'WOLocation':
          setWOLocation(event.value)
          break
        case 'assetNumber':
          setAssetNumber(event.value)
          break
        case 'dateCreatedFrom':
          setDateCreatedFrom(event.value)
          break
        case 'reportFrom':
          setReportFrom(event.value)
          break
        case 'completedDateFrom':
          setCompletedDateFrom(event.value)
          break
        case 'dateCreatedTo':
          setDateCreatedTo(event.value)
          break
        case 'completedDateTo':
          setCompletedDateTo(event.value)
          break
        case 'reportTo':
          setReportTo(event.value)
          break
        case 'totalResults':
          setState(prevState => ({
            ...prevState,
            [fieldName]: +event.itemData.text
          }))
          break
        case 'showAllWO':
          setShowAllWO(true)
          break
        default:
          break
      }
    }

    //setFieldValue(newValue.value)
    // set other field values using their respective useState setters
  }

  useEffect(() => {
    if (state.department && Object.keys(state.department).length !== 0) {
      const tempTeams = teamItems
      const filteredTeam = tempTeams.filter((t: any) => t.department_id === state.department.id)

      setTempTeamItems(filteredTeam)
      setTempCrewItems([])
      setDisabledTeam(false)
      setDisabledCrew(true)
    }

    if (state.team && Object.keys(state.team).length !== 0) {
      const tempCrew = crewItems
      const filteredCrew: any[] = []
      tempCrew.forEach((c: any) => {
        if (c.team_id === state.team.id || c.title === 'Default Crew') {
          filteredCrew.push(c)
        }
      })
      setTempCrewItems(filteredCrew)
      setDisabledCrew(false)
    }

    // if (assetTypeItem) {
    //   setAssetId('')
    //   setUnitId1('')
    //   setUnitId2('')
    // }
    
  }, [state])

  const handleDropdownChange = (e: any, fieldName: string) => {
    const chosenValue = e.selectedItem
    if (chosenValue !== state[fieldName]) {
      setState(prevState => ({
        ...prevState,
        [fieldName]: chosenValue
      }))
    }
  }

  const _multiFieldChanged = (event: any, fieldName: string) => {
    switch (fieldName) {
      case 'workType':
        setWorktype(event.value)
        break
      case 'woStatus':
        setWoStatus(event.value)
        break
      case 'priority':
        setPriority(event.value)
        break
      default:
        break
    }
  }

  const closeAlert = (): void => {
    setAlertMessage('')
    setShowAlert(false)
  }

  const closeModal = (): void => {
    setShowModal(false)
  }

  const alert = (): JSX.Element | null => {
    if (!showAlert) {
      return null
    }

    return (
      <AlertDialog showDialog={showAlert} message={alertMessage} title={Config.productTitle} onClose={closeAlert} />
    )
  }

  const renderDetailsModal = (): JSX.Element | null => {
    if (!showModal) {
      return null
    }

    return <DisplayDetailsModal details={details} columns={columns} showModal={showModal} onClick={closeModal} />
  }

  const NormalSearchContent = () => {
    return (
      <TabPanel value={activeRadioTab} index={0}>
        <div className={'searchFieldDiv '}>
          <div className='search-fields'>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <InputLabel shrink className='label bold'>
                  Work Order #
                </InputLabel>
                <TextBoxInput
                  stylingMode='outlined'
                  value={workorderno}
                  onValueChanged={(e: any) => handleValueChange(e, 'workorderno')}
                  onKeyDown={(e: any) => handleKeyDown(e)}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <InputLabel shrink className='label bold'>
                  Work Request #
                </InputLabel>
                <TextBoxInput
                  stylingMode='outlined'
                  value={workReqNo}
                  onValueChanged={(e: any) => handleValueChange(e, 'workReqNo')}
                  onKeyDown={(e: any) => handleKeyDown(e)}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <InputLabel shrink className='label bold'>
                  Work Order Location
                </InputLabel>
                <TextBoxInput
                  stylingMode='outlined'
                  value={WOLocation}
                  onValueChanged={(e: any) => handleValueChange(e, 'WOLocation')}
                  onKeyDown={(e: any) => handleKeyDown(e)}
                />
              </Grid>
              {workTypeItems && (
                <Grid item xs={12} sm={6} md={6} lg={4}>
                  <InputLabel shrink className='label bold'>
                    Activity
                  </InputLabel>
                  <TagBox
                    dataSource={workTypeItems}
                    valueExpr='id'
                    value={worktype ? worktype : []}
                    searchEnabled={true}
                    displayExpr='text'
                    multiline={false}
                    showSelectionControls={true}
                    applyValueMode='useButtons'
                    onValueChanged={(e: any) => _multiFieldChanged(e, 'workType')}
                    onKeyDown={(e: any) => handleKeyDown(e)}
                  />
                </Grid>
              )}
              {priorityItems && (
                <Grid item xs={12} sm={6} md={6} lg={4}>
                  <InputLabel shrink className='label bold'>
                    Priority
                  </InputLabel>
                  <TagBox
                    dataSource={priorityItems}
                    valueExpr='id'
                    value={priority ? priority : []}
                    searchEnabled={true}
                    displayExpr='text'
                    multiline={false}
                    showSelectionControls={true}
                    applyValueMode='useButtons'
                    onKeyDown={(e: any) => handleKeyDown(e)}
                    onValueChanged={(e: any) => _multiFieldChanged(e, 'priority')}
                  />
                </Grid>
              )}
              {StatusItems && (
                <Grid item xs={12} sm={6} md={6} lg={4}>
                  <InputLabel shrink className='label bold'>
                    Status
                  </InputLabel>
                  <TagBox
                    dataSource={StatusItems}
                    valueExpr='id'
                    value={woStatus ? woStatus : []}
                    searchEnabled={true}
                    displayExpr='text'
                    onValueChanged={(e: any) => _multiFieldChanged(e, 'woStatus')}
                    multiline={false}
                    showSelectionControls={true}
                    applyValueMode='useButtons'
                    onKeyDown={(e: any) => handleKeyDown(e)}
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <InputLabel shrink className='label bold'>
                  Asset #
                </InputLabel>
                <TextBoxInput
                  stylingMode='outlined'
                  value={assetNumber}
                  onValueChanged={(e: any) => handleValueChange(e, 'assetNumber')}
                  onKeyDown={(e: any) => handleKeyDown(e)}
                />
              </Grid>
            </Grid>
          </div>
        </div>
      </TabPanel>
    )
  }

  const SearchByDatesContent = () => {
    return (
      <TabPanel value={activeRadioTab} index={1}>
        <div className={'searchFieldDiv '}>
          <div className='search-fields'>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <InputLabel shrink className='label bold'>
                  Schedule Date From
                </InputLabel>
                <DateBoxInput
                  stylingMode='outlined'
                  value={dateCreatedFrom ? new Date(dateCreatedFrom) : null}
                  type='date'
                  displayFormat={'dd/MM/yyyy'}
                  placeholder={'DD/MM/YYYY'}
                  showClearButton={true}
                  onValueChanged={(e: any) => handleValueChange(e, 'dateCreatedFrom')}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={4}>
                <InputLabel shrink className='label bold'>
                  Reported Date From
                </InputLabel>
                <DateBoxInput
                  stylingMode='outlined'
                  value={reportFrom ? new Date(reportFrom) : null}
                  type='date'
                  displayFormat={'dd/MM/yyyy'}
                  placeholder={'DD/MM/YYYY'}
                  showClearButton={true}
                  onValueChanged={(e: any) => handleValueChange(e, 'reportFrom')}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <InputLabel shrink className='label bold'>
                  Completed Date From
                </InputLabel>
                <DateBoxInput
                  stylingMode='outlined'
                  value={completedDateFrom ? new Date(completedDateFrom) : null}
                  type='date'
                  displayFormat={'dd/MM/yyyy'}
                  placeholder={'DD/MM/YYYY'}
                  showClearButton={true}
                  onValueChanged={(e: any) => handleValueChange(e, 'completedDateFrom')}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <InputLabel shrink className='label bold'>
                  Schedule Date To
                </InputLabel>
                <DateBoxInput
                  stylingMode='outlined'
                  value={dateCreatedTo ? new Date(dateCreatedTo) : null}
                  type='date'
                  displayFormat={'dd/MM/yyyy'}
                  placeholder={'DD/MM/YYYY'}
                  showClearButton={true}
                  onValueChanged={(e: any) => handleValueChange(e, 'dateCreatedTo')}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={4}>
                <InputLabel shrink className='label bold'>
                  Reported Date To
                </InputLabel>
                <DateBoxInput
                  stylingMode='outlined'
                  value={reportTo ? new Date(reportTo) : null}
                  type='date'
                  displayFormat={'dd/MM/yyyy'}
                  placeholder={'DD/MM/YYYY'}
                  showClearButton={true}
                  onValueChanged={(e: any) => handleValueChange(e, 'reportTo')}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <InputLabel shrink className='label bold'>
                  Completed Date To
                </InputLabel>
                <DateBoxInput
                  stylingMode='outlined'
                  value={completedDateTo ? new Date(completedDateTo) : null}
                  type='date'
                  displayFormat={'dd/MM/yyyy'}
                  placeholder={'DD/MM/YYYY'}
                  showClearButton={true}
                  onValueChanged={(e: any) => handleValueChange(e, 'completedDateTo')}
                />
              </Grid>
            </Grid>
          </div>
        </div>
      </TabPanel>
    )
  }

  const AdvancedSearchContent = () => {
    return (
      <TabPanel value={activeRadioTab} index={2}>
        <div className={'searchFieldDiv '}>
          <div className='search-fields'>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <InputLabel shrink className='label bold'>
                  Department
                </InputLabel>
                <SelectBoxInput
                  stylingMode='outlined'
                  displayExpr='text'
                  valueExpr='id'
                  showClearButton={true}
                  items={departmentItems}
                  selectedItem={state.department}
                  onSelectionChanged={(e: any) => handleDropdownChange(e, 'department')}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <InputLabel shrink className='label bold'>
                  Teams
                </InputLabel>
                <SelectBoxInput
                  stylingMode='outlined'
                  displayExpr='text'
                  valueExpr='id'
                  showClearButton={true}
                  items={tempTeamItems}
                  selectedItem={state.team}
                  disabled={disabledTeam ? true : false}
                  onSelectionChanged={(e: any) => handleDropdownChange(e, 'team')}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <InputLabel shrink className='label bold'>
                  Crew
                </InputLabel>
                <SelectBoxInput
                  stylingMode='outlined'
                  displayExpr='text'
                  valueExpr='id'
                  showClearButton={true}
                  items={tempCrewItems}
                  selectedItem={state.crew}
                  disabled={disabledCrew ? true : false}
                  onSelectionChanged={(e: any) => handleDropdownChange(e, 'crew')}
                />
              </Grid>
            </Grid>
          </div>
        </div>
      </TabPanel>
    )
  }

  const handleRadioTabsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setActiveRadioTab(parseInt(event.target.value))
  }

  return (
    <React.Fragment>
      <div>
        {renderAssetMap()}
        {details && renderDetailsModal()}
        {showAlert && alert()}
        <div className='ui-container'>
          {/* <h2 id='asset-search-types'>Search Work Orders</h2> */}
         
            <RadioGroup row value={activeRadioTab} onChange={handleRadioTabsChange}>
              <FormControlLabel value='0' control={<Radio />} label='Normal Search' className='bold' />
              <FormControlLabel value='1' control={<Radio />} label='Search By Dates' className='bold' />
              <FormControlLabel value='2' control={<Radio />} label='Advanced Search' className='bold' />
            </RadioGroup>
            <Typography component='div'>
              {activeRadioTab === 0 && <NormalSearchContent />}
              {activeRadioTab === 1 && <SearchByDatesContent />}
              {activeRadioTab === 2 && <AdvancedSearchContent />}
            </Typography>
            <div className='actions-container'>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <div className='buttongroups-container displayFlex'>
                    <CheckBoxInput
                      value={showAllWO}
                      onValueChanged={(e: any) => handleValueChange(e, 'showAllWO')}
                      text={'Show All Workorders'}
                    />

                    <span className='show-results bold'>show: </span>
                    <ButtonGroupItem
                      items={totalResultsList}
                      keyExpr='text'
                      stylingMode='text'
                      selectedItemKeys={[state.totalResults.toString()]}
                      onItemClick={(e: any) => handleValueChange(e, 'totalResults')}
                    />
                    <div className={'searchButtonDiv displayFlex'}>
                      {totalRecords && (
                        <div className='totalRecordsDiv'>Total records available for this search is {totalRecords}</div>
                      )}
                    </div>
                    {/* <div>
                      <NumberBoxInput
                        stylingMode='underlined'
                        label='Total Results to Display'
                        min={1}
                        max={100000000}
                        value={totalResults}
                        onValueChanged={(e: any) => handleValueChange(e, 'totalResults')}
                      />
                    </div> */}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className='action-buttons'>
                    <Button onClick={clearFilter} variant='outlined' className={'clearButton'}>
                      Clear
                    </Button>
                    <Button onClick={getWorkOrder} variant='outlined' color='primary' className='primary-button'>
                      Search
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </div>
        </div>

        <div>
          <h2>Results</h2>
          <Typography>{resultText}</Typography>
          {!loading ? (
            <div>
              {items && items.length > 0 && (
                <div ref={myRef} className='results-container'>
                  <DataTable
                    //style={{ width: '100%' }}
                    filteralbe={true}
                    groupable={true}
                    dataSource={items}
                    columns={columns}
                    fileName={'workorders'}
                    columnChooser={'workorderColumns'}
                    selectMode={false}
                    showHideColumnMenu={true}
                    callAfterInit={callAfterInit}
                    stopCommsAfterInit={() => stopCommsAfterInit()}
                    viewItem={(a: any) => viewDetails(a)}
                    loading={loading}
                    allowExportSelecteditems={false}
                    showNewBtn={false}
                    showCommandBtn={false}
                    showViewDetailBtn={false}
                    // showMoreBtn={true}
                    columnHidingEnabled={true}
                  />
                </div>
              )}
            </div>
          ) : (
            <Spinner label='Loading all fields. Please wait' size='large' />
          )}
        </div>
      </div>
    </React.Fragment>
  )
}

export default Searchworkorder
