import React, { useState, useEffect } from 'react'
import utility from '../../../classes/utility'
import { Config } from '../../../classes/config'
import DataTable from '../../Common/CustomASDataGrid'
import Spinner from '../../Common/ReusableComponents/Spinner'
import global from '../../../classes/global'
import CustomStore from 'devextreme/data/custom_store'
import { LoadOptions } from 'devextreme/data'
import DropZoneBox from '../../Common/ReusableComponents/DropZoneBox'
import { object } from 'yup'
import notify from 'devextreme/ui/notify'

interface LoadResult {
  data: any[]
  totalCount: number
}

const Tcp = (props: any) => {
  if (props.role !== global.worxOnline_Admin) {
    window.open('#/AccessDenied', '_self')
  }

  const [dataLoaded, setDataLoaded] = useState(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [items, setItems] = useState<any[]>([])
  const [uploadedFiles, setUploadedFiles] = useState<any>([])
  const [editDetails, setEditDetails] = useState<any>(null)
  const filterValue = [['is_active', '=', true]]

  const columns = [
    {
      field: 'id',
      title: 'ID',
      // width: 160,
      type: 'string',
      allowHiding: true,
      visibleColumn: false,
      formItem: {
        visible: false
      }
    },
    {
      field: 'document_number',
      title: 'Document #',
      // width: 160,
      type: 'string',
      allowHiding: true,
      isRequired: true
    },
    {
      field: 'title',
      title: 'Title',
      // width: 160,
      type: 'string',
      allowHiding: true,
      isRequired: true
    },
    // {
    //   field: 'department',
    //   title: 'Department',
    //   // width: 160,
    //   type: 'string',
    //   allowHiding: false,
    // },
    {
      field: 'file_url',
      title: 'File Url',
      width: 100,
      type: 'string',
      visble: false,
      allowHiding: true
    },
    {
      field: 'is_active',
      title: 'Active',
      // width: 160,
      type: 'boolean',
      calculateCellValue: (data: any) => data.is_active ?? true, // Default to true
      setCellValue: (newData: any, value: any) => {
        newData.is_active = value
      }
    },
    {
      field: 'attachment',
      title: 'Attachment',
      width: 120,
      type: object,
      visibleColumn: false,
      allowHiding: true,
      //setCellValue: setDropZoneValue,
      editCellComponent: (props: any) => (
        <DropZoneBox
          cellData={props}
          editDetails={editDetails}
          uploadedFiles={uploadedFiles}
          setUploadedFiles={setUploadedFiles}
          fileType="image/jpeg, image/png"
          fileSizeLimit={5}
        />
      ),
      formItem: {
        visible: true,
        colSpan: 2
      }
    },
    {
      field: 'updated',
      title: 'Updated',
      width: 160,
      type: 'datetime',
      visibleColumn: false,
      allowHiding: true,
      sortOrder: 'desc,',
      formItem: {
        visible: false
      }
    },
    {
      field: 'updated_by',
      title: 'Updated By',
      width: 160,
      type: 'string',
      visibleColumn: false,
      allowHiding: true,
      formItem: {
        visible: false
      }
    },
    {
      field: 'created',
      title: 'Created',
      width: 160,
      type: 'datetime',
      visibleColumn: false,
      allowHiding: true,
      formItem: {
        visible: false
      }
    },
    {
      field: 'created_by',
      title: 'Created By',
      width: 160,
      type: 'string',
      visibleColumn: false,
      allowHiding: true,
      formItem: {
        visible: false
      }
    }
  ]

  useEffect(() => {
    fetchItemsData()
  }, [])

  const fetchItemsData = async () => {
    setLoading(true)
    try {
      const itemsResponse = await utility.genericGetAPICall(Config.tcpDocumentList)
      let items: any[] = []

      for (let r of itemsResponse) {
        items.push({
          ...r,
          id: r.id,
          active: r.is_active ? true : false
        })
      }
      items = utility._sortItems(items, 'id', true)
      setItems(items)
      setLoading(false)
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  }

  const viewDocument = (file: any) => {
    let item = file
    let dataToPassToService: any = {}
    // if ( (item.fileURL !== null) && (!utility.validURL(item.fileURL) || !item.name)) {
    //   setAlertMessage('unable to view this document. \n File URL or File name does exist');
    //   setShowAlert(true);
    //   return;
    // }
    // dataToPassToService.fileName = item.name ? item.name : '';
    // dataToPassToService.fileUrl = item.fileURL ? item.fileURL : '';
    // utility.genericPostAPIcallForDowloadDoct(Config.dowloadDocumentList, dataToPassToService).then((doc: any) => {
    //   //console.log("doc",doc);
    //   window.open(doc, "_blank");
    // });
  }

  const processFileData = (values: any, fileKey: string) => {
    if (values[fileKey] && values[fileKey].length > 0) {
      let d = new Date()
      let amOrPm = d.getHours() < 12 ? 'AM' : 'PM'
      let hour = d.getHours() < 12 ? d.getHours() : d.getHours() - 12
      let date = `${d.getDate()}_${d.getMonth() + 1}_${d.getFullYear()}_${hour}_${amOrPm}`

      let selectedFile = values[fileKey][0].file
      let fileType = selectedFile.type.replace('image/', '.')
      let data = selectedFile.dataURL.replace(`data:${selectedFile.type};base64,`, '')

      // Destructure values to exclude the fileKey
      const { [fileKey]: file, ...restValues } = values

      return {
        ...restValues,
        file_name: `TCP_${date}${fileType}`,
        filethumbnail_url_name: data
      }
    }
    return values
  }

  const dataSource = new CustomStore({
    load: async (loadOptions: LoadOptions): Promise<LoadResult> => {
      return {
        data: items,
        totalCount: items.length
      }
    },
    key: 'id',
    onLoaded: () => {
      console.log('Data loaded')
      setLoading(false)
      setDataLoaded(true)
    },
    insert: async (values: any) => {
      try {
        const dataToPassToService = processFileData(values, 'attachment')
        await utility.genericPostAPICall(Config.tcpDocumentList, dataToPassToService)
        notify('Row added successfully', 'success', 3000)
        await fetchItemsData()
      } catch (error:any) {
        const errorMessage = error.message || 'An unexpected error occurred.';
        notify(errorMessage, 'error', 5000); 
        console.error(errorMessage);
      }
    },
    update: async (key: any, values: any) => {
      try {
        const dataToPassToService = processFileData(values, 'attachment')
        await utility.genericUpdatePatchAPICall(Config.tcpDocumentList, key, dataToPassToService)
        notify('Row updated successfully', 'success', 3000)
        await fetchItemsData()
      } catch (error:any) {
        const errorMessage = error.message || 'An unexpected error occurred.';
        notify(errorMessage, 'error', 5000); 
        console.error(errorMessage);
      }
    },
    remove: async (key: any) => {
      try {
        await utility.genericDeleteAPICall(Config.tcpDocumentList, key)
        notify('Row deleted successfully', 'success', 3000)
        await fetchItemsData()
      } catch (error:any) {
        const errorMessage = error.message || 'An unexpected error occurred.';
        notify(errorMessage, 'error', 5000); 
        console.error(errorMessage);
      }
    }
  })

  return (
    <>
      <div>
        {!loading ? (
          <div className='results-container'>
            <DataTable
              dataSource={dataSource}
              style={{ width: '100%' }}
              filteralbe={true}
              groupable={true}
              columns={columns}
              fileName={'TCP'}
              columnChooser={'TCPColumns'}
              selectionMode={false}
              showHideColumnMenu={true}
              loading={loading}
              allowExportSelectedData={false}
              columnHidingEnabled={true}
              showCommandBtn={false}
              hideSearchPanel={false}
              hideCommandDeleteBtn={false}
              showNewBtn={false}
              showViewDetailBtn={false}
              hideNewBtn={true}
              defaultfiltervalue={filterValue}
              showMoreBtn={true}
              viewFile={(e: any) => viewDocument(e)}
              visible={dataLoaded}
            />
          </div>
        ) : (
          <Spinner size='large' className='loader' />
        )}
      </div>
    </>
  )
}

export default Tcp
